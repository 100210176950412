import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getMeetingByLocationApi, getMeetingTokenApi } from './meetingRoom.api';
import { actions as appActions } from '../app/app.slice';

export interface IMeetingRoomState {
  jwtToken: string;
  roomName: string;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IMeetingRoomState = {
  jwtToken: '',
  roomName: '',
  loading: false,
  success: false,
  error: null,
};

export const getMeetingTokenAsync = createAsyncThunk(
  'getToken',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMeetingTokenApi(data);
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getMeetingByLocationAsync = createAsyncThunk(
  'getMeetingByLocation',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMeetingByLocationApi(data);
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const meetingRoomSlice = createSlice({
  name: 'scheduleMeeting',
  initialState,
  reducers: {
    resetJwtToken(state) {
      state.jwtToken = '';
    },
    resetSuccess(state) {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // get Meeting Token
    builder.addCase(getMeetingTokenAsync.pending, (state, action) => {
      state.loading = true;
      state.jwtToken = '';
      state.success = false;
      state.error = null;
    });
    builder.addCase(getMeetingTokenAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.jwtToken = action?.payload?.data?.jwtToken;
      state.success = true;
    });
    builder.addCase(getMeetingTokenAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
    // get Meeting Token
    builder.addCase(getMeetingByLocationAsync.pending, (state, action) => {
      state.roomName = '';
    });
    builder.addCase(getMeetingByLocationAsync.fulfilled, (state, action) => {
      state.roomName = action.payload?.data?.name;
    });
    builder.addCase(getMeetingByLocationAsync.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { actions } = meetingRoomSlice;

export const selectMeetingRoom = (state: RootState) => state.meetingRoom;

export default meetingRoomSlice.reducer;
