/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useState, useEffect, ChangeEvent } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { Button, IconButton } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { TextField } from '../../../components/TextField';
import { isUrl, validateEmail } from '../../../helper/validation';
import { IGenericStringState } from '../../../components/types';
import { ILink } from '../settings.slice';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    wrapper: {
      marginBottom: theme.spacing(3),
    },
    description: {
      margin: theme.spacing(1, 0),
    },
  });
};
interface IContactUsSettingsView extends WithStyles<typeof styles> {
  settings: any;
  handleUpdateSettings: (data: any) => void;
}

interface ILinkState {
  value: ILink[];
  error: string;
}

const linkList: ILink[] = [
  {
    name: 'facebook',
    value: '',
  },
  {
    name: 'youtube',
    value: '',
  },
  {
    name: 'instagram',
    value: '',
  },
  {
    name: 'linkedin',
    value: '',
  },
  {
    name: 'twitter',
    value: '',
  },
  {
    name: 'tiktok',
    value: '',
  },
];

const ContactUsSettingsView: React.FC<IContactUsSettingsView> = ({
  classes,
  settings,
  handleUpdateSettings,
}: IContactUsSettingsView) => {
  const intl = useIntl();
  const [linkId, setLinkId] = useState(0);

  const [supportMail, setSupportMail] = useState<IGenericStringState>({
    value: '',
    error: '',
  });
  const [contactNumber, setContactNumber] = useState<IGenericStringState>({
    value: '',
    error: '',
  });
  const [address, setAddress] = useState<IGenericStringState>({
    value: '',
    error: '',
  });
  const [googleMapAPIKey, setGoogleMapAPIKey] = useState<IGenericStringState>({
    value: '',
    error: '',
  });
  const [links, setLinks] = useState<ILinkState>({
    value: [],
    error: '',
  });

  useEffect(() => {
    if (!settings?.loading && settings?.data?.contactInfo) {
      const info = settings.data.contactInfo;
      setSupportMail({ value: info.supportMail, error: '' });
      setContactNumber({ value: info.contactNumber, error: '' });
      setAddress({ value: info.address, error: '' });
      setGoogleMapAPIKey({ value: info.googleMapAPIKey, error: '' });
      const linksTemp: ILink[] = [];
      let localLinkId = 0;
      [...info.links].forEach((link2: ILink) => {
        localLinkId += 1;
        linksTemp.push({
          ...link2,
          id: localLinkId,
          error: '',
        });
      });
      setLinkId(localLinkId);
      setLinks({ value: linksTemp, error: '' });
    }
  }, [settings]);

  const _supportMailOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const error = validateEmail(e.target.value);
    setSupportMail({
      value: e.target.value,
      ...(error
        ? {
            error: intl.formatMessage({
              id: 'please_insert_valid_email',
            }),
          }
        : { error: '' }),
    });
  };
  const _contactNumberOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContactNumber({ value: e.target.value, error: '' });
  };
  const _addressOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAddress({ value: e.target.value, error: '' });
  };
  const handleLinkDropdownChange = (data1: ILink | null, data2: ILink) => {
    if (!data1) return;
    const linksTemp: ILink[] = [];
    links.value.forEach((link2: ILink) => {
      linksTemp.push({
        ...link2,
        name: link2.id === data2.id ? data1.name : link2.name,
      });
    });
    setLinks({ ...links, value: linksTemp });
  };

  const handleLinkTexfieldChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    data: ILink
  ) => {
    const error = isUrl(e.target.value);
    const linksTemp: ILink[] = [];
    links.value.forEach((link2: ILink) => {
      linksTemp.push({
        ...link2,
        value: link2.id === data.id ? e.target.value : link2.value,
        ...(link2.id === data.id && error
          ? {
              error: intl.formatMessage({
                id: 'please_insert_valid_url',
              }),
            }
          : { error: '' }),
      });
    });
    setLinks({
      ...links,
      value: linksTemp,
    });
  };

  const _validate = () => {
    let isValidate = true;
    const linksTemp: ILink[] = [];

    if (supportMail.error) isValidate = false;
    links.value.forEach((data) => {
      if (data.error) {
        isValidate = false;
      }
      const error = isUrl(data.value || '');
      if (error) {
        isValidate = false;
      }
      linksTemp.push({
        ...data,
        ...(error && {
          error: intl.formatMessage({
            id: 'please_insert_valid_url',
          }),
        }),
      });
    });
    setLinks({
      ...links,
      value: linksTemp,
    });
    return isValidate;
  };

  const _handleSaveButtonClick = () => {
    if (_validate()) {
      const linksTemp: ILink[] = [];
      links.value.forEach((link2: ILink) => {
        linksTemp.push({ name: link2.name, value: link2.value });
      });

      const data = {
        setting: {
          supportMail: supportMail.value,
          contactNumber: contactNumber.value,
          address: address.value,
          googleMapAPIKey: googleMapAPIKey.value,
          links: linksTemp,
        },
        category: 'contact',
      };
      handleUpdateSettings(data);
    }
  };

  const _handleAddNewLink = () => {
    const linksTemp: ILink[] = links.value;
    linksTemp.push({
      id: linkId + 1,
      name: 'facebook',
      value: '',
      error: '',
    });
    setLinkId(linkId + 1);
    setLinks({ ...links, value: linksTemp });
  };

  const _handleRemoveLink = (data: ILink) => {
    const linksTemp: ILink[] = [];
    links.value.forEach((link2: ILink) => {
      if (data.id !== link2.id) {
        linksTemp.push(link2);
      }
    });
    setLinks({
      ...links,
      value: linksTemp,
    });
  };

  return (
    <div>
      <Card customStyles={classes.root}>
        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            <FormattedMessage id="contact_info" />
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            <FormattedMessage id="required_input_fields" />
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} lg={3}>
              <TextField
                label={intl.formatMessage({
                  id: 'support_mail',
                })}
                type="email"
                value={supportMail.value}
                onChange={_supportMailOnChange}
                error={Boolean(supportMail.error)}
                helperText={supportMail.error}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label={intl.formatMessage({
                  id: 'contact_number',
                })}
                type="tel"
                value={contactNumber.value}
                onChange={_contactNumberOnChange}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label={intl.formatMessage({
                  id: 'address',
                })}
                value={address.value}
                onChange={_addressOnChange}
              />
            </Grid>
            {/* <Grid item sm={6} lg={3}>
              <TextField
                label={intl.formatMessage({
                  id: 'google_map_api_key',
                })}
                value={googleMapAPIKey.value}
                onChange={_googleMapAPIKeyOnChange}
              />
            </Grid> */}
          </Grid>
        </div>

        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            <FormattedMessage id="social_media_links" />
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            <FormattedMessage id="social_media_links_description" />
          </Typography>
          <Grid container spacing={2}>
            {links.value.map((data: ILink) => {
              return (
                <Grid container key={`link-row-${data.id}`}>
                  <Grid item xs={12} sm={6} md={2}>
                    <AutocompleteTextField
                      id="contact-us-autocomplete"
                      multiple={false}
                      limitTags={1}
                      options={linkList}
                      getOptionLabel={(option: ILink) =>
                        option.name.charAt(0).toUpperCase() +
                        option.name.slice(1)
                      }
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={data}
                      onChange={(
                        _event: ChangeEvent<{}>,
                        data2: ILink | null
                      ) => {
                        handleLinkDropdownChange(data2, data);
                      }}
                      label={intl.formatMessage({
                        id: 'social_media',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'social_media',
                      })}
                      error={!!data.error}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={10}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <TextField
                      label={intl.formatMessage({
                        id: 'url',
                      })}
                      value={data.value}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        handleLinkTexfieldChange(e, data);
                      }}
                      error={!!data.error}
                      helperText={data.error}
                    />
                    <IconButton
                      id="social-media-row-remove-button"
                      onClick={() => {
                        _handleRemoveLink(data);
                      }}
                      icon={<ClearIcon fontSize="inherit" />}
                      label={intl.formatMessage({
                        id: 'remove',
                      })}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2}>
            <IconButton
              id="add-social-media-links-button"
              onClick={_handleAddNewLink}
              icon={<AddCircleIcon fontSize="inherit" />}
              label={intl.formatMessage({
                id: 'add',
              })}
            />
          </Grid>
        </div>
        <Button
          // customStyles={classes.button}
          id="update-profile-save-button"
          variant="contained"
          label={intl.formatMessage({
            id: 'save',
          })}
          onClick={_handleSaveButtonClick}
        />
      </Card>
    </div>
  );
};

export default memo(withStyles(styles)(ContactUsSettingsView));
