/* eslint-disable import/prefer-default-export */

import { webBaseUrl, jitsiDomain } from '../app/config';

export const generateMeetingUrl = (
  location: string,
  withBaseUrl = true
): string => {
  if (withBaseUrl) return `${webBaseUrl}/room/${location}`;

  return `/room/${location}`;
};

export const generateReplaystreamingUrl = (
  location: string,
  withBaseUrl = true
): string => {
  if (withBaseUrl) return `${webBaseUrl}/replaystreaming/${location}`;

  return `/replaystreaming/${location}`;
};

export const generateExternalMeetingUrl = (
  location: string,
  jwt: string,
  withBaseUrl = true
): string => {
  if (withBaseUrl) return `https://${jitsiDomain}/${location}?jwt=${jwt}`;

  return `/${location}?jwt=${jwt}`;
};
