/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  deleteCustomersAsync,
  editCustomerAsync,
  fetchAllSubadminsAsync,
  fetchCustomerAsync,
  selectCustomers,
  suspendCustomerAsync,
  unsuspendCustomerAsync,
} from './customers.slice';
import ManageCustomerView from './manageCustomer.view';
import {
  addMemberAsync,
  deleteMembersAsync,
  fetchMembersAsync,
  selectMembers,
} from '../members/members.slice';
import {
  assignDeviceForAnyCustomerAsync,
  fetchDevicesAsync,
  fetchDevicesRemoteAsync,
  selectDevices,
  unassignAnyDevicesAsync,
} from '../devices/devices.slice';
import {
  addGroupAsync,
  deleteAnyGroupAsync,
  destroyAnyGroupMemberAsync,
  editAnyGroupAsync,
  getGroupsAsync,
  selectGroups,
} from '../group/groups.slice';
import { IRequestParams } from '../../components/types';

export interface IManageCustomerContainer {
  selectedCustomer: any;
  allCustomer: boolean;
  backToCustomers: () => void;
}

const ManageCustomerContainer: React.FC<IManageCustomerContainer> = ({
  selectedCustomer,
  allCustomer,
  backToCustomers,
}: IManageCustomerContainer) => {
  const dispatch = useAppDispatch();
  const members = useAppSelector(selectMembers);
  const customers = useAppSelector(selectCustomers);
  const devices = useAppSelector(selectDevices);
  const groups = useAppSelector(selectGroups);

  useEffect(() => {
    dispatch(fetchCustomerAsync(selectedCustomer.id as string));
    dispatch(fetchMembersAsync({ id: selectedCustomer.id }));
    dispatch(fetchDevicesAsync({ id: selectedCustomer.id }));
    dispatch(getGroupsAsync({ id: selectedCustomer.id }));
    dispatch(fetchDevicesRemoteAsync(null));
    if (allCustomer) {
      dispatch(fetchAllSubadminsAsync(selectedCustomer.id));
    }
  }, []);

  const handleAddMember = (data: any) => {
    const { email, name, contactNumber } = data;
    dispatch(
      addMemberAsync({
        email,
        name,
        contactNumber,
        customerId: selectedCustomer.id,
      })
    );
  };

  const handleEditCustomer = (data: any) => {
    dispatch(editCustomerAsync({ ...data, nextApiCallType: 'fetchCustomer' }));
  };

  const handleSelectDevice = (data: any) => {
    dispatch(
      assignDeviceForAnyCustomerAsync({
        ...data,
        customerId: selectedCustomer.id,
      })
    );
  };

  const handleAddGroup = (data: any) => {
    dispatch(
      addGroupAsync({
        ...data,
        customerId: selectedCustomer.id,
        nextApiCallType: 'getGroupsByCustomer',
      })
    );
  };

  const handleDeleteMember = (id: string) => {
    dispatch(deleteMembersAsync({ id, customerId: selectedCustomer.id }));
  };

  const handleDeleteDevice = (data: any) => {
    dispatch(
      unassignAnyDevicesAsync({ ...data, customerId: selectedCustomer.id })
    );
  };
  const handleDeleteGroup = (id: string) => {
    dispatch(
      deleteAnyGroupAsync({
        id,
        customerId: selectedCustomer.id,
        nextApiCallType: 'getGroupsByCustomer',
      })
    );
  };

  const handleEditGroup = (data: any) => {
    dispatch(
      editAnyGroupAsync({
        ...data,
        customerId: selectedCustomer.id,
        nextApiCallType: 'getGroupsByCustomer',
      })
    );
  };

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomersAsync(selectedCustomer.id));
  };

  const handleSuspendCustomer = () => {
    dispatch(
      suspendCustomerAsync({
        id: selectedCustomer.id,
        nextApiCallType: 'fetchCustomer',
      })
    );
  };

  const handleUnsuspendCustomer = () => {
    dispatch(
      unsuspendCustomerAsync({
        id: selectedCustomer.id,
        nextApiCallType: 'fetchCustomer',
      })
    );
  };

  const handleDeleteGroupMember = (id: string) => {
    dispatch(
      destroyAnyGroupMemberAsync({
        id,
        customerId: selectedCustomer.id,
        nextApiCallType: 'getGroups',
      })
    );
  };

  const handleGetDevices = (data: IRequestParams) => {
    dispatch(fetchDevicesAsync({ id: selectedCustomer.id, ...data }));
  };

  const handleGetGroups = (data: IRequestParams) => {
    dispatch(getGroupsAsync({ id: selectedCustomer.id, ...data }));
  };

  const handleGetMembers = (data: IRequestParams) => {
    dispatch(fetchMembersAsync({ id: selectedCustomer.id, ...data }));
  };

  return (
    <ManageCustomerView
      selectedCustomer={customers.customer}
      allCustomer={allCustomer}
      selectMember={members}
      membersList={members.list}
      subadminList={customers.listSubadmin}
      handleAddMember={handleAddMember}
      handleEditCustomer={handleEditCustomer}
      handleSelectDevice={handleSelectDevice}
      handleAddGroup={handleAddGroup}
      selectDevice={devices}
      selectGroup={groups}
      backToCustomers={backToCustomers}
      handleDeleteMember={handleDeleteMember}
      handleDeleteDevice={handleDeleteDevice}
      handleDeleteGroup={handleDeleteGroup}
      handleEditGroup={handleEditGroup}
      handleDeleteCustomer={handleDeleteCustomer}
      handleSuspendCustomer={handleSuspendCustomer}
      handleUnsuspendCustomer={handleUnsuspendCustomer}
      handleDeleteGroupMember={handleDeleteGroupMember}
      handleGetDevices={handleGetDevices}
      handleGetGroups={handleGetGroups}
      handleGetMembers={handleGetMembers}
    />
  );
};

export default memo(ManageCustomerContainer);
