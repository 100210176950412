import React, { memo } from 'react';
import { Card, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(4),
      borderRadius: '10px',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[200]}`,
      [theme.breakpoints.down('lg')]: {
        padding: 24,
      },
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
    },
  });
};

export interface ICardComponentProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  customStyles?: any;
  id?: string;
}

const CardComponent = ({
  classes,
  id,
  children,
  customStyles,
}: ICardComponentProps) => {
  return (
    <Card
      className={clsx(classes.container, customStyles && customStyles)}
      id={id}
    >
      {children}
    </Card>
  );
};

export default memo(withStyles(styles)(CardComponent));
