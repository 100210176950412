/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import InstantMeetingModalView from './instantMeetingModal.view';
import { ShareMeetingModal } from '../../meetings/components';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { generateMeetingUrl } from '../../../helper/conference';
import {
  selectSchesuleMeeting,
  actions,
  instantMeetingAsync,
} from '../scheduleMeeting.slice';
import { fetchDevicesAsync, selectDevices } from '../../devices/devices.slice';
import { ICreateInstant } from '../scheduleMeeting.api';

const InstantMeetingModalContainer: React.FC<unknown> = () => {
  const scheduleMeeting = useAppSelector(selectSchesuleMeeting);
  const devices = useAppSelector(selectDevices);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);

  useEffect(() => {
    if (
      scheduleMeeting.success &&
      scheduleMeeting.isInstantMeetingModalOpen &&
      !isShareMeetingOpen
    ) {
      dispatch(actions.closeInstantMeetingModal());
      setIsShareMeetingOpen(true);
      const url = generateMeetingUrl(scheduleMeeting.data?.location, false);
      history.push(url);
    }
  }, [scheduleMeeting.success]);

  useEffect(() => {
    if (scheduleMeeting.isInstantMeetingModalOpen) {
      dispatch(fetchDevicesAsync({}));
    }
  }, [scheduleMeeting.isInstantMeetingModalOpen]);

  const _instantMeetinOnClose = () => {
    dispatch(actions.closeInstantMeetingModal());
  };

  const _startMeetingButtonClick = (data: ICreateInstant) => {
    dispatch(instantMeetingAsync(data));
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
    dispatch(actions.resetScheduleMeeting());
  };

  return (
    <>
      <InstantMeetingModalView
        open={scheduleMeeting.isInstantMeetingModalOpen}
        devices={devices.list}
        handleClose={_instantMeetinOnClose}
        startMeetingButtonClick={_startMeetingButtonClick}
      />
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={scheduleMeeting?.data}
        />
      )}
    </>
  );
};

export default memo(InstantMeetingModalContainer);
