import React, { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid/Grid';
import { Typography } from '../../components/Typography';
import { useAppSelector } from '../../app/hooks';
import { ILink, selectSettings } from '../settings/settings.slice';
import { TextArea, TextField } from '../../components/TextField';
import { validateContactNumber, validateEmail } from '../../helper/validation';
import { Button, SocialMediaLink } from '../../components/Button';
import { LocationIcon, MailIcon, PhoneIcon } from '../../assets/icons';
import { IContactusData } from './contactus.api';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '5% 4.2%',
    },
    title: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '40px',
    },
    description: {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '16px',
      padding: '0 26%',
      marginBottom: '4vh',
      [theme.breakpoints.down('sm')]: {
        padding: '0 5%',
      },
    },
    contactItemWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10vh',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '20px',
      },
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      '& img': {
        marginTop: '5px',
      },
      '& a': {
        color: '#211A5B',
        fontSize: '16px',
        fontWeight: 400,
        textDecoration: 'none',
      },
    },
    socialButtonWrapper: {
      display: 'flex',
      justifyContent: 'end',
      gap: '12px',
      '& img': {
        width: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        gap: '18px',

        '& img': {
          width: '40px',
        },
      },
      '& .MuiButton-root': {
        width: 'auto',
        minWidth: 'unset',
        lineHeight: 'unset',
      },
    },
  });
};

interface IContactusView extends WithStyles<typeof styles> {
  handleSendMessage: (data: IContactusData) => void;
}

const ContactView: React.FC<IContactusView> = ({
  classes,
  handleSendMessage,
}) => {
  const settings = useAppSelector(selectSettings);

  const [name, setName] = useState({ error: false, value: '' });
  const [email, setEmail] = useState({ error: false, value: '' });
  const [contactNumber, setContactNumber] = useState({
    error: false,
    value: '',
  });
  const [message, setMessage] = useState({ error: false, value: '' });

  const intl = useIntl();

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    if (!contactNumber.value || contactNumber.error) {
      isValidate = false;
      setContactNumber({ ...contactNumber, error: true });
    }
    if (!message.value || message.error) {
      isValidate = false;
      setMessage({ ...message, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const data = {
        name: name.value,
        email: email.value,
        contactNumber: contactNumber.value,
        message: message.value,
      };
      handleSendMessage(data);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    const error = !e.target.value;
    setName({ value: e.target.value, error });
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const contactNumberOnChange = (e: { target: { value: string } }) => {
    const error = validateContactNumber(e.target.value);
    setContactNumber({ value: e.target.value, error });
  };

  const messageOnChange = (e: { target: { value: string } }) => {
    const error = !e.target.value;
    setMessage({ value: e.target.value, error });
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h4"
        component="h2"
        color="textPrimary"
        className={classes.title}
      >
        <FormattedMessage id="contact_us" />
      </Typography>
      <br />
      <Typography
        variant="h5"
        component="h5"
        color="textPrimary"
        className={classes.description}
      >
        <FormattedMessage id="contact_us_description" />
      </Typography>
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="contact-us-email"
            error={name.error}
            placeholder={intl.formatMessage({
              id: 'enter_your_name',
            })}
            required
            onChange={nameOnChange}
            helperText={
              name.error &&
              intl.formatMessage({
                id: 'please_insert_valid_name',
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="contact-us-email"
            error={email.error}
            placeholder={intl.formatMessage({
              id: 'enter_your_email',
            })}
            required
            onChange={emailOnChange}
            helperText={
              email.error &&
              intl.formatMessage({
                id: 'please_insert_valid_email',
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="contact-us-contact-number"
            error={contactNumber.error}
            placeholder={intl.formatMessage({
              id: 'enter_your_contact_number',
            })}
            required
            type="tel"
            onChange={contactNumberOnChange}
            helperText={
              contactNumber.error &&
              intl.formatMessage({
                id: 'please_insert_valid_contact_number',
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextArea
            id="contact-us-message"
            error={message.error}
            placeholder={intl.formatMessage({
              id: 'write_your_message',
            })}
            required
            onChange={messageOnChange}
            helperText={
              message.error &&
              intl.formatMessage({
                id: 'please_insert_valid_message',
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            align="center"
            id="contact-us-button"
            variant="contained"
            color="primary"
            label={intl.formatMessage({
              id: 'send_message',
            })}
            onClick={onClickActionButton}
          />
        </Grid>
      </Grid>

      <div className={classes.contactItemWrapper}>
        <div className={classes.contactItem}>
          <img src={PhoneIcon} alt="phone" />
          <Link
            variant="h6"
            href={`tel:${settings?.data?.contactInfo?.contactNumber}`}
            underline="always"
          >
            {settings?.data?.contactInfo?.contactNumber}
          </Link>
        </div>

        <div className={classes.contactItem}>
          <img src={MailIcon} alt="mail" />
          <Link
            variant="h6"
            href={`mailto:${settings?.data?.contactInfo?.supportMail}`}
            underline="always"
          >
            {settings?.data?.contactInfo?.supportMail}
          </Link>
        </div>

        <div className={classes.contactItem}>
          <img src={LocationIcon} alt="location" />
          <Link
            variant="h6"
            href={`https://www.google.com/maps/search/${settings?.data?.contactInfo?.address}`}
            underline="always"
            target="_blank"
          >
            {settings?.data?.contactInfo?.address}
          </Link>
        </div>

        <div className={classes.socialButtonWrapper}>
          {settings?.data?.contactInfo?.links &&
            settings?.data?.contactInfo?.links.map((data: ILink) => {
              return (
                <>
                  {data.value && (
                    <SocialMediaLink icon={data.name} onClick={data.value} />
                  )}
                </>
              );
            })}
        </div>
      </div>
      <div />
    </div>
  );
};

export default memo(withStyles(styles)(ContactView));
