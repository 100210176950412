/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ReplaystreamingRoomView from './replaystreamingRoom.view';
import {
  selectReplaystreamingRoom,
  getMeetingByLocationAsync,
} from './replaystreamingRoom.slice';
import { getProfileAsync, selectAuth } from '../auth/auth.slice';
import { getToken } from '../../helper/localStorage';
import { actions as appActions } from '../app/app.slice';

export interface IReplaystreamingRoomContainer {}

const ReplaystreamingRoomContainer: React.FC<IReplaystreamingRoomContainer> = ({}: IReplaystreamingRoomContainer) => {
  const dispatch = useAppDispatch();
  const replaystreamingRoom = useAppSelector(selectReplaystreamingRoom);
  const auth = useAppSelector(selectAuth);

  const history = useHistory();
  const [roomName, setRoomName] = useState<string>('');
  const [roomTitle, setRoomTitle] = useState<string>('');
  const [viewingUrl, setViewingUrl] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  useEffect(() => {
    const token = getToken();
    if (token) {
      dispatch(getProfileAsync(null));
    }
    const pathnameArray = history.location.pathname.split('/');
    const room =
      pathnameArray.length > 0 ? pathnameArray[pathnameArray.length - 1] : '';
    dispatch(getMeetingByLocationAsync(room));
    setRoomName(room);
  }, [dispatch, history.location.pathname]);

  useEffect(() => {
    setRoomTitle(replaystreamingRoom.roomName);
    setViewingUrl(replaystreamingRoom.viewingUrl);
    setStartTime(replaystreamingRoom.startTime);
    setEndTime(replaystreamingRoom.endTime);
  }, [replaystreamingRoom]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10123'));
  }, []);

  const _backToHomeButtonOnClick = () => {
    history.push('/');
  };
  return (
    <ReplaystreamingRoomView
      roomName={roomName}
      roomTitle={roomTitle}
      viewingUrl={viewingUrl}
      user={auth?.user}
      startTime={startTime}
      endTime={endTime}
      backToHomeButtonOnClick={_backToHomeButtonOnClick}
    />
  );
};

export default memo(ReplaystreamingRoomContainer);
