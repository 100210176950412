/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { IMembersState } from '../../members/members.slice';
import { IGroupsState } from '../groups.slice';
import { IDevice, IDevicesState } from '../../devices/devices.slice';
import { IUser } from '../../auth/auth.slice';
import { IAddGroupData } from '../groups.api';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddAllGroupModalProps extends WithStyles<typeof styles> {
  open: boolean;
  handleClose: () => void;
  handleAddGroup: (data: IAddGroupData) => void;
  selectGroup: IGroupsState;
  selectMember: IMembersState;
  selectDevice: IDevicesState;
}

const AddAllGroupModal = ({
  classes,
  open,
  handleClose,
  handleAddGroup,
  selectGroup,
  selectMember,
  selectDevice,
}: IAddAllGroupModalProps) => {
  useEffect(() => {
    handleClose();
    setName({
      error: false,
      value: '',
    });
    setDescription({
      error: false,
      value: '',
    });
    setMemberIds({
      error: false,
      value: [],
    });
  }, [selectGroup.loading]);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [description, setDescription] = useState({
    error: false,
    value: '',
  });

  interface IMemberIdState {
    error: boolean;
    value: IUser[];
  }

  interface IDeviceIdState {
    error: boolean;
    value: IDevice[];
  }

  const [memberIds, setMemberIds] = useState<IMemberIdState>({
    error: false,
    value: [],
  });
  const [deviceIds, setDeviceIds] = useState<IDeviceIdState>({
    error: false,
    value: [],
  });
  const intl = useIntl();

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (description.error) {
      isValidate = false;
      setDescription({ ...description, error: true });
    }
    if (memberIds.error) {
      isValidate = false;
      setMemberIds({ ...memberIds, error: true });
    }
    if (deviceIds.error) {
      isValidate = false;
      setDeviceIds({ ...deviceIds, error: true });
    }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberIdsArr = [];
      for (const member of memberIds.value) {
        if (member?._id) memberIdsArr.push(member._id);
      }
      const deviceIdsArr = [];
      for (const device of deviceIds.value) {
        if (device?._id) deviceIdsArr.push(device._id);
      }

      const groupData = {
        name: name.value,
        description: description.value,
        members: memberIdsArr,
        devices: deviceIdsArr,
      };
      handleAddGroup(groupData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const descriptionOnChange = (e: { target: { value: string } }) => {
    setDescription({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const handleMembersChange = (
    event: ChangeEvent<unknown>,
    value: IUser[],
    reason: AutocompleteChangeReason
  ) => {
    setMemberIds({
      value,
      error: value.length === 0,
    });
  };

  const handleDevicesChange = (
    event: ChangeEvent<unknown>,
    value: IDevice[],
    reason: AutocompleteChangeReason
  ) => {
    setDeviceIds({
      value,
      error: value.length === 0,
    });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'add_group',
      })}
      modalDescription={intl.formatMessage({
        id: 'make_group_manage_meetings',
      })}
      id="add-group-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'add_group',
      })}
    >
      <TextField
        id="add-group-name"
        error={name.error}
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
        required
        helperText={
          name.error &&
          intl.formatMessage({
            id: 'please_insert_valid_name',
          })
        }
      />
      <TextField
        id="add-group-description"
        error={description.error}
        placeholder={intl.formatMessage({
          id: 'description',
        })}
        onChange={descriptionOnChange}
        helperText={
          description.error &&
          intl.formatMessage({
            id: 'please_insert_valid_description',
          })
        }
      />
      <AutocompleteTextField
        multiple
        limitTags={2}
        id="add-group-members"
        options={selectMember.list.data}
        getOptionLabel={(option) => option.profile.name}
        onChange={handleMembersChange}
        label={intl.formatMessage({
          id: 'members',
        })}
        placeholder={intl.formatMessage({
          id: 'select_at_least_one_member',
        })}
        error={memberIds.error}
      />
      <AutocompleteTextField
        multiple
        limitTags={2}
        id="add-group-devices"
        options={selectDevice.list.data}
        getOptionLabel={(option) =>
          option.userDeviceName || option.deviceName || ''
        }
        onChange={handleDevicesChange}
        label={intl.formatMessage({
          id: 'devices',
        })}
        placeholder={intl.formatMessage({
          id: 'select_at_least_one_device',
        })}
        error={deviceIds.error}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddAllGroupModal));
