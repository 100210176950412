/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setHeader } from '../../app/axios';
import { setToken } from '../../helper/localStorage';
import { selectAuth, actions as authAction } from '../auth/auth.slice';
import { actions as scheduleMeetingAction } from '../scheduleMeeting/scheduleMeeting.slice';
import {
  selectSettings,
  actions as settingsAction,
} from '../settings/settings.slice';
import { ScheduleMeetingModal, InstantMeetingmodal } from '../scheduleMeeting';
import { AppBar } from '../../components/layout';

export interface IAppBarContainer {}

const AppBarContainer: React.FC<IAppBarContainer> = ({}: IAppBarContainer) => {
  const auth = useAppSelector(selectAuth);
  const settings = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const _signOut = () => {
    dispatch(authAction.signOut());
    dispatch(settingsAction.resetSettings());
    setToken('');
    setHeader('');
    history.push('/signin');
    history.go(0); // refresh the application after signout
  };

  const _scheduleMeetingOnClick = () => {
    dispatch(scheduleMeetingAction.openScheduleModal());
  };

  const _instantMeetingOnClick = () => {
    dispatch(scheduleMeetingAction.openInstantMeetingModal());
  };

  return (
    <>
      <AppBar
        auth={auth}
        settings={settings}
        signOut={_signOut}
        scheduleMeetingOnClick={_scheduleMeetingOnClick}
        instantMeetingOnClick={_instantMeetingOnClick}
      />

      <ScheduleMeetingModal />
      <InstantMeetingmodal />
    </>
  );
};

export default memo(AppBarContainer);
