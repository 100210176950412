import { AxiosResponse } from 'axios';
import api from '../../app/axios';
import { IRequestParams } from '../../components/types';

export interface IGetUsersArgs extends IRequestParams {
  id?: string;
}

export const fetchAllUsersApi = (
  data: IGetUsersArgs
): Promise<AxiosResponse> => {
  return api.post('users/getadmins', data);
};

export const addUserApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`users/createadmin`, data);
};

export const deleteUserApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`users/admin/${id}`);
};

export const editUserApi = (data: string): Promise<AxiosResponse> => {
  return api.post(`users/admin/update`, data);
};

export const suspendUserApi = (id: string): Promise<AxiosResponse> => {
  return api.post(`users/admin/suspend/${id}`);
};

export const unsuspendUserApi = (id: string): Promise<AxiosResponse> => {
  return api.post(`users/admin/unsuspend/${id}`);
};

export const resetUserPasswordApi = (data: any): Promise<AxiosResponse> => {
  const { _id, password } = data;
  return api.post(`users/admin/${_id}/password`, { password });
};
