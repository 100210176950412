/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Theme,
} from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { LabelPlacement, Color } from '../types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiCheckbox-root': {
        color: theme.palette.grey[500],
      },
    },
  });
};

export interface ICheckboxPropstProps
  extends WithStyles<CheckboxProps & typeof styles> {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  id: string;
  checked: boolean;
  label?: string;
  labelPlacement?: LabelPlacement;
  color?: Color;
}

export const CheckboxComponent: React.FC<ICheckboxPropstProps> = ({
  classes,
  id,
  onChange,
  checked,
  label = '',
  labelPlacement = 'end',
  color = 'primary',
  ...otherProps
}: ICheckboxPropstProps) => {
  return (
    <span className={classes.root}>
      <FormControlLabel
        id={id}
        control={
          <Checkbox checked={checked} onChange={onChange} color={color} />
        }
        label={label}
        labelPlacement={labelPlacement}
        {...otherProps}
      />
    </span>
  );
};

export default withStyles(styles)(CheckboxComponent);
