/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import {
  changeUserPasswordAsync,
  resetPasswordAsync,
  selectEmailRouter,
  updateUserVerifyAsync,
} from './emailRouter.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EmailRouterView from './emailRouter.view';
import { selectSettings } from '../settings/settings.slice';
import { actions as appActions } from '../app/app.slice';

export interface IEmailRouterContainer {}

const EmailRouterContainer: React.FC<IEmailRouterContainer> = ({}: IEmailRouterContainer) => {
  const dispatch = useAppDispatch();
  const emailRouter = useAppSelector(selectEmailRouter);
  const settings = useAppSelector(selectSettings);
  const { verifyApi, passwordResetSuccess, loading, error } = emailRouter;
  const history = useHistory();
  const { location } = history;
  const [view, setView] = useState('none');
  const [isPasswordReset, setIsPasswordReset] = useState(true);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams && queryParams.accountconfirmation) {
      // User clicked on verify user email link
      apiUpdateUserVerifyAsync({ token: queryParams.token });
    } else if (queryParams && queryParams.passwordreset) {
      // User clicked on reset password email link
      apiResetPasswordAsync({ token: queryParams.token });
    }
  }, []);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (loading === false) {
      if (verifyApi && verifyApi.success === true) {
        if (queryParams && queryParams.accountconfirmation) {
          setView('passwordChange');
          setIsPasswordReset(false);
        } else if (queryParams && queryParams.passwordreset) {
          setView('passwordChange');
          setIsPasswordReset(true);
        } else {
          setView('none');
        }
      } else if ((verifyApi && verifyApi.success === false) || error) {
        if (queryParams && queryParams.accountconfirmation) {
          setView('tokenExpired');
          setIsPasswordReset(false);
        } else if (queryParams && queryParams.passwordreset) {
          setView('tokenExpired');
          setIsPasswordReset(true);
        } else {
          setView('none');
        }
      }
    }
  }, [verifyApi, loading, error, location.search]);

  useEffect(() => {
    if (loading === false) {
      if (passwordResetSuccess) {
        history.push('/');
      }
    }
  }, [passwordResetSuccess, loading, error, location.search]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10116'));
  }, []);

  const apiUpdateUserVerifyAsync = (data: any) => {
    dispatch(updateUserVerifyAsync(data));
  };

  const apiResetPasswordAsync = (data: any) => {
    dispatch(resetPasswordAsync(data));
  };

  const handleChangeUserPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(changeUserPasswordAsync(data));
  };

  const handleSignin = (data: any) => {
    history.push('/signin');
  };

  return (
    <EmailRouterView
      handleChangeUserPassword={handleChangeUserPassword}
      handleSignin={handleSignin}
      generalSettings={settings?.data?.general}
      view={view}
      isPasswordReset={isPasswordReset}
    />
  );
};

export default memo(EmailRouterContainer);
