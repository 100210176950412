import React, { memo, useEffect, useState } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface ISuspendUserModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  selectedUser: any;
  handleClose?: any;
  handleSuspendUser: (id: string) => void;
  handleUnsuspendUser: (id: string) => void;
  selectUser: any;
}

const SuspendUserModal = ({
  classes,
  open,
  selectedUser,
  handleClose,
  handleSuspendUser,
  handleUnsuspendUser,
  selectUser,
}: ISuspendUserModalProps) => {
  const intl = useIntl();
  const [isSuspend, setIsSuspend] = useState(true);
  useEffect(() => {
    handleClose();
  }, [selectUser.loading]);

  useEffect(() => {
    if (selectedUser && selectedUser.status) {
      if (selectedUser.status === 'suspend') {
        setIsSuspend(true);
      } else {
        setIsSuspend(false);
      }
    }
  }, [selectedUser]);

  return (
    <ActionModal
      modalTitle={
        isSuspend
          ? intl.formatMessage({
              id: 'unsuspend_this_user',
            })
          : intl.formatMessage({
              id: 'suspend_this_user',
            })
      }
      open={open}
      handleClose={handleClose}
      actinButtonLabel={
        isSuspend
          ? intl.formatMessage({
              id: 'unsuspend',
            })
          : intl.formatMessage({
              id: 'suspend',
            })
      }
      actinButtonOnClick={() =>
        isSuspend
          ? handleUnsuspendUser(selectedUser?._id)
          : handleSuspendUser(selectedUser?._id)
      }
    >
      <Grid container className={classes.userCard}>
        <Grid item xs={1}>
          <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>
              {selectedUser?.profile?.name ||
                intl.formatMessage({
                  id: 'not_available',
                })}
            </b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedUser?.email ||
              intl.formatMessage({
                id: 'not_available',
              })}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(SuspendUserModal));
