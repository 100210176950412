import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { meetingReportsApi, meetingHostListApi } from './meetingReport.api';
import { actions as appActions } from '../../app/app.slice';
import { ApiError } from '../../../components/types';

export interface IMeetingReportsState {
  meetingReports: Array<any>;
  meetingHosts: Array<any>;
  overview: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IMeetingReportsState = {
  meetingReports: [],
  meetingHosts: [],
  overview: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchMeetingReportsAsync = createAsyncThunk(
  'reports/meetings',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await meetingReportsApi(data);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMeetingHostsListAsync = createAsyncThunk(
  'reports/hosts',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await meetingHostListApi();
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const meetingReportSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch meeting reports
    builder.addCase(fetchMeetingReportsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchMeetingReportsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingReports = action?.payload?.data?.meetings;
    });
    builder.addCase(fetchMeetingReportsAsync.rejected, (state, action) => {
      state.loading = false;
      state.meetingReports = [];
      state.error = action.payload;
    });

    // fetch meeting hosts
    builder.addCase(fetchMeetingHostsListAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchMeetingHostsListAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingHosts = action?.payload?.data?.hosts;
    });
    builder.addCase(fetchMeetingHostsListAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = meetingReportSlice;

export const selectMeetingReports = (state: RootState) => state.reports;

export default meetingReportSlice.reducer;
