/* eslint-disable no-unused-vars */
import React from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';

import BasicTextField, { ITextFieldProps } from '../TextField/BasicTextField';
import SettingsItem from './SettingsItem';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
    },
  });
};

export interface ISettingsTextFieldProps
  extends WithStyles<ITextFieldProps & typeof styles> {
  id: string;
  label: string;
  helperText?: string;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
}

export const settingsTextField: React.FC<ISettingsTextFieldProps> = ({
  classes,
  id,
  onChange,
  label,
  value,
  helperText = '',
  placeholder = '',
  ...otherProps
}: ISettingsTextFieldProps) => {
  return (
    <SettingsItem
      id={id}
      label={label}
      helperText={helperText}
      control={
        <BasicTextField
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...otherProps}
        />
      }
    />
  );
};

export default withStyles(styles)(settingsTextField);
