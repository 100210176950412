import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import sendContactusApi, { IContactusData } from './contactus.api';
import { actions as appActions } from '../app/app.slice';
import { ApiError } from '../../components/types';

export interface IContactusState {
  loading: boolean;
  error: ApiError;
}

export interface ILink {
  name: string;
  value: string;
  id?: number;
  error?: string;
}

const initialState: IContactusState = {
  loading: false,
  error: null,
};

export const sendContactusAsync = createAsyncThunk(
  'contactus/send',
  async (data: IContactusData, { rejectWithValue, dispatch }) => {
    try {
      const response = await sendContactusApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_sent_contactus',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const contactusSlice = createSlice({
  name: 'contactus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // update contactus
    builder.addCase(sendContactusAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendContactusAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendContactusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = contactusSlice;

export const selectContactus = (state: RootState): IContactusState =>
  state.contactus;

export default contactusSlice.reducer;
