import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '26px',
    },
    topWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    topButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '31px',
    },
    bottomWrapper: {
      flexGrow: 1,
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '32px',
    },
  });
};

export default styles;
