import React, { memo } from 'react';
import { Theme, Grid, Input } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '../Typography';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    description: {
      margin: theme.spacing(1, 0),
    },
    fileUploadWrapper: {
      height: '10vh',
      minHeight: 60,
      maxHeight: 100,
    },
    leftWrapper: {
      backgroundColor: theme.palette.grey[100],
      height: '100%',
      padding: '0 10px',
      display: 'flex',
      '& .MuiInputBase-input': {
        color: theme.palette.grey[700],
        height: 'auto',
      },
    },
    rightWrapper: {
      height: '100%',
      '& img': {
        maxHeight: '100%',
      },
    },
  });
};

export interface IImageUploadInputProps extends WithStyles<typeof styles> {
  id: string;
  inputTitle?: string;
  name?: string;
  value?: any;
  onChange: (data: any) => void;
  disabled?: boolean;
}

const ImageUploadInput = ({
  classes,
  id,
  inputTitle,
  name,
  value,
  onChange,
  disabled,
}: IImageUploadInputProps) => {
  return (
    <div className={classes.root} id={id}>
      <Typography
        component="h4"
        variant="body2"
        className={classes.description}
      >
        {inputTitle}
      </Typography>
      <Grid container spacing={2} className={classes.fileUploadWrapper}>
        <Grid item xs={6} sm={8} lg={8}>
          <div className={classes.leftWrapper}>
            <Input
              type="file"
              disableUnderline
              name={name}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </Grid>
        <Grid item xs={6} sm={4} lg={4} className={classes.rightWrapper}>
          {value ? (
            <img src={value} alt={name} />
          ) : (
            <div>
              <FormattedMessage id="not_selected" />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(withStyles(styles)(ImageUploadInput));
