/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { IMembersState, fetchMembersAsync } from '../../members/members.slice';
import { IGroup, IGroupsState } from '../groups.slice';
import { useAppDispatch } from '../../../app/hooks';
import {
  IDevice,
  IDevicesState,
  fetchDevicesAsync,
} from '../../devices/devices.slice';
import { IEditGroupData } from '../groups.api';
import { IUser } from '../../auth/auth.slice';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IEditGroupModalProps extends WithStyles<typeof styles> {
  open: boolean;
  selectGroup: IGroupsState;
  selectedGroup: IGroup;
  selectMember: IMembersState;
  selectDevice: IDevicesState;
  allGroups: boolean;
  handleClose: () => void;
  handleEditGroup: (data: IEditGroupData) => void;
}

const EditGroupModal = ({
  classes,
  open,
  handleClose,
  handleEditGroup,
  selectGroup,
  selectedGroup,
  selectMember,
  selectDevice,
  allGroups,
}: IEditGroupModalProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (allGroups && selectedGroup?.parentId?._id) {
      dispatch(fetchMembersAsync({ id: selectedGroup.parentId._id }));
      dispatch(fetchDevicesAsync({ id: selectedGroup.parentId._id }));
    }
  }, [selectedGroup]);

  useEffect(() => {
    handleClose();
    setName({
      error: false,
      value: '',
    });
    setDescription({
      error: false,
      value: '',
    });
    setMemberIds({
      error: false,
      value: [],
    });
    setDeviceIds({
      error: false,
      value: [],
    });
  }, [selectGroup.loading]);

  useEffect(() => {
    if (selectedGroup) {
      setName({
        value: selectedGroup.name,
        error: !selectedGroup.name,
      });
      setDescription({
        value: selectedGroup.description,
        error: description.error,
      });

      const members = [];
      for (const member1 of selectedGroup.members) {
        for (const member2 of selectMember.list.data) {
          if (member1.userId._id === member2._id) {
            members.push(member2);
          }
        }
      }
      setMemberIds({
        value: members,
        error: memberIds.error,
      });

      const devices = [];
      for (const device1 of selectedGroup.devices) {
        for (const device2 of selectDevice.list.data) {
          if (device1.deviceId._id === device2._id) {
            devices.push(device2);
          }
        }
      }
      setDeviceIds({
        value: devices,
        error: deviceIds.error,
      });
    }
  }, [selectMember.list, selectDevice.list, selectedGroup]);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [description, setDescription] = useState({
    error: false,
    value: '',
  });

  interface IMemberIdState {
    error: boolean;
    value: IUser[];
  }

  interface IDeviceIdState {
    error: boolean;
    value: IDevice[];
  }

  const [memberIds, setMemberIds] = useState<IMemberIdState>({
    error: false,
    value: [],
  });
  const [deviceIds, setDeviceIds] = useState<IDeviceIdState>({
    error: false,
    value: [],
  });
  const intl = useIntl();

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (description.error) {
      isValidate = false;
      setDescription({ ...description, error: true });
    }
    if (memberIds.error) {
      isValidate = false;
      setMemberIds({ ...memberIds, error: true });
    }
    if (deviceIds.error) {
      isValidate = false;
      setDeviceIds({ ...deviceIds, error: true });
    }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberIdsArr = [];
      for (const member of memberIds.value) {
        if (member._id) memberIdsArr.push(member._id);
      }
      const deviceIdsArr = [];
      for (const device of deviceIds.value) {
        if (device._id) deviceIdsArr.push(device._id);
      }

      const groupData = {
        groupId: selectedGroup._id,
        name: name.value,
        description: description.value,
        members: memberIdsArr,
        devices: deviceIdsArr,
      };
      handleEditGroup(groupData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const descriptionOnChange = (e: { target: { value: string } }) => {
    setDescription({
      value: e.target.value,
      error: description.error,
    });
  };

  const handleMembersChange = (
    event: ChangeEvent<unknown>,
    value: IUser[],
    reason: AutocompleteChangeReason
  ) => {
    setMemberIds({
      value,
      error: memberIds.error,
    });
  };

  const handleDevicesChange = (
    event: ChangeEvent<unknown>,
    value: IDevice[],
    reason: AutocompleteChangeReason
  ) => {
    setDeviceIds({
      value,
      error: deviceIds.error,
    });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'edit_group',
      })}
      modalDescription={intl.formatMessage({
        id: 'edit_group_to_manage_meetings',
      })}
      id="edit-group-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'continue',
      })}
    >
      <TextField
        id="edit-group-name"
        value={name.value}
        error={name.error}
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
        required
        helperText={name.error && 'Please insert a name.'}
      />
      <TextField
        id="edit-group-description"
        value={description.value}
        error={description.error}
        placeholder={intl.formatMessage({
          id: 'description',
        })}
        onChange={descriptionOnChange}
        helperText={description.error && 'Please insert a valid description.'}
      />
      <AutocompleteTextField
        multiple
        limitTags={2}
        id="edit-group-members"
        options={selectMember.list.data}
        getOptionLabel={(option) => option.profile.name}
        value={memberIds.value}
        onChange={handleMembersChange}
        label={intl.formatMessage({
          id: 'members',
        })}
        placeholder={intl.formatMessage({
          id: 'select_members',
        })}
        error={memberIds.error}
      />
      <AutocompleteTextField
        multiple
        limitTags={2}
        id="edit-group-devices"
        options={selectDevice.list.data}
        getOptionLabel={(option) =>
          option.userDeviceName || option.deviceName || ''
        }
        value={deviceIds.value}
        onChange={handleDevicesChange}
        label={intl.formatMessage({
          id: 'devices',
        })}
        placeholder={intl.formatMessage({
          id: 'select_devices',
        })}
        error={memberIds.error}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(EditGroupModal));
