import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export interface IContactusData {
  name: string;
  email: string;
  contactNumber: string;
  message: string;
}

const sendContactusApi = (data: IContactusData): Promise<AxiosResponse> => {
  return api.post(`misc/contactus`, data);
};

export default sendContactusApi;
