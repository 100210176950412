import { combineReducers } from 'redux';

import { authReducer } from '../features/auth';
import { meetingsReducer } from '../features/meetings';
import { scheduleMeetingsReducer } from '../features/scheduleMeeting';
import { usersReducer } from '../features/users';
import { membersReducer } from '../features/members';
import { customersReducer } from '../features/customers';
import { appReducer } from '../features/app';
import { meetingRoomReducer } from '../features/meetingRoom';
import { settingsReducer } from '../features/settings';
import { meetingReportsReducer } from '../features/reports/meetingReport';
import { emailRouterReducer } from '../features/emailRouter';
import { groupsReducer } from '../features/group';
import { devicesReducer } from '../features/devices';
import { contactusReducer } from '../features/subPages';
import { livestreamingReducer } from '../features/livestreaming';
import { scheduledEventReducer } from '../features/scheduledEvents';
import { replaystreamingReducer } from '../features/replaystreaming';
import { replaystreamingRoomReducer } from '../features/replaystreamingRoom';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  settings: settingsReducer,
  meetings: meetingsReducer,
  scheduleMeeting: scheduleMeetingsReducer,
  meetingRoom: meetingRoomReducer,
  users: usersReducer,
  members: membersReducer,
  customers: customersReducer,
  reports: meetingReportsReducer,
  emailRouter: emailRouterReducer,
  groups: groupsReducer,
  devices: devicesReducer,
  contactus: contactusReducer,
  livestreaming: livestreamingReducer,
  replaystreaming: replaystreamingReducer,
  replaystreamingRoom: replaystreamingRoomReducer,
  scheduledEvent: scheduledEventReducer,
});

export default rootReducer;
