import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    topWrapper: {
      width: '100%',
      marginBottom: '2vh',
      display: 'flex',
      alignItems: 'center',
    },
    bottomWrapper: {
      position: 'relative',
      flexGrow: 1,
      overflow: 'auto',
    },
    mainGridItem: {
      height: '50%',
      overflow: 'auto',
      '& .MuiCard-root': {
        overflow: 'auto',
      },
    },
    cardContentWrapper: {
      // overflow: 'auto',
      // height: '90%',
    },
    cardGridWrapper: {},
    cardWithoutTable: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    cardTitle: {
      fontSize: '1.2rem',
      height: 64,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
    },
    memberTextfieldsWrapper: {
      // height: '20vh',
      // overflow: 'auto',
    },
    groupWrapper: {
      height: 'calc(100% - 70px)',
    },
    groupTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    groupTitle: {
      marginBottom: 5,
      fontWeight: 'bold',
    },
    groupMemberName: {
      // width: '40%',
    },
    deleteButtonsWrapper: {
      display: 'flex',
      '& :first-child': {
        marginRight: 10,
      },
    },
    card: {},
    tableWrapper: {
      overflow: 'auto',
      width: '100%',
    },
  });
};

export default styles;
