/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  assignDeviceAsync,
  unassignDevicesAsync,
  fetchDevicesAsync,
  selectDevices,
  resetDevicesAsync,
  editDevicesAsync,
} from './devices.slice';
import { selectAuth } from '../auth/auth.slice';
import DevicesView from './devices.view';
import { actions as appActions } from '../app/app.slice';
import { IGetDevicesArgs } from './devices.api';

export interface IDevicesContainer {}

const DevicesContainer: React.FC<IDevicesContainer> = ({}: IDevicesContainer) => {
  const devices = useAppSelector(selectDevices);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDevicesAsync({ limit: 500 }));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10119'));
  }, []);

  const handleAddDevice = (data: any) => {
    dispatch(assignDeviceAsync(data));
  };

  const handleDeleteDevice = (data: any) => {
    dispatch(unassignDevicesAsync(data));
  };

  const handleResetDevice = (id: string) => {
    dispatch(resetDevicesAsync(id));
  };

  const handleEditDevice = (data: any) => {
    dispatch(editDevicesAsync(data));
  };

  const handleGetDevices = (data: IGetDevicesArgs) => {
    dispatch(fetchDevicesAsync(data));
  };

  return (
    <DevicesView
      selectDevice={devices}
      devicesList={devices.list}
      permissions={auth.permissions}
      handleAddDevice={handleAddDevice}
      handleDeleteDevice={handleDeleteDevice}
      handleResetDevice={handleResetDevice}
      handleEditDevice={handleEditDevice}
      handleGetDevices={handleGetDevices}
    />
  );
};

export default memo(DevicesContainer);
