import { AxiosResponse } from 'axios';
import api from '../../app/axios';
import { IRequestParams } from '../../components/types';

export interface IGetDevicesArgs extends IRequestParams {
  id?: string;
}

export const fetchDevicesApi = (
  data: IGetDevicesArgs
): Promise<AxiosResponse> => {
  const { id, ...filteredData } = data;
  if (id) {
    return api.post(`device/read/${id}`, filteredData);
  }
  return api.post('device/read', filteredData);
};

export const fetchDeviceRemoteApi = (): Promise<AxiosResponse> => {
  return api.get('device/readremote');
};

export const fetchAllDevicesApi = (
  data: IGetDevicesArgs
): Promise<AxiosResponse> => {
  return api.post('device/readall', data);
};

export const assignDeviceApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`device/assign`, data);
};

export const assignDeviceForAnyCustomerApi = (
  data: any
): Promise<AxiosResponse> => {
  const { deviceId, privacyEnabled, privacyTimeout } = data;
  return api.post(`device/assign/${data.customerId}`, {
    deviceId,
    privacyEnabled,
    privacyTimeout,
  });
};

export const unassignDeviceApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`device/unassign`, data);
};

export const unassignAnyDeviceApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`device/unassignany`, data);
};

export const editDeviceApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`device/edit`, data);
};

export const editAnyDeviceApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`device/editany`, data);
};

export const resetDeviceApi = (id: string): Promise<AxiosResponse> => {
  return api.get(`device/reset/${id}`);
};

export const resetAnyDeviceApi = (id: string): Promise<AxiosResponse> => {
  return api.get(`device/resetany/${id}`);
};
