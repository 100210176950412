import React, { memo } from 'react';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IconButton } from '../Button';

const styles = (theme: Theme) => {
  return createStyles({
    paginationWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingTop: '24px',
    },
    paginationText: {
      marginRight: '26px',
      fontSize: '12px',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    arrowButton: {
      margin: 0,
      '& .MuiButton-root': {
        minWidth: '40px',
        color: theme.palette.secondary.main,
      },
      '& .Mui-disabled': {
        color: '#00000061',
      },
      '& :hover': {
        backgroundColor: 'unset',
      },
    },
  });
};

export interface IPaginationProps extends WithStyles<typeof styles> {
  itemsPerPage: number;
  firstItemIndex: number;
  lastItemIndex: number;
  totalItems: number;
  currentPage: number;
  setCurrentPage: any;
}

const Pagination = ({
  classes,
  itemsPerPage = 2,
  firstItemIndex = 1,
  lastItemIndex = 3,
  totalItems = 5,
  currentPage = 1,
  setCurrentPage = console.log('Arrow clicked'),
}: IPaginationProps) => {
  return (
    <div className={classes.paginationWrapper}>
      <div className={classes.paginationText}>
        {itemsPerPage < 2
          ? firstItemIndex + 1
          : `${firstItemIndex + 1} - ${lastItemIndex}`}{' '}
        of {totalItems}
      </div>
      <div className={classes.buttonWrapper}>
        <IconButton
          id="ffff"
          icon={<ArrowBackIosIcon />}
          variant="text"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={firstItemIndex === 0 || !totalItems}
          customStyles={classes.arrowButton}
        />
        <IconButton
          id="ffff"
          icon={<ArrowForwardIosIcon />}
          variant="text"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={totalItems <= lastItemIndex}
          customStyles={classes.arrowButton}
        />
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(Pagination));
