/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import api from '../../../app/axios';

export const meetingReportsApi = (data: any): Promise<AxiosResponse> => {
  return api.post('reports/meetings', data);
};

export const meetingHostListApi = (): Promise<AxiosResponse> => {
  return api.post('reports/hosts');
};
