/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { validateEmail } from '../../../helper/validation';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IEditUserModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleEditUser: (data: any) => void;
  selectUser: any;
  selectedUser: any;
}

const EditUserModal = ({
  classes,
  open,
  handleClose,
  handleEditUser,
  selectUser,
  selectedUser,
}: IEditUserModalProps) => {
  useEffect(() => {
    handleClose();
  }, [selectUser.loading]);

  useEffect(() => {
    if (selectedUser) {
      setName({
        value: selectedUser.profile.name,
        error: !selectedUser.profile.name,
      });
      setEmail({
        value: selectedUser.email,
        error: !selectedUser.email,
      });
    }
  }, [selectedUser]);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [email, setEmail] = useState({
    error: false,
    value: '',
  });

  const intl = useIntl();

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const adminData = {
        id: selectedUser._id,
        name: name.value,
        contactNumber: null,
        email: email.value,
      };
      handleEditUser(adminData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'edit_admin',
      })}
      id="edit-admin-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'continue',
      })}
    >
      <TextField
        id="edit-admin-name"
        value={name.value}
        error={name.error}
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
        required
        helperText={name.error && 'Please insert a name.'}
      />
      <TextField
        id="edit-admin-email"
        value={email.value}
        error={email.error}
        placeholder={intl.formatMessage({
          id: 'email',
        })}
        onChange={emailOnChange}
        required
        helperText={email.error && 'Please insert a valid email.'}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(EditUserModal));
