import React, { memo, useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Theme, Grid } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { IconTextField } from '../TextField';
import { Card } from '../Card';
import { TimePicker } from '../TimePicker';
import BasicButton from '../Button/BasicButton';
import { IRequestParams } from '../types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      // position: 'absolute',
      // top: 80,
      // right: 0,
      // zIndex: 2,
      // [theme.breakpoints.down('md')]: {
      //   top: 84,
      // },
    },
    rootExpanded: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    searchButton: {
      padding: 5,
      color: theme.palette.secondary.main,
      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
    },
    textField: {
      width: 340,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    expandWrapper: {
      position: 'relative',
      backgroundColor: '#fff',
    },
    card: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      boxShadow: '0px 11px 15px -7px #00000033',
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      gap: 8,
      marginTop: 24,
    },
  });
};

export interface ITableSearchProps extends WithStyles<typeof styles> {
  id?: string;
  handleSearch: (data: IRequestParams) => void;
}

const TableSearch = ({ classes, handleSearch }: ITableSearchProps) => {
  const intl = useIntl();
  const [isExpand, setIsExpand] = useState(false);
  const [search, setSearch] = useState({
    value: '',
    error: '',
  });
  const [startTime, setStartTime] = useState({
    value: '',
    error: '',
  });
  const [endTime, setEndTime] = useState({
    value: '',
    error: '',
  });

  const _handleSearchChange = (e: { target: { value: string } }) => {
    setSearch({
      value: e.target.value,
      error: '',
    });
  };

  const _handleStartTimeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (!endTime.value || new Date(e.target.value) < new Date(endTime.value)) {
      setStartTime({ value: e.target.value, error: '' });
      setEndTime({ ...endTime, error: '' });
    } else {
      setStartTime({
        value: e.target.value,
        error: intl.formatMessage({
          id: 'end_time_cannot_be_before_begin_time',
        }),
      });
    }
  };

  const _handleEndTimeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (
      !startTime.value ||
      new Date(startTime.value) < new Date(e.target.value)
    ) {
      setEndTime({ value: e.target.value, error: '' });
      setStartTime({ ...startTime, error: '' });
    } else {
      setEndTime({
        value: e.target.value,
        error: intl.formatMessage({
          id: 'end_time_cannot_be_before_begin_time',
        }),
      });
    }
  };

  const _validate = () => {
    let isValidate = true;

    if (startTime.error || endTime.error) isValidate = false;

    if (new Date(startTime.value) > new Date(endTime.value)) {
      isValidate = false;
      setEndTime({
        ...endTime,
        error: intl.formatMessage({
          id: 'end_time_cannot_be_before_begin_time',
        }),
      });
    }

    return isValidate;
  };

  const onClickSearch = () => {
    if (_validate()) {
      const data = [
        {
          name: 'name',
          value: search.value,
        },
        {
          name: 'startTime',
          value: startTime.value ? new Date(startTime.value).toISOString() : '',
        },
        {
          name: 'endTime',
          value: endTime.value ? new Date(endTime.value).toISOString() : '',
        },
      ];
      handleSearch({ filters: data });
    }
    setIsExpand(false);
  };

  const handleReset = () => {
    setSearch({
      value: '',
      error: '',
    });
    setStartTime({
      value: '',
      error: '',
    });
    setEndTime({
      value: '',
      error: '',
    });
    const data = [
      {
        name: 'name',
        value: '',
      },
      {
        name: 'startTime',
        value: '',
      },
      {
        name: 'endTime',
        value: '',
      },
    ];
    handleSearch({ filters: data });
  };

  return (
    <div className={clsx(classes.root, isExpand && classes.rootExpanded)}>
      <div>
        {!isExpand ? (
          <IconButton
            aria-label="search"
            className={classes.searchButton}
            onClick={() => setIsExpand(!isExpand)}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        ) : (
          <ClickAwayListener onClickAway={() => setIsExpand(false)}>
            <div className={classes.expandWrapper}>
              <IconTextField
                customStyles={classes.textField}
                placeholder={intl.formatMessage({
                  id: 'search',
                })}
                icon={<SearchIcon fontSize="small" />}
                value={search.value}
                onChange={(e) => _handleSearchChange(e)}
                error={!!search.error}
                helperText={search.error}
              />
              <Card customStyles={classes.card}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TimePicker
                      label={intl.formatMessage({
                        id: 'start_time',
                      })}
                      type="datetime-local"
                      id="table-search-start-time"
                      onChange={(e) => _handleStartTimeChange(e)}
                      value={startTime.value}
                      error={!!startTime.error}
                      helperText={startTime.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePicker
                      label={intl.formatMessage({
                        id: 'end_time',
                      })}
                      type="datetime-local"
                      id="table-search-end-time"
                      onChange={(e) => _handleEndTimeChange(e)}
                      value={endTime.value}
                      error={!!endTime.error}
                      helperText={endTime.error}
                    />
                  </Grid>
                </Grid>
                <div className={classes.buttonWrapper}>
                  <BasicButton
                    color="primary"
                    label={intl.formatMessage({
                      id: 'reset',
                    })}
                    onClick={handleReset}
                  />
                  <BasicButton
                    color="primary"
                    variant="contained"
                    label={intl.formatMessage({
                      id: 'search',
                    })}
                    onClick={onClickSearch}
                  />
                </div>
              </Card>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(TableSearch));
