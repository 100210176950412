/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addCustomerAsync,
  deleteCustomersAsync,
  demoteSubadminAsync,
  editCustomerAsync,
  fetchCustomersAsync,
  promoteCustomerAsync,
  resetCustomersPasswordAsync,
  selectCustomers,
  suspendCustomerAsync,
  unsuspendCustomerAsync,
} from './customers.slice';
import { selectAuth } from '../auth/auth.slice';
import CustomersView from './customers.view';
import { actions as appActions } from '../app/app.slice';
import { IGetCustomersArgs } from './customers.api';

export interface ICustomersContainer {}

const CustomersContainer: React.FC<ICustomersContainer> = ({}: ICustomersContainer) => {
  const customers = useAppSelector(selectCustomers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCustomersAsync({}));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10106'));
  }, []);

  const handleAddCustomer = (data: any) => {
    dispatch(addCustomerAsync(data));
  };

  const handleDeleteCustomer = (id: string) => {
    dispatch(deleteCustomersAsync(id));
  };

  const handlePromoteCustomer = (id: string) => {
    dispatch(promoteCustomerAsync(id));
  };

  const handleDemoteSubadmin = (id: string) => {
    dispatch(demoteSubadminAsync(id));
  };

  const handleRestCustomerPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetCustomersPasswordAsync(data));
  };

  const handleEditCustomer = (data: any) => {
    dispatch(editCustomerAsync(data));
  };

  const handleSuspendCustomer = (id: string) => {
    dispatch(
      suspendCustomerAsync({
        id,
      })
    );
  };

  const handleUnsuspendCustomer = (id: string) => {
    dispatch(
      unsuspendCustomerAsync({
        id,
      })
    );
  };

  const handleGetCustomers = (data: IGetCustomersArgs) => {
    dispatch(fetchCustomersAsync(data));
  };

  return (
    <CustomersView
      selectCustomer={customers}
      customersList={customers.list}
      permissions={auth.permissions}
      handleAddCustomer={handleAddCustomer}
      handleDeleteCustomer={handleDeleteCustomer}
      handleRestCustomerPassword={handleRestCustomerPassword}
      handlePromoteCustomer={handlePromoteCustomer}
      handleDemoteSubadmin={handleDemoteSubadmin}
      handleEditCustomer={handleEditCustomer}
      handleSuspendCustomer={handleSuspendCustomer}
      handleUnsuspendCustomer={handleUnsuspendCustomer}
      handleGetCustomers={handleGetCustomers}
    />
  );
};

export default memo(CustomersContainer);
