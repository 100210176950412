import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    toolbar: {},
    editorWrapper: {
      maxHeight: 'calc(100% - 44px)',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    editor: {
      flexGrow: 1,
    },
  });
};
interface ITermsSettingsView extends WithStyles<typeof styles> {
  settings: any;
  handleUpdateSettings: (data: any) => void;
}

const TermsSettingsView: React.FC<ITermsSettingsView> = ({
  classes,
  settings,
  handleUpdateSettings,
}: ITermsSettingsView) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const intl = useIntl();

  useEffect(() => {
    if (settings?.data?.terms) {
      const termsSetting = EditorState.createWithContent(
        convertFromRaw(JSON.parse(settings?.data?.terms))
      );

      setEditorState(termsSetting);
    }
  }, [settings.data]);

  const _onEditorStateChange = (value: any) => {
    setEditorState(value);
  };

  const _handlePublishButtonClick = () => {
    const terms = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const data = {
      setting: { terms },
      category: 'terms',
    };
    handleUpdateSettings(data);
  };
  return (
    <Card customStyles={classes.root}>
      <Editor
        editorState={editorState}
        toolbarClassName={classes.toolbar}
        wrapperClassName={classes.editorWrapper}
        editorClassName={classes.editor}
        onEditorStateChange={_onEditorStateChange}
      />
      <Button
        color="primary"
        id="terms-publish-button"
        label={intl.formatMessage({
          id: 'publish',
        })}
        variant="contained"
        onClick={_handlePublishButtonClick}
      />
    </Card>
  );
};

export default memo(withStyles(styles)(TermsSettingsView));
