/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import MeetingsView from './meetings.view';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { generateMeetingUrl } from '../../helper/conference';
import {
  selectMeetings,
  fetchAllMeetingsAsync,
  removeMeetingAsync,
} from './meetings.slice';
import { actions as scheduleMeetingActions } from '../scheduleMeeting/scheduleMeeting.slice';
import { selectAuth } from '../auth/auth.slice';
// import { ScheduleMeetingModal, InstantMeetingmodal } from '../scheduleMeeting';
import { actions as appActions } from '../app/app.slice';

export interface IMeetingsContainer {}

const MeetingsContainer: React.FC<IMeetingsContainer> = ({}: IMeetingsContainer) => {
  const meetings = useAppSelector(selectMeetings);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [meetingList, setMeetingList] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(fetchAllMeetingsAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    // const dayEnd = moment().endOf('day').toISOString();

    // const past = meetings?.list
    //   ?.filter(({ beginTime }) => beginTime <= now)
    //   .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    // const today = meetings?.list
    //   ?.filter(({ beginTime }) => beginTime >= now && beginTime <= dayEnd)
    //   .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const all = meetings?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'completed';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const sortMeetings = all.sort(
      (a, b) =>
        new Date(b.beginTime).getTime() - new Date(a.beginTime).getTime()
    );

    setMeetingList(sortMeetings);
  }, [meetings.list]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10108'));
  }, []);

  const _joinToMeetingButtonClick = (data: any) => {
    const url = generateMeetingUrl(data?.location, false);
    history.push(url);
  };

  const _removeMeeting = (id: string) => {
    dispatch(removeMeetingAsync(id));
  };

  const _openEditMeetingModal = (data: any) => {
    dispatch(scheduleMeetingActions.openEditMeetingModal(data));
  };
  const _openViewMeetingModal = (data: any) => {
    dispatch(scheduleMeetingActions.openViewMeetingModal(data));
  };

  return (
    <>
      <MeetingsView
        user={auth?.user}
        meetings={meetingList}
        joinToMeetingButtonClick={_joinToMeetingButtonClick}
        removeMeeting={_removeMeeting}
        openEditMeetingModal={_openEditMeetingModal}
        openViewMeetingModal={_openViewMeetingModal}
      />
      {/* <ScheduleMeetingModal />
      <InstantMeetingmodal /> */}
    </>
  );
};

export default memo(MeetingsContainer);
