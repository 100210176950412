import React, { memo } from 'react';
import { Card, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      borderRadius: '10px',
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[100],
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: 24,
      overflow: 'unset',
    },
  });
};

export interface IMeetingCardProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  customStyles?: any;
  id?: string;
}

const MeetingCard = ({
  classes,
  id,
  children,
  customStyles,
}: IMeetingCardProps) => {
  return (
    <Card className={clsx(classes.root, customStyles && customStyles)} id={id}>
      {children}
    </Card>
  );
};

export default memo(withStyles(styles)(MeetingCard));
