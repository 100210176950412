import React from 'react';
import { Button, ButtonProps, Theme, Typography } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import {
  FacebookIcon,
  InstaIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
  linkedinIcon,
} from '../../assets/icons';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import FacebookIcon from '../../assets/Login/Facebook.png';
// import GoogleIcon from '../../assets/Login/google.png';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: 0,
      padding: 0,
      width: '100%',
    },
    iconWrapper: {
      '& img': {
        height: '100%',
      },
    },
  });
};

export interface ButtonComponentProps
  extends WithStyles<ButtonProps & typeof styles> {
  onClick: string;
  icon?: string;
  id?: string;
  text?: string;
}

export const SocialMediaLink: React.FC<ButtonComponentProps> = ({
  classes,
  id,
  onClick,
  icon,
  text,
  ...otherProps
}: ButtonComponentProps) => {
  const getIcon = () => {
    switch (icon) {
      case 'facebook':
        return FacebookIcon;

      case 'instagram':
        return InstaIcon;

      case 'linkedin':
        return linkedinIcon;

      case 'twitter':
        return TwitterIcon;

      case 'youtube':
        return YoutubeIcon;

      case 'tiktok':
        return TiktokIcon;

      default:
        return FacebookIcon;
    }
  };

  return (
    <a
      id={id}
      className={classes.root}
      href={onClick.includes('http') ? onClick : `https://${onClick}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className={classes.iconWrapper}>
        <img src={getIcon()} alt="Social media icon" />
      </div>
    </a>
  );
};

export default withStyles(styles)(SocialMediaLink);
