import React, { memo, useState, useEffect, useCallback } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import { TextField, TextArea } from '../../../components/TextField';
import { Card } from '../../../components/Card';
import { DeleteFAQModal } from './index';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    rightSide: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    rightTitleWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(4),
    },
    rightTitle: {
      flexGrow: 1,
    },
    cardWrapper: {
      flexGrow: 1,
      overflow: 'auto',
    },
    card: {
      height: 'auto',
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    buttonWrapper: {
      display: 'flex',
    },
    leftButton: {
      '& .basic-button-class': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    textField: {
      flexGrow: 1,
      '& #TextareaAutosize': {
        flexGrow: 1,
        maxHeight: '100%',
        maxWidth: '100%',
        resize: 'none',
      },
    },
  });
};

interface IFAQSettingsView extends WithStyles<typeof styles> {
  settings: any;
  handleAddFAQ: (data: any) => void;
  handleUpdateFAQ: (data: any) => void;
  handleDeleteFAQ: (id: string) => void;
}

const FAQSettingsView: React.FC<IFAQSettingsView> = ({
  classes,
  settings,
  handleAddFAQ,
  handleUpdateFAQ,
  handleDeleteFAQ,
}: IFAQSettingsView) => {
  const [title, setTitle] = useState<any>({ value: '', error: '' });
  const [discription, setDiscription] = useState<any>({
    value: '',
    error: '',
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedFAQ, setSelectedFAQ] = useState<any>(null);
  const [isDeleteFAQOpen, setIsDeleteFAQOpen] = useState<boolean>(false);

  const _titleOnChange = (e: any) => {
    setTitle({ value: e.target.value, error: false });
  };

  const _discriptionOnChange = (e: any) => {
    setDiscription({ value: e.target.value, error: false });
  };

  const _validateAddFAQ = () => {
    let isValidate = true;

    if (title.error || discription.error) isValidate = false;

    if (!title.value) {
      isValidate = false;
      setTitle({ ...title, error: 'Title is required' });
    }

    if (!discription.value) {
      isValidate = false;
      setDiscription({ ...discription, error: 'Dscription is required' });
    }

    return isValidate;
  };

  const _publishButtonOnClick = () => {
    if (_validateAddFAQ()) {
      const data = {
        title: title.value,
        answer: discription.value,
      };
      if (isEdit) handleUpdateFAQ({ faq: data, id: selectedFAQ?._id });
      else handleAddFAQ(data);
    }
  };

  const _editButtonOnClick = (data: any) => {
    setIsEdit(true);
    setSelectedFAQ(data);
    setTitle({ value: data?.title, error: '' });
    setDiscription({ value: data?.answer, error: '' });
  };

  const _deleteButtonOnClick = (data: any) => {
    setIsDeleteFAQOpen(true);
    setSelectedFAQ(data);
  };

  const _handleCloseDeleteFAQModal = () => {
    setIsDeleteFAQOpen(false);
    setSelectedFAQ(null);
  };

  const _handleDeleteFAQ = (id: string) => {
    handleDeleteFAQ(id);
  };

  const renderFAQList = useCallback(() => {
    return settings?.data?.faq?.map(
      (faq: { title: string; answer: string }) => {
        return (
          <Card customStyles={classes.card}>
            <Typography variant="h6" component="p">
              {faq?.title}
            </Typography>
            <Typography variant="body1" component="p" color="textSecondary">
              {faq.answer}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                customStyles={classes.leftButton}
                id="faq-delete-button"
                label="DELETE"
                variant="text"
                onClick={() => _deleteButtonOnClick(faq)}
              />
              <Button
                id="faq-edit-button"
                label="EDIT"
                variant="text"
                onClick={() => _editButtonOnClick(faq)}
              />
            </div>
          </Card>
        );
      }
    );
  }, [settings.data?.faq]);

  return (
    <Card>
      <Grid container className={classes.root}>
        <Grid item xs={6} className={classes.leftSide}>
          <Typography component="h3" variant="h6">
            FAQ
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            The Field Labels Marked With * Are Required Input Fields.
          </Typography>
          <TextField
            id="faq-title"
            label="Title"
            value={title.value}
            onChange={_titleOnChange}
            required
            error={Boolean(title.error)}
            helperText={title.error}
          />
          <TextArea
            id="faq-description"
            label="Description *"
            required
            value={discription.value}
            onChange={_discriptionOnChange}
            error={Boolean(discription.error)}
            helperText={discription.error}
            customStyles={classes.textField}
          />
          <Button
            id="faq-publish-button"
            variant="contained"
            label="Publish"
            onClick={_publishButtonOnClick}
          />
        </Grid>
        <Grid item xs={6} className={classes.rightSide}>
          <div className={classes.rightTitleWrapper}>
            <Typography
              component="h3"
              variant="h6"
              className={classes.rightTitle}
            >
              Added FAQ List
            </Typography>
            <Button id="faq-preview-button" variant="text" label="Preview" />
          </div>
          <div className={classes.cardWrapper}>
            <div>{renderFAQList()}</div>
          </div>
          <DeleteFAQModal
            faq={selectedFAQ}
            open={isDeleteFAQOpen}
            handleClose={_handleCloseDeleteFAQModal}
            handleDeleteFAQ={_handleDeleteFAQ}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default memo(withStyles(styles)(FAQSettingsView));
