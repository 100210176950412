/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectMeetings,
  fetchAllMeetingsAsync,
  removeMeetingAsync,
} from '../meetings/meetings.slice';
import { selectAuth } from '../auth/auth.slice';
import {
  instantMeetingAsync,
  actions as scheduleMeetingActions,
  selectSchesuleMeeting,
} from '../scheduleMeeting/scheduleMeeting.slice';
import DashboardView from './dashboard.view';
import { generateMeetingUrl } from '../../helper/conference';
import { fetchDevicesAsync, selectDevices } from '../devices/devices.slice';
import { IRequestParams } from '../../components/types';
import { actions as appActions } from '../app/app.slice';
import {
  readLivestreamingsAsync,
  selectLivestreaming,
  actions as livestreamingActions,
  deleteLivestreamingAsync,
} from '../livestreaming/livestreaming.slice';
import {
  deleteScheduledEventAsync,
  readScheduledEventAsync,
  selectScheduledEvent,
  actions as scheduledEventActions,
} from '../scheduledEvents/scheduledEvent.slice';

export interface IDashboardContainer {}

const DashboardContainer: React.FC<IDashboardContainer> = ({}: IDashboardContainer) => {
  const scheduleMeeting = useAppSelector(selectSchesuleMeeting);
  const meetings = useAppSelector(selectMeetings);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const devices = useAppSelector(selectDevices);
  const livestreaming = useAppSelector(selectLivestreaming);
  const scheduledEvent = useAppSelector(selectScheduledEvent);

  // const [upcomingMeetings, setUpcomingMeetings] = useState<Array<any>>([]);
  // const [ongoingMeetings, setOngoingMeetings] = useState<Array<any>>([]);
  // const [recentMeetings, setRecentMeetings] = useState<Array<any>>([]);
  const [todayMeetings, setTodayMeetings] = useState<Array<any>>([]);
  const [allMeetings, setAllMeetings] = useState<Array<any>>([]);
  const [allStreamings, setAllStreamings] = useState<Array<any>>([]);
  const [allScheledEvents, setAllScheledEvents] = useState<Array<any>>([]);

  useEffect(() => {
    if (scheduleMeeting.success && scheduleMeeting.isSpeeddialInprogress) {
      dispatch(scheduleMeetingActions.closeSpeeddial());
      const url = generateMeetingUrl(scheduleMeeting.data?.location, false);
      history.push(url);
    }
  }, [scheduleMeeting.success]);

  useEffect(() => {
    if (
      scheduleMeeting.meetingLocation &&
      scheduleMeeting.isSpeeddialInprogress
    ) {
      dispatch(scheduleMeetingActions.closeSpeeddial());
      const url = generateMeetingUrl(scheduleMeeting.meetingLocation, false);
      dispatch(scheduleMeetingActions.setMeetingLocation(''));
      history.push(url);
    }
  }, [scheduleMeeting.meetingLocation]);

  useEffect(() => {
    dispatch(fetchAllMeetingsAsync(null));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDevicesAsync({
        limit: 500,
        sortOrder: { name: 'favourite', direction: 'desc' },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10102'));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    // const dayStart = moment().startOf('day').toISOString();
    const dayEnd = moment().endOf('day').toISOString();

    // const past = meetings?.list
    //   ?.filter(({ beginTime }) => beginTime <= now)
    //   .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    const today = meetings?.list
      ?.filter(({ beginTime }) => beginTime >= now && beginTime <= dayEnd)
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const all = meetings?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    // const ongoingMeetingsList = all
    //   ?.filter(
    //     (meeting) =>
    //       meeting.meetingType === 'ongoing' && meeting.type !== 'instant'
    //   )
    //   .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    // const upcomingMeetingsList = all
    //   ?.filter(
    //     (meeting) =>
    //       meeting.meetingType === 'upcoming' && meeting.type !== 'instant'
    //   )
    //   .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    // const pastMeetingsList = all
    //   ?.filter(
    //     (meeting) =>
    //       meeting.meetingType === 'past' && meeting.type !== 'instant'
    //   )
    //   .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1))
    //   .slice(0, 10); // latest 10 past meetings
    // const allMeetingsForDashboardView = [
    //   ...ongoingMeetingsList,
    //   ...upcomingMeetingsList,
    //   ...pastMeetingsList,
    // ];
    const sortedItems = all.sort((a, b) =>
      a.beginTime - b.beginTime ? 1 : -1
    );

    setTodayMeetings(today);
    // setRecentMeetings(past);
    // setUpcomingMeetings(upcomingMeetingsList);
    // setOngoingMeetings(ongoingMeetingsList);
    setAllMeetings(sortedItems);
  }, [meetings.list]);

  const _scheduleMeetingOnClick = () => {
    dispatch(scheduleMeetingActions.openScheduleModal());
  };

  const _instantMeetingOnClick = () => {
    dispatch(scheduleMeetingActions.openInstantMeetingModal());
  };

  const _handleSpeeddial = (data: any) => {
    dispatch(scheduleMeetingActions.openSpeeddial());
    dispatch(instantMeetingAsync(data));
  };

  const _handleSpeeddialJoinCall = (meetingLocation: string) => {
    dispatch(scheduleMeetingActions.openSpeeddial());
    dispatch(scheduleMeetingActions.setMeetingLocation(meetingLocation));
  };

  const _joinToMeetingButtonClick = (data: any) => {
    const url = generateMeetingUrl(data?.location, false);
    history.push(url);
  };

  const _handleSearch = (data: IRequestParams) => {
    dispatch(fetchAllMeetingsAsync(data));
  };

  const _openEditMeetingModal = (data: any) => {
    dispatch(scheduleMeetingActions.openEditMeetingModal(data));
  };

  const _removeMeeting = (id: string) => {
    dispatch(removeMeetingAsync(id));
  };

  //
  //
  // Streamings ------------------------------------------------------

  useEffect(() => {
    dispatch(readLivestreamingsAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    const all = livestreaming?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const sortedItems = all.sort((a, b) =>
      a.beginTime - b.beginTime ? 1 : -1
    );

    setAllStreamings(sortedItems);
  }, [livestreaming.list]);

  const _scheduleStreamingOnClick = () => {
    dispatch(livestreamingActions.openCreateLivestreamingModal());
  };

  const _joinToStreamingButtonClick = (location: string) => {
    const url = generateMeetingUrl(location, false);
    history.push(url);
  };

  const _handleSearchStreaming = (data: IRequestParams) => {
    dispatch(readLivestreamingsAsync(data));
  };

  const _openUpdateLivestreamingModal = (data: any) => {
    dispatch(livestreamingActions.openUpdateLivestreamingModal(data));
  };

  const _openViewLivestreamingModal = (data: any) => {
    dispatch(livestreamingActions.openViewLivestreamingModal(data));
  };

  const _removeStreaming = (id: string) => {
    dispatch(deleteLivestreamingAsync(id));
  };

  //
  // ScheduledEvents ------------------------------------

  useEffect(() => {
    dispatch(readScheduledEventAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    const all = scheduledEvent?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const sortedItems = all.sort((a, b) =>
      a.beginTime - b.beginTime ? 1 : -1
    );

    setAllScheledEvents(sortedItems);
  }, [scheduledEvent.list]);

  const _createScheduledEventOnClick = () => {
    dispatch(scheduledEventActions.openCreateScheduledEventModal());
  };

  const _joinScheduledEventOnClick = (location: string) => {
    const url = generateMeetingUrl(location, false);
    history.push(url);
  };

  const _handleSearchScheduledEvent = (data: IRequestParams) => {
    dispatch(readScheduledEventAsync(data));
  };

  const _openUpdateScheduledEventModal = (data: any) => {
    dispatch(scheduledEventActions.openUpdateScheduledEventModal(data));
  };

  const _openViewScheduledEventModal = (data: any) => {
    dispatch(scheduledEventActions.openViewScheduledEventModal(data));
  };

  const _removeScheduledEvent = (id: string) => {
    dispatch(deleteScheduledEventAsync(id));
  };

  return (
    <>
      <DashboardView
        // upcomingMeetings={upcomingMeetings}
        // ongoingMeetings={ongoingMeetings}
        // recentMeetings={recentMeetings}
        todayMeetings={todayMeetings}
        allMeetings={allMeetings}
        user={auth?.user}
        permissions={auth?.permissions}
        overview={meetings?.overview || []}
        scheduleMeetingOnClick={_scheduleMeetingOnClick}
        instantMeetingOnClick={_instantMeetingOnClick}
        joinToMeetingButtonClick={_joinToMeetingButtonClick}
        openEditMeetingModal={_openEditMeetingModal}
        handleSpeeddial={_handleSpeeddial}
        devices={devices.list}
        handleSpeeddialJoinCall={_handleSpeeddialJoinCall}
        handleSearch={_handleSearch}
        removeMeeting={_removeMeeting}
        //
        //
        // LiveStreaming
        allStreamings={allStreamings}
        removeStreaming={_removeStreaming}
        joinToStreamingButtonClick={_joinToStreamingButtonClick}
        scheduleStreamingOnClick={_scheduleStreamingOnClick}
        handleSearchStreaming={_handleSearchStreaming}
        openUpdateLivestreamingModal={_openUpdateLivestreamingModal}
        openViewLivestreamingModal={_openViewLivestreamingModal}
        //
        //
        // ScheduledEvents
        allScheledEvents={allScheledEvents}
        removeScheduledEvent={_removeScheduledEvent}
        joinScheduledEventOnClick={_joinScheduledEventOnClick}
        createScheduledEventOnClick={_createScheduledEventOnClick}
        handleSearchScheduledEvent={_handleSearchScheduledEvent}
        openUpdateScheduledEventModal={_openUpdateScheduledEventModal}
        openViewScheduledEventModal={_openViewScheduledEventModal}
      />
      {/* <ScheduleMeetingModal />
      <InstantMeetingmodal /> */}
    </>
  );
};

export default memo(DashboardContainer);
