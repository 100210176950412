import React, { memo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      // height: '56px',
      margin: theme.spacing(1.5, 0),
      '& .MuiOutlinedInput-input': {
        padding: '16px 12px !important',
        textTransform: 'capitalize',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
        opacity: 38,
      },
      '&:hover': {
        borderColor: theme.palette.secondary.dark,
        opacity: 38,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.secondary.dark,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: theme.palette.common.white,
        padding: '0 5px',
      },
      '& MuiSvgIcon-root': {
        fill: theme.palette.secondary.contrastText,
      },
    },
    select: {
      '& em': {
        fontStyle: 'unset !important',
      },
    },
    menuItem: {
      textTransform: 'capitalize',
      '& em': {
        fontStyle: 'normal',
      },
    },
  });
};

export interface ISelectTextFieldProps
  extends WithStyles<TextFieldProps & typeof styles> {
  id?: string;
  onChange?: any;
  defaultValue?: string | number;
  value?: string | number;
  label?: string;
  selectItems: Array<any> | any;
}

const SelectTextField: React.FC<ISelectTextFieldProps> = ({
  classes,
  id,
  label,
  defaultValue,
  value,
  onChange,
  selectItems,
}: ISelectTextFieldProps) => {
  return (
    <FormControl variant="outlined" className={classes.root}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={label}
        className={classes.select}
      >
        {selectItems.map((data: any) => {
          return (
            <MenuItem
              key={data.label}
              value={data.value}
              className={classes.menuItem}
            >
              <em>{data.label}</em>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default memo(withStyles(styles)(SelectTextField));
