import React, { memo, useEffect, useState } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface ISuspendCustomerModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  selectedCustomer: any;
  handleClose?: any;
  handleSuspendCustomer: (id: string) => void;
  handleUnsuspendCustomer: (id: string) => void;
  selectCustomer: any;
}

const SuspendCustomerModal = ({
  classes,
  open,
  selectedCustomer,
  handleClose,
  handleSuspendCustomer,
  handleUnsuspendCustomer,
  selectCustomer,
}: ISuspendCustomerModalProps) => {
  const intl = useIntl();
  const [isSuspend, setIsSuspend] = useState(true);
  const [isCustomer, setIsCustomer] = useState(true);
  useEffect(() => {
    handleClose();
  }, [selectCustomer.loading]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.status) {
      if (selectedCustomer.status === 'suspend') {
        setIsSuspend(true);
      } else {
        setIsSuspend(false);
      }
    }
    if (selectedCustomer && selectedCustomer.role) {
      if (selectedCustomer.role === 'customer') {
        setIsCustomer(true);
      } else {
        setIsCustomer(false);
      }
    }
  }, [selectedCustomer]);

  const getTitle = () => {
    if (isSuspend && isCustomer) {
      return intl.formatMessage({
        id: 'unsuspend_this_customer',
      });
    }
    if (isSuspend && !isCustomer) {
      return intl.formatMessage({
        id: 'unsuspend_this_subadmin',
      });
    }
    if (!isSuspend && isCustomer) {
      return intl.formatMessage({
        id: 'suspend_this_customer',
      });
    }
    if (!isSuspend && !isCustomer) {
      return intl.formatMessage({
        id: 'suspend_this_subadmin',
      });
    }
    return 'none';
  };

  return (
    <ActionModal
      modalTitle={getTitle()}
      open={open}
      handleClose={handleClose}
      actinButtonLabel={
        isSuspend
          ? intl.formatMessage({
              id: 'unsuspend',
            })
          : intl.formatMessage({
              id: 'suspend',
            })
      }
      actinButtonOnClick={() =>
        isSuspend
          ? handleUnsuspendCustomer(selectedCustomer?._id)
          : handleSuspendCustomer(selectedCustomer?._id)
      }
    >
      <Grid container className={classes.userCard}>
        <Grid item xs={1}>
          <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>
              {selectedCustomer?.profile?.name ||
                intl.formatMessage({
                  id: 'not_available',
                })}
            </b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedCustomer?.email ||
              intl.formatMessage({
                id: 'not_available',
              })}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(SuspendCustomerModal));
