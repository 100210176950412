/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addGroupAsync,
  deleteAnyGroupAsync,
  deleteAnyGroupsAsync,
  editAnyGroupAsync,
  getAllGroupsAsync,
  selectGroups,
} from './groups.slice';
import { selectAuth } from '../auth/auth.slice';
import GroupsAllView from './allGroups.view';
import { selectMembers } from '../members/members.slice';
import { fetchAllCustomersAsync } from '../customers/customers.slice';
import { selectDevices } from '../devices/devices.slice';
import { IAddGroupData, IEditGroupData } from './groups.api';
import { actions as appActions } from '../app/app.slice';
import { IRequestParams } from '../../components/types';

const AllGroupsContainer: React.FC<unknown> = () => {
  const groups = useAppSelector(selectGroups);
  const members = useAppSelector(selectMembers);
  const devices = useAppSelector(selectDevices);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllGroupsAsync({}));
    dispatch(fetchAllCustomersAsync({ limit: 500 }));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10118'));
  }, []);

  const handleAddGroup = (data: IAddGroupData) => {
    dispatch(addGroupAsync({ ...data, nextApiCallType: 'getAllGroups' }));
  };

  const handleDeleteGroup = (id: string) => {
    dispatch(
      deleteAnyGroupAsync({
        id,
        nextApiCallType: 'getAllGroups',
      })
    );
  };

  const handleDeleteGroups = (ids: string[]) => {
    dispatch(deleteAnyGroupsAsync(ids));
  };

  const handleEditGroup = (data: IEditGroupData) => {
    dispatch(
      editAnyGroupAsync({
        ...data,
        nextApiCallType: 'getAllGroups',
      })
    );
  };

  const handleGetGroups = (data: IRequestParams) => {
    dispatch(getAllGroupsAsync(data));
  };

  return (
    <GroupsAllView
      selectGroup={groups}
      selectMember={members}
      selectDevice={devices}
      groupsList={groups.listAll}
      permissions={auth.permissions}
      handleAddGroup={handleAddGroup}
      handleDeleteGroup={handleDeleteGroup}
      handleDeleteGroups={handleDeleteGroups}
      handleEditGroup={handleEditGroup}
      handleGetGroups={handleGetGroups}
    />
  );
};

export default memo(AllGroupsContainer);
