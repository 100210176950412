import React, { memo } from 'react';
import ScheduleMeetingButtonsView from './scheduleMeetingButtons.view';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { actions } from '../scheduleMeeting.slice';
import { selectAuth } from '../../auth/auth.slice';

const ScheduleMeetingModalContainer: React.FC<unknown> = () => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const _scheduleMeetingBtnClick = () => {
    dispatch(actions.openScheduleModal());
  };
  const _instanteMeetingBtnClick = () => {
    dispatch(actions.openInstantMeetingModal());
  };

  return (
    <ScheduleMeetingButtonsView
      permissions={auth?.permissions}
      scheduleMeetingBtnClick={_scheduleMeetingBtnClick}
      instanteMeetingBtnClick={_instanteMeetingBtnClick}
    />
  );
};

export default memo(ScheduleMeetingModalContainer);
