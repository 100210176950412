import React, { memo } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import RouteWrapper from './RouteWrapper';
import { ForgotPasswordView, LoginView } from '../features/auth';
import { Dashboard } from '../features/dashboard';
import { Meetings } from '../features/meetings';
import { Users } from '../features/users';
import { Members, AllMembers } from '../features/members';
import { SpeedDials } from '../features/speedDials';
import { Customers, AllCustomers } from '../features/customers';
import { MeetingRoom } from '../features/meetingRoom';
import { Settings } from '../features/settings';
import { MeetingReports } from '../features/reports/meetingReport';
import {
  ContactView,
  HelpView,
  PrivacyView,
  TermsView,
} from '../features/subPages';
import { EmailRouter } from '../features/emailRouter';
import { Groups, AllGroups } from '../features/group';
import { AllDevices, Devices } from '../features/devices';
import { Livestreaming } from '../features/livestreaming';
import { ScheduledEvent } from '../features/scheduledEvents';
import { Replaystreaming } from '../features/replaystreaming';
import { ReplaystreamingRoom } from '../features/replaystreamingRoom';

const Routes = () => {
  const routes = [
    {
      path: '/',
      exact: true,
      isPrivate: true,
      component: () => <SpeedDials />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/speed-dials',
      exact: true,
      isPrivate: true,
      component: () => <SpeedDials />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/overview',
      exact: true,
      isPrivate: true,
      component: () => <Dashboard />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/admins',
      exact: true,
      isPrivate: true,
      component: () => <Users />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/members',
      exact: true,
      isPrivate: true,
      component: () => <Members />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/allmembers',
      exact: true,
      isPrivate: true,
      component: () => <AllMembers />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/customers',
      exact: true,
      isPrivate: true,
      component: () => <Customers />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/allcustomers',
      exact: true,
      isPrivate: true,
      component: () => <AllCustomers />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/meetings',
      exact: true,
      isPrivate: true,
      component: () => <Meetings />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/room/:roomName',
      exact: false,
      isPrivate: false,
      component: () => <MeetingRoom />,
      hasNavbar: false,
      hasFooter: false,
    },
    {
      path: '/signin',
      exact: true,
      isPrivate: false,
      component: () => <LoginView />,
    },
    {
      path: '/settings',
      exact: true,
      isPrivate: true,
      component: () => <Settings />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/terms-of-use',
      exact: true,
      isPrivate: true,
      component: () => <TermsView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/privacy-policy',
      exact: true,
      isPrivate: true,
      component: () => <PrivacyView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/help',
      exact: true,
      isPrivate: true,
      component: () => <HelpView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/contact-us',
      exact: true,
      isPrivate: true,
      component: () => <ContactView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/reports/meetings',
      exact: true,
      isPrivate: true,
      component: () => <MeetingReports />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/emailroute',
      exact: true,
      isPrivate: false,
      component: () => <EmailRouter />,
      hasNavbar: false,
      hasFooter: false,
    },
    {
      path: '/groups',
      exact: true,
      isPrivate: true,
      component: () => <Groups />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/allgroups',
      exact: true,
      isPrivate: true,
      component: () => <AllGroups />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/devices',
      exact: true,
      isPrivate: true,
      component: () => <Devices />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/alldevices',
      exact: true,
      isPrivate: true,
      component: () => <AllDevices />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/forgotpassword',
      exact: true,
      isPrivate: false,
      component: () => <ForgotPasswordView />,
    },
    {
      path: '/livestreaming',
      exact: true,
      isPrivate: true,
      component: () => <Livestreaming />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/scheduledevents',
      exact: true,
      isPrivate: true,
      component: () => <ScheduledEvent />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/replaystreaming',
      exact: true,
      isPrivate: true,
      component: () => <Replaystreaming />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/replaystreaming/:roomName',
      exact: false,
      isPrivate: false,
      component: () => <ReplaystreamingRoom />,
      hasNavbar: false,
      hasFooter: false,
    },
  ];
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route) => (
          <RouteWrapper
            key={route.path}
            path={route.path}
            exact={route.exact}
            isPrivate={route.isPrivate}
            Component={route.component}
            hasNavbar={route.hasNavbar}
            hasFooter={route.hasFooter}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default memo(Routes);
