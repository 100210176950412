import React, { Children } from 'react';
import { IconButton, ButtonProps, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Variant } from '../types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    button: {
      borderRadius: 4,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      padding: 8,
      // [theme.breakpoints.down('xs')]: {
      //   padding: 3,
      //   '& .MuiSvgIcon-root': {
      //     fontSize: 20,
      //   },
      // },
    },
  });
};

export interface ButtonComponentProps
  extends WithStyles<ButtonProps & typeof styles> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  id?: string;
  children: React.ReactNode;
  disabled?: boolean;
  customStyles?: any;
  size?: 'small' | 'medium' | undefined;
}

export const IconOnlyButton: React.FC<ButtonComponentProps> = ({
  classes,
  onClick,
  id,
  children,
  disabled = false,
  customStyles,
  size,
  ...otherProps
}: ButtonComponentProps) => {
  return (
    <span className={clsx(classes.root, customStyles && customStyles)}>
      <IconButton
        className={clsx(classes.button, 'custom-style-icon-button')}
        onClick={onClick}
        id={id}
        disabled={disabled}
        size={size}
        {...otherProps}
      >
        {children}
      </IconButton>
    </span>
  );
};

export default withStyles(styles)(IconOnlyButton);
