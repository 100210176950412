/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import SettingsView from './settings.view';
import {
  selectSettings,
  updateSettingsAsync,
  addFAQAsync,
  updateFAQAsync,
  deleteFAQAsync,
  uploadSystemLogoAsync,
  uploadProfilePictureAsync,
} from './settings.slice';
import {
  selectAuth,
  updateProfileAsync,
  updateUserPasswordAsync,
} from '../auth/auth.slice';
import { actions as appActions } from '../app/app.slice';

export interface ISettingsContainer {}

const SettingsContainer: React.FC<ISettingsContainer> = ({}: ISettingsContainer) => {
  const settings = useAppSelector(selectSettings);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appActions.setLocationCode('10110'));
  }, []);

  const _handleUpdateSettings = (data: any) => {
    dispatch(updateSettingsAsync(data));
  };

  const _handleUploadSystemLogo = async (data: any) => {
    const response = await dispatch(uploadSystemLogoAsync(data));
    return unwrapResult(response);
  };

  const _handleUploadProfilePicture = async (data: any) => {
    const response = await dispatch(uploadProfilePictureAsync(data));
    return unwrapResult(response);
  };

  const _handleUpdateUserProfile = (data: any) => {
    dispatch(updateProfileAsync(data));
  };

  const _handleUpdateUserPassword = (data: any) => {
    dispatch(updateUserPasswordAsync(data));
  };

  const _handleAddFAQ = (data: any) => {
    dispatch(addFAQAsync(data));
  };

  const _handleUpdateFAQ = (data: any) => {
    dispatch(updateFAQAsync(data));
  };

  const _handleDeleteFAQ = (id: string) => {
    dispatch(deleteFAQAsync(id));
  };

  return (
    <SettingsView
      auth={auth}
      settings={settings}
      handleUpdateSettings={_handleUpdateSettings}
      handleUploadSystemLogo={_handleUploadSystemLogo}
      handleUploadProfilePicture={_handleUploadProfilePicture}
      handleUpdateUserProfile={_handleUpdateUserProfile}
      handleUpdateUserPassword={_handleUpdateUserPassword}
      handleAddFAQ={_handleAddFAQ}
      handleUpdateFAQ={_handleUpdateFAQ}
      handleDeleteFAQ={_handleDeleteFAQ}
    />
  );
};

export default memo(SettingsContainer);
