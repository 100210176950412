import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  IGetUsersArgs,
  addUserApi,
  deleteUserApi,
  editUserApi,
  fetchAllUsersApi,
  resetUserPasswordApi,
  suspendUserApi,
  unsuspendUserApi,
} from './users.api';
import { actions as appActions } from '../app/app.slice';
import { ApiError, IResponseParams } from '../../components/types';
import { IUser } from '../auth/auth.slice';

export interface IGetUserResponse extends IResponseParams {
  data: IUser[];
}

export interface IUsersState {
  list: IGetUserResponse;
  addUser: any;
  deleteUser: any;
  editUser: any;
  suspendUser: any;
  unsuspendUser: any;
  resetUserPassword: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IUsersState = {
  list: { data: [], total: 0 },
  addUser: null,
  deleteUser: null,
  editUser: null,
  suspendUser: null,
  unsuspendUser: null,
  resetUserPassword: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchAllUsersAsync = createAsyncThunk(
  'users/fetchAll',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetUsersArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchAllUsersApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addUserAsync = createAsyncThunk(
  'users/addUser',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await addUserApi(data);
      dispatch(fetchAllUsersAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_user',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Edit users
export const editUserAsync = createAsyncThunk(
  'members/editUser',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await editUserApi(data);
      dispatch(fetchAllUsersAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_member',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteUsersAsync = createAsyncThunk(
  'users/deleteUser',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteUserApi(data);
      dispatch(fetchAllUsersAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_user',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetUsersPasswordAsync = createAsyncThunk(
  'users/resetUserPassword',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await resetUserPasswordApi(data);
      dispatch(fetchAllUsersAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_reset_password',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const suspendUserAsync = createAsyncThunk(
  'users/suspendUser',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await suspendUserApi(data);
      dispatch(fetchAllUsersAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_suspended_user',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unsuspendUserAsync = createAsyncThunk(
  'users/unsuspendUser',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await unsuspendUserApi(data);
      dispatch(fetchAllUsersAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_unsuspended_user',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch all users
    builder.addCase(fetchAllUsersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
    });
    builder.addCase(fetchAllUsersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new user
    builder.addCase(addUserAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addUser = action.payload;
    });
    builder.addCase(addUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Edit user
    builder.addCase(editUserAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.editUser = action.payload;
    });
    builder.addCase(editUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete user
    builder.addCase(deleteUsersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteUsersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteUser = action.payload;
    });
    builder.addCase(deleteUsersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Suspend user
    builder.addCase(suspendUserAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(suspendUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.suspendUser = action.payload;
    });
    builder.addCase(suspendUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Unsuspend user
    builder.addCase(unsuspendUserAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unsuspendUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.unsuspendUser = action.payload;
    });
    builder.addCase(unsuspendUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset user password
    builder.addCase(resetUsersPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetUsersPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetUserPassword = action.payload;
    });
    builder.addCase(resetUsersPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = usersSlice;

export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
