import React, { memo } from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { TextField } from '../TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '0',
      },
      '& .MuiAutocomplete-tag': {
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        border: '1px solid #DADADA',
        padding: '5px 4px 5px 10px',
        height: 'auto',
        lineHeight: '12px',
        textTransform: 'capitalize',
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.secondary.contrastText,
      },
    },
    multipleLines: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        minHeight: '92px',
        alignItems: 'start',
      },
      '& .MuiAutocomplete-endAdornment': {
        top: '8px',
      },
      '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
        boxSizing: 'border-box',
        height: '100%',
        paddingBottom: '48px !important',
      },
      '& .MuiAutocomplete-tag': {
        marginTop: '16px',
      },
    },
  });
};

export interface IAutocompleteTextField extends WithStyles<typeof styles> {
  id: string;
  customStyles?: any;
  value?: any;
  onChange: (
    event: React.ChangeEvent<any>,
    value: any,
    reason: AutocompleteChangeReason
  ) => void;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  limitTags?: number;
  options: Array<any>;
  getOptionSelected?: (a: any, b: any) => boolean;
  getOptionLabel?: (a: any) => string;
  required?: boolean;
}

const AutocompleteTextField = ({
  classes,
  id,
  onChange,
  value,
  disabled = false,
  multiple = false,
  limitTags,
  options,
  getOptionSelected,
  getOptionLabel,
  label,
  placeholder,
  customStyles,
  error = false,
  helperText,
  required = false,
}: IAutocompleteTextField) => {
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      className={clsx(
        classes.root,
        multiple && classes.multipleLines,
        customStyles
      )}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default memo(withStyles(styles)(AutocompleteTextField));
