import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError } from '../../components/types';
import { RootState } from '../../app/store';
import {
  IScheduledMeetingData,
  connectYoutubeApi,
  disconnectYoutubeApi,
  readLivestreamingsApi,
  getYoutubeStatusApi,
  deleteLivestreamingsApi,
  createLivestreamingApi,
  updateLivestreamingApi,
} from './livestreaming.api';
import { actions as appActions } from '../app/app.slice';
import {
  IScheduleMeetings,
  emptyScheduleMeeting,
} from '../scheduleMeeting/scheduleMeeting.slice';

export interface ILivestreamingState {
  data: IScheduleMeetings;
  list: Array<any>;
  overview: any;
  loading: boolean;
  success: boolean;
  youtubeStatus: boolean;
  error: ApiError;
  isScheduleModalOpen: boolean;
  isUpdateLivestreaming: boolean;
  isCreateLiveStreaming: boolean;
  isViewLiveStreaming: boolean;
}

const initialState: ILivestreamingState = {
  list: [],
  overview: null,
  loading: false,
  success: false,
  youtubeStatus: false,
  error: null,
  data: emptyScheduleMeeting,
  isScheduleModalOpen: false,
  isUpdateLivestreaming: false,
  isCreateLiveStreaming: false,
  isViewLiveStreaming: false,
};

export const createLivestreamingAsync = createAsyncThunk(
  'livestreaming/create',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await createLivestreamingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(readLivestreamingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_livestreaming',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const readLivestreamingsAsync = createAsyncThunk(
  'livestreaming/fetch',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await readLivestreamingsApi(data);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateLivestreamingAsync = createAsyncThunk(
  'livestreaming/update',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateLivestreamingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(readLivestreamingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_livestreaming',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteLivestreamingAsync = createAsyncThunk(
  'livestreaming/delete',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteLivestreamingsApi(data);

      // fetch all the meetings after remove a meeting
      dispatch(readLivestreamingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_livestreaming',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const connectYoutubeAsync = createAsyncThunk(
  'livestreaming/connectYoutube',
  async (data: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await connectYoutubeApi();
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const disconnectYoutubeAsync = createAsyncThunk(
  'livestreaming/disconnectYoutube',
  async (data: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await disconnectYoutubeApi();
      dispatch(getYoutubeStatusAsync());
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getYoutubeStatusAsync = createAsyncThunk(
  'livestreaming/getYoutubeStatus',
  async (data: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await getYoutubeStatusApi();
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const livestreamingSlice = createSlice({
  name: 'livestreaming',
  initialState,
  reducers: {
    openScheduleModal(state) {
      state.isScheduleModalOpen = true;
    },
    closeScheduleModal(state) {
      state.isScheduleModalOpen = false;
      state.isCreateLiveStreaming = false;
      state.isViewLiveStreaming = false;
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    openCreateLivestreamingModal(state) {
      state.isScheduleModalOpen = true;
      state.isCreateLiveStreaming = true;
    },
    openUpdateLivestreamingModal(state, action) {
      state.isUpdateLivestreaming = true;
      state.isScheduleModalOpen = true;
      state.data = action.payload;
    },
    openViewLivestreamingModal(state, action) {
      state.isUpdateLivestreaming = false;
      state.isScheduleModalOpen = true;
      state.isViewLiveStreaming = true;
      state.data = action.payload;
    },
    closeEditMeetingModal(state) {
      state.isScheduleModalOpen = false;
      state.isUpdateLivestreaming = false;
      state.isViewLiveStreaming = false;
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    resetScheduleMeeting(state) {
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // fetch livestreamings
    builder.addCase(readLivestreamingsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(readLivestreamingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data?.meetings;
      state.overview = action?.payload?.data?.overview;
    });
    builder.addCase(readLivestreamingsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // connect youtube
    builder.addCase(connectYoutubeAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(connectYoutubeAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.data) {
        window.location.replace(action?.payload?.data);
      }
    });
    builder.addCase(connectYoutubeAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // get youtube status
    builder.addCase(getYoutubeStatusAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getYoutubeStatusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.youtubeStatus = action?.payload?.success;
    });
    builder.addCase(getYoutubeStatusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // schedule a new livestreaming
    builder.addCase(createLivestreamingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(createLivestreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(createLivestreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // delete a livestreaming
    builder.addCase(deleteLivestreamingAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteLivestreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteLivestreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // edit a livestreaming
    builder.addCase(updateLivestreamingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(updateLivestreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(updateLivestreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
  },
});

export const { actions } = livestreamingSlice;

export const selectLivestreaming = (state: RootState): ILivestreamingState =>
  state.livestreaming;

export default livestreamingSlice.reducer;
