import React, { memo } from 'react';
import { TextField, TextFieldProps, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      margin: theme.spacing(1.5, 0),
      '& .MuiOutlinedInput-input': {
        padding: '16px 12px !important',
        backgroundColor: '#fff',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
        transform: 'translate(14px, 17px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
        opacity: 38,
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
        opacity: 38,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.secondary.main,
      },
      '& .MuiFormHelperText-contained': {
        position: 'absolute',
        bottom: -23,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: '#F64C4C',
        fontSize: theme.typography.overline.fontSize,
      },
      // '& legend': {
      //   width: '0 !important',
      // },
    },
  });
};

export interface ITextFieldProps
  extends WithStyles<TextFieldProps & typeof styles> {
  id?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value?: unknown;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  error?: boolean;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  autoFocus?: boolean;
  customStyles?: string;
  InputLabelProps?: Record<string, unknown> | any;
}

export const TextFieldComponent: React.FC<ITextFieldProps> = ({
  classes,
  id,
  label,
  placeholder = '',
  value,
  onChange,
  onKeyDown,
  onBlur,
  type = 'text',
  helperText = '',
  error = false,
  disabled = false,
  required = false,
  autoFocus = false,
  customStyles,
  InputLabelProps,
  ...otherProps
}: ITextFieldProps) => {
  return (
    <TextField
      {...otherProps}
      id={id}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      className={clsx(classes.root, customStyles)}
      onChange={onChange}
      value={value}
      type={type}
      helperText={error && helperText}
      error={error}
      disabled={disabled}
      required={required}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      autoFocus={autoFocus}
      size="small"
      InputLabelProps={InputLabelProps}
    />
  );
};

export default memo(withStyles(styles)(TextFieldComponent));
