import api from '../../app/axios';

export const userVerifyApi = (data: any) => {
  return api.post(`users/verify`, data);
};

export const passwordResetApi = (data: any) => {
  return api.post(`users/resetpassword`, data);
};

export const usersCreatePasswordApi = (data: any) => {
  return api.post(`users/createpassword`, data);
};
