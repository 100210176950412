/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import ScheduleLiveStreamingModal from './scheduleLiveStreamingModal.view';
import { ShareMeetingModal } from '../../../meetings/components';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import {
  selectLivestreaming,
  actions,
  updateLivestreamingAsync,
  createLivestreamingAsync,
} from '../../livestreaming.slice';
import { getGroupsAsync, selectGroups } from '../../../group/groups.slice';
import {
  fetchMembersAsync,
  selectMembers,
} from '../../../members/members.slice';
import {
  fetchDevicesAsync,
  selectDevices,
} from '../../../devices/devices.slice';
import { IScheduledMeetingData } from '../../livestreaming.api';

const ScheduleLiveStreamingModalContainer: React.FC<unknown> = () => {
  const livestreamings = useAppSelector(selectLivestreaming);
  const groups = useAppSelector(selectGroups);
  const devices = useAppSelector(selectDevices);
  const member = useAppSelector(selectMembers);
  const dispatch = useAppDispatch();

  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);

  const _scheduleMeetinOnClose = useCallback(() => {
    if (livestreamings.isUpdateLivestreaming)
      dispatch(actions.closeEditMeetingModal());
    else dispatch(actions.closeScheduleModal());
  }, [dispatch, livestreamings.isUpdateLivestreaming]);

  useEffect(() => {
    if (
      livestreamings.success &&
      livestreamings.isScheduleModalOpen &&
      !isShareMeetingOpen
    ) {
      _scheduleMeetinOnClose();
      // setIsShareMeetingOpen(true);
    }
  }, [livestreamings.success]);

  useEffect(() => {
    dispatch(getGroupsAsync({ limit: 500 }));
    dispatch(fetchMembersAsync({ limit: 500 }));
    dispatch(fetchDevicesAsync({ limit: 500 }));
  }, []);

  const _saveMeetingBtnClick = (data: IScheduledMeetingData) => {
    if (livestreamings.isUpdateLivestreaming)
      dispatch(updateLivestreamingAsync(data));
    else dispatch(createLivestreamingAsync(data));
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
    dispatch(actions.resetScheduleMeeting());
  };

  return (
    <>
      {livestreamings.isScheduleModalOpen && (
        <ScheduleLiveStreamingModal
          open={livestreamings.isScheduleModalOpen}
          groups={groups.list}
          devicesList={devices.list.data}
          membersList={member.list.data}
          handleClose={_scheduleMeetinOnClose}
          saveMeetingButtonClick={_saveMeetingBtnClick}
          isUpdateLivestreaming={livestreamings.isUpdateLivestreaming}
          isCreateLiveStreaming={livestreamings.isCreateLiveStreaming}
          isViewLiveStreaming={livestreamings.isViewLiveStreaming}
          meeting={livestreamings.data}
          loading={livestreamings.loading}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={livestreamings?.data}
        />
      )}
    </>
  );
};

export default memo(ScheduleLiveStreamingModalContainer);
