/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import MeetingReportView from './meetingReport.view';
import {
  selectMeetingReports,
  fetchMeetingReportsAsync,
  fetchMeetingHostsListAsync,
} from './meetingReport.slice';
import { actions as appActions } from '../../app/app.slice';

const MeetingReportContainer: React.FC<unknown> = () => {
  const reports = useAppSelector(selectMeetingReports);
  const dispatch = useAppDispatch();

  const [meetingReports, setMeetingReports] = useState<Array<any>>([{}]);
  const [fromDate, setFromDate] = useState({
    value: '2020-01-01',
    error: '',
  });
  const [toDate, setToDate] = useState({
    value: moment(new Date()).format('YYYY-MM-DD'),
    error: '',
  });
  const [hostList, setHostList] = useState<Array<any>>([{}]);
  const [selectedHost, setSelectedHost] = useState<string>('all');
  useEffect(() => {
    const toDateNew = new Date(toDate.value);
    dispatch(
      fetchMeetingReportsAsync({
        beginTime: fromDate.value,
        endTime: toDateNew.setDate(toDateNew.getDate() + 1), // adding a day for get today meetings
        host: selectedHost === 'all' ? '' : selectedHost,
      })
    );
  }, [fromDate.value, toDate.value, selectedHost]);

  useEffect(() => {
    dispatch(fetchMeetingHostsListAsync(null));
  }, []);

  useEffect(() => {
    setMeetingReports(reports.meetingReports);
    setHostList(reports.meetingHosts);
  }, [reports]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10115'));
  }, []);

  const _handleFromDateChange = (e: any) => {
    if (new Date() > new Date(e.target.value)) {
      setFromDate({ value: e.target.value, error: '' });
      if (new Date(e.target.value) <= new Date(toDate.value)) {
        setToDate({ value: toDate.value, error: '' });
      } else if (new Date() >= new Date(toDate.value))
        setToDate({ value: toDate.value, error: 'Enter Valid Date Range' });
    } else
      setFromDate({ value: e.target.value, error: 'Enter Valid Date/Time' });
  };

  const _handleToDateChange = (e: any) => {
    if (new Date() >= new Date(e.target.value)) {
      if (new Date(fromDate.value) <= new Date(e.target.value))
        setToDate({ value: e.target.value, error: '' });
      else
        setToDate({ value: e.target.value, error: 'Enter Valid Date Range' });
    } else setToDate({ value: e.target.value, error: 'Enter Valid Date/Time' });
  };

  return <MeetingReportView meetingsReports={meetingReports} />;
};

export default memo(MeetingReportContainer);
