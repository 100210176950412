/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addUserAsync,
  deleteUsersAsync,
  editUserAsync,
  fetchAllUsersAsync,
  resetUsersPasswordAsync,
  selectUsers,
  suspendUserAsync,
  unsuspendUserAsync,
} from './users.slice';
import UsersView from './users.view';
import { actions as appActions } from '../app/app.slice';
import { IGetUsersArgs } from './users.api';

export interface IUsersContainer {}

const UsersContainer: React.FC<IUsersContainer> = ({}: IUsersContainer) => {
  const users = useAppSelector(selectUsers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllUsersAsync({}));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10103'));
  }, []);

  const handleAddUser = (data: any) => {
    const { email, name, password } = data;
    dispatch(addUserAsync({ email, password, name }));
  };

  const handleDeleteUser = (id: string) => {
    dispatch(deleteUsersAsync(id));
  };

  const handleRestUserPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetUsersPasswordAsync(data));
  };

  const handleEditUser = (data: any) => {
    dispatch(editUserAsync(data));
  };

  const handleSuspendUser = (data: any) => {
    dispatch(suspendUserAsync(data));
  };

  const handleUnsuspendUser = (data: any) => {
    dispatch(unsuspendUserAsync(data));
  };

  const handleGetUsers = (data: IGetUsersArgs) => {
    dispatch(fetchAllUsersAsync(data));
  };

  return (
    <UsersView
      selectUser={users}
      usersList={users.list}
      handleAddUser={handleAddUser}
      handleDeleteUser={handleDeleteUser}
      handleRestUserPassword={handleRestUserPassword}
      handleEditUser={handleEditUser}
      handleSuspendUser={handleSuspendUser}
      handleUnsuspendUser={handleUnsuspendUser}
      handleGetUsers={handleGetUsers}
    />
  );
};

export default memo(UsersContainer);
