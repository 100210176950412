import { AxiosResponse } from 'axios';
import api from '../../app/axios';
import { IStreamingUrl } from './replaystreaming.slice';

export interface IScheduledMeetingData {
  id?: string;
  name: string;
  location: string;
  groupId: string | null;
  deviceId: string | null;
  members: string[];
  devices: string[];
  endTime: Date;
  beginTime: Date;
  participantCount: number;
  livestreamingGroupId: string | null;
  livestreamingDevices: string[];
  replayStreamingId: IStreamingUrl | string | null;
}

export const createReplaystreamingApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post('meetings/createreplaystreaming', data);
};

export const readReplaystreamingsApi = (data?: any): Promise<AxiosResponse> => {
  if (data) {
    return api.post('meetings/getreplaystreamings', data);
  }
  return api.post('meetings/getreplaystreamings');
};

export const getLocalLivestreamingsApi = (
  search?: string
): Promise<AxiosResponse> => {
  return api.post('livestreaming/getlocallivestreamings', { search });
};

export const updateReplaystreamingApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post(`meetings/updatereplaystreaming/${data.id}`, data);
};

export const deleteReplaystreamingsApi = (
  id: string
): Promise<AxiosResponse> => {
  return api.delete(`meetings/deletereplaystreaming/${id}`);
};

export const connectYoutubeApi = (): Promise<AxiosResponse> => {
  return api.get('livestreaming/connectyoutube');
};

export const disconnectYoutubeApi = (): Promise<AxiosResponse> => {
  return api.get('livestreaming/disconnectyoutube');
};

export const getYoutubeStatusApi = (): Promise<AxiosResponse> => {
  return api.get('livestreaming/getyoutubestatus');
};
