/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { IGroup, IGroupsState } from '../groups.slice';

const styles = (theme: Theme) => {
  return createStyles({
    groupCard: {},
    right: {
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'row',
    },
  });
};

export interface IDeleteGroupsModalProps extends WithStyles<typeof styles> {
  selectGroup: IGroupsState;
  open: boolean;
  selectedGroups: IGroup[];
  handleClose: () => void;
  handleDeleteGroups: (ids: string[]) => void;
}

const DeleteGroupsModal = ({
  classes,
  open,
  selectedGroups,
  handleClose,
  handleDeleteGroups,
  selectGroup,
}: IDeleteGroupsModalProps) => {
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectGroup.loading]);

  const getDeletedGroupList = (selectedGroupsList: IGroup[]) => {
    const groupsList: string[] = [];
    for (let index = 0; index < selectedGroupsList.length; index += 1) {
      groupsList.push(selectedGroupsList[index].id);
    }
    return groupsList;
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'do_you_want_to_delete_these_groups',
      })}
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'delete',
      })}
      actinButtonOnClick={() =>
        handleDeleteGroups(getDeletedGroupList(selectedGroups))
      }
    >
      <Grid container className={classes.groupCard}>
        {selectedGroups.map((selectedGroup: IGroup) => (
          <Grid item className={classes.right} key={selectedGroup.id} xs={12}>
            <Typography variant="body1" color="primary">
              {selectedGroup?.name ||
                intl.formatMessage({
                  id: 'not_available',
                })}
            </Typography>
            <Typography variant="body1" color="secondary">
              &nbsp; :&nbsp;
            </Typography>
            <Typography variant="body1" color="secondary">
              {selectedGroup?.description ||
                intl.formatMessage({
                  id: 'not_available',
                })}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteGroupsModal));
