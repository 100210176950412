export const isSameDay = (date1: Date, date2: Date): boolean => {
  if (date1 && date2) {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);
    return (
      parsedDate1.getFullYear() === parsedDate2.getFullYear() &&
      parsedDate1.getMonth() === parsedDate2.getMonth() &&
      parsedDate1.getDate() === parsedDate2.getDate()
    );
  }

  return false;
};

export const formatInmmss = (timeInSeconds: number): string => {
  if (timeInSeconds) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return '';
};
