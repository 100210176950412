import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import BasicSwitch, { IBasicSwitchProps } from './BasicSwitch';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      // margin: theme.spacing(),
    },
    label: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  });
};

export interface ILabelSwitchProps
  extends WithStyles<IBasicSwitchProps & typeof styles> {
  id?: string;
  label: string;
  checked?: boolean;
  onChange?: () => void;
}

export const LabelSwitch: React.FC<ILabelSwitchProps> = ({
  classes,
  id,
  checked,
  onChange,
  label,
  ...otherProps
}: ILabelSwitchProps) => {
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      className={classes?.root}
    >
      <Grid item>
        <Typography id={id} variant="h6" className={classes?.label}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <BasicSwitch checked={checked} onChange={onChange} {...otherProps} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LabelSwitch);
