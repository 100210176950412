import { AxiosResponse } from 'axios';
import api from '../../app/axios';
import { IRequestParams } from '../../components/types';

export interface IGetMembersArgs extends IRequestParams {
  id?: string;
}

export const fetchMembersApi = (
  data: IGetMembersArgs
): Promise<AxiosResponse> => {
  const { id, ...filteredData } = data;
  if (id) {
    return api.post(`users/getmembers/${id}`, filteredData);
  }
  return api.post(`users/getmembers`, data);
};

export const fetchAllMembersApi = (
  data: IGetMembersArgs
): Promise<AxiosResponse> => {
  return api.post('users/getallmembers', data);
};

export const addMemberApi = (data: any): Promise<AxiosResponse> => {
  const { email, name, contactNumber } = data;
  if (data?.customerId) {
    return api.post(`users/createmember/${data.customerId}`, {
      email,
      name,
      contactNumber,
    });
  }
  return api.post(`users/createmember`, data);
};

export const deleteMemberApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`users/member/${id}`);
};

export const editMemberApi = (data: string): Promise<AxiosResponse> => {
  return api.post(`users/member/update`, data);
};

export const resetMemberPasswordApi = (data: any): Promise<AxiosResponse> => {
  const { _id, password } = data;
  return api.post(`users/member/${_id}/password`, { password });
};
