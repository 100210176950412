/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import ScheduleMeetingModalView from './scheduleMeetingModal.view';
import { ShareMeetingModal } from '../../meetings/components';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  selectSchesuleMeeting,
  actions,
  scheduleMeetingAsync,
  editMeetingAsync,
} from '../scheduleMeeting.slice';
import { getGroupsAsync, selectGroups } from '../../group/groups.slice';
import { fetchMembersAsync, selectMembers } from '../../members/members.slice';
import { selectAuth } from '../../auth/auth.slice';
import { fetchDevicesAsync, selectDevices } from '../../devices/devices.slice';
import { IScheduledMeetingData } from '../scheduleMeeting.api';

const ScheduleMeetingModalContainer: React.FC<unknown> = () => {
  const scheduleMeeting = useAppSelector(selectSchesuleMeeting);
  const groups = useAppSelector(selectGroups);
  const devices = useAppSelector(selectDevices);
  const member = useAppSelector(selectMembers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);

  const _scheduleMeetinOnClose = useCallback(() => {
    if (scheduleMeeting.isEditMeeting)
      dispatch(actions.closeEditMeetingModal());
    else dispatch(actions.closeScheduleModal());
  }, [dispatch, scheduleMeeting.isEditMeeting]);

  useEffect(() => {
    if (
      scheduleMeeting.success &&
      scheduleMeeting.isScheduleModalOpen &&
      !isShareMeetingOpen
    ) {
      _scheduleMeetinOnClose();
      setIsShareMeetingOpen(true);
    }
  }, [scheduleMeeting.success]);

  useEffect(() => {
    if (
      scheduleMeeting.isScheduleModalOpen &&
      auth &&
      auth.user &&
      auth.user.role === 'member' &&
      auth.user.parentId
    ) {
      dispatch(fetchMembersAsync({ id: auth.user.parentId._id, limit: 500 }));
      dispatch(getGroupsAsync({ id: auth.user.parentId._id, limit: 500 }));
      dispatch(fetchDevicesAsync({ limit: 500 }));
    } else if (scheduleMeeting.isScheduleModalOpen) {
      dispatch(getGroupsAsync({ limit: 500 }));
      dispatch(fetchMembersAsync({ limit: 500 }));
      dispatch(fetchDevicesAsync({ limit: 500 }));
    }
  }, [scheduleMeeting.isScheduleModalOpen, auth]);

  const _saveMeetingBtnClick = (data: IScheduledMeetingData) => {
    if (scheduleMeeting.isEditMeeting) dispatch(editMeetingAsync(data));
    else dispatch(scheduleMeetingAsync(data));
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
    dispatch(actions.resetScheduleMeeting());
  };

  return (
    <>
      {scheduleMeeting.isScheduleModalOpen && (
        <ScheduleMeetingModalView
          open={scheduleMeeting.isScheduleModalOpen}
          groups={groups.list}
          devicesList={devices.list.data}
          membersList={member.list.data}
          handleClose={_scheduleMeetinOnClose}
          saveMeetingButtonClick={_saveMeetingBtnClick}
          isEditMeeting={scheduleMeeting.isEditMeeting}
          isViewMeeting={scheduleMeeting.isViewMeeting}
          meeting={scheduleMeeting.data}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={scheduleMeeting?.data}
        />
      )}
    </>
  );
};

export default memo(ScheduleMeetingModalContainer);
