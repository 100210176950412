/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export const getMeetingTokenApi = (data: any): Promise<AxiosResponse> => {
  return api.post('meetings/gettoken', data);
};

export const getMeetingByLocationApi = (id: string): Promise<AxiosResponse> => {
  return api.get(`meetings/getbylocation/${id}`);
};
