import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Lock as LockIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';
import { CircularProgress, IconButton } from '@material-ui/core';
import styles from './members.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import {
  AddMemberModal,
  ChangePasswordModal,
  DeleteMemberModal,
} from './components';
import EditMemberModal from './components/editMemberModal';
import MemberInfoModal from './components/memberInfoModal';
import { IRequestParams } from '../../components/types';
import { getUserStatus } from '../../helper/translationFunctions';

interface IMembersView extends WithStyles<typeof styles> {
  managedByList: any;
  selectMember: any;
  membersList: any;
  permissions: any;
  handleAddMember: (data: any) => void;
  handleDeleteMember: (id: string) => void;
  handleRestMemberPassword: (data: any) => void;
  handleEditMember: (data: any) => void;
  handleGetMembers: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

const MembersView: React.FC<IMembersView> = ({
  classes,
  managedByList,
  selectMember,
  membersList,
  permissions,
  handleAddMember,
  handleDeleteMember,
  handleRestMemberPassword,
  handleEditMember,
  handleGetMembers,
}: IMembersView) => {
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openEditMemberModal, setOpenEditMemberModal] = useState(false);
  const [openViewMemberModal, setOpenViewMemberModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const intl = useIntl();

  const tableHeadings = [
    {
      name: 'profile.name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: intl.formatMessage({
        id: 'email',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'profile.contactNumber',
      label: intl.formatMessage({
        id: 'contact_number',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'parentId.profile.name',
      label: intl.formatMessage({
        id: 'managed_by',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const MemberOptionList = [
    {
      label: intl.formatMessage({
        id: 'view',
      }),
      id: 'member-view',
      onClick: () => setOpenViewMemberModal(true),
      icon: <VisibilityOutlinedIcon fontSize="small" />,
    },
  ];

  if (permissions.editMembers) {
    MemberOptionList.push(
      {
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'member-edit',
        onClick: () => setOpenEditMemberModal(true),
        icon: <EditIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'delete',
        }),
        id: 'member-delete',
        onClick: () => setOpenDeleteMemberModal(true),
        icon: <DeleteOutlinedIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'change_password',
        }),
        id: 'member-change_password',
        onClick: () => setOpenResetPasswordModal(true),
        icon: <LockIcon fontSize="small" />,
      }
    );
  }

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedMember(data);
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const getManagedByPrefix = (role: string) => {
    if (role === 'subadmin') return 'ABU: ';
    if (role === 'customer') return 'AU: ';
    return '';
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    // setAnchorEl(null);
    setOpenMoreOption(false);
  };
  const renderTableRow = useCallback(() => {
    return map(membersList.data, (row) => {
      return {
        ...row,
        'profile.name': row.profile.name,
        'profile.contactNumber': row.profile.contactNumber,
        'parentId.profile.name': `${getManagedByPrefix(row.parentId.role)}${
          row.parentId.profile.name
        }`,
        status: getUserStatus(intl, row.status, true),
        more: (
          <IconButton
            id="more-option-in-members"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
            key={row.id}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" key={row.id} />
          </IconButton>
        ),
      };
    });
  }, [membersList]);

  const renderAddMemberButton = () => {
    return (
      <div>
        <Button
          customStyles={classes.button}
          onClick={() => setOpenAddMemberModal(true)}
          label={intl.formatMessage({
            id: 'add_member',
          })}
          id="add-member-button"
          startIcon={<PersonAddIcon />}
          variant="contained"
          color="primary"
        />
      </div>
    );
  };
  if (!membersList) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first member">
        {permissions.addMembers ? renderAddMemberButton() : null}
        <AddMemberModal
          selectMember={selectMember}
          open={openAddMemberModal}
          handleClose={() => setOpenAddMemberModal(false)}
          handleAddMember={handleAddMember}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="members-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          <FormattedMessage id="members" />
        </Typography>
        <div className={classes.buttonWrapper}>
          {permissions.addMembers ? renderAddMemberButton() : null}
        </div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="members-list-table"
            title={
              <div id="total-members">
                <FormattedMessage
                  id="total_members"
                  values={{ n: membersList.total }}
                />
                {selectMember.loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination
            serverSide
            filter
            count={membersList.total}
            dataRefreshFunc={handleGetMembers}
          />
        </Card>
      </div>
      <AddMemberModal
        selectMember={selectMember}
        open={openAddMemberModal}
        handleClose={() => setOpenAddMemberModal(false)}
        handleAddMember={handleAddMember}
      />
      <DeleteMemberModal
        selectMember={selectMember}
        open={openDeleteMemberModal}
        handleClose={() => setOpenDeleteMemberModal(false)}
        selectedMember={selectedMember}
        handleDeleteMember={handleDeleteMember}
      />
      <ChangePasswordModal
        selectMember={selectMember}
        open={openResetPasswordModal}
        handleClose={() => setOpenResetPasswordModal(false)}
        selectedMember={selectedMember}
        handleRestMemberPassword={handleRestMemberPassword}
      />
      <EditMemberModal
        selectMember={selectMember}
        managedByList={managedByList}
        open={openEditMemberModal}
        handleClose={() => setOpenEditMemberModal(false)}
        selectedMember={selectedMember}
        handleEditMember={handleEditMember}
      />
      <MemberInfoModal
        open={openViewMemberModal}
        handleClose={() => setOpenViewMemberModal(false)}
        selectedMember={selectedMember}
        showParent
      />
      <Dropdown
        id="member-more-option-button"
        open={Boolean(selectedMember) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={MemberOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(MembersView));
