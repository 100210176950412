import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import {
  validateContactNumber,
  validateEmail,
} from '../../../helper/validation';
import { SelectTextField, TextField } from '../../../components/TextField';
import { selectAuth } from '../../auth/auth.slice';
import { useAppSelector } from '../../../app/hooks';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddCustomerModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleAddCustomer: (data: any) => void;
  selectCustomer: any;
}

const AddCustomerModal = ({
  classes,
  open,
  handleClose,
  handleAddCustomer,
  selectCustomer,
}: IAddCustomerModalProps) => {
  const auth = useAppSelector(selectAuth);
  const [name, setName] = useState('');
  const [email, setEmail] = useState({ error: false, value: '' });
  const [contactNumber, setContactNumber] = useState({
    error: false,
    value: '',
  });
  const [role, setRole] = useState<any>({
    value: 'customer',
    error: false,
  });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectCustomer.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    if (!contactNumber.value || contactNumber.error) {
      isValidate = false;
      setContactNumber({ ...contactNumber, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const customerData = {
        name,
        email: email.value,
        contactNumber: contactNumber.value,
        ...(auth.user?.role !== 'subadmin' && { role: role.value }),
      };
      handleAddCustomer(customerData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const contactNumberOnChange = (e: { target: { value: string } }) => {
    const error = validateContactNumber(e.target.value);
    setContactNumber({ value: e.target.value, error });
  };

  const _roleOnChange = (e: any) => {
    setRole({ value: e.target.value, error: false });
  };

  const _userRolesArray = [
    {
      label: intl.formatMessage({
        id: 'customer',
      }),
      value: 'customer',
    },
    {
      label: intl.formatMessage({
        id: 'subadmin',
      }),
      value: 'subadmin',
    },
  ];

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'add_customer',
      })}
      modalDescription={intl.formatMessage({
        id: 'make_customers_to_manage_meetings',
      })}
      id="add-customer-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'add_customer',
      })}
    >
      <TextField
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
      />
      <TextField
        id="add-customer-email"
        error={email.error}
        placeholder={intl.formatMessage({
          id: 'email',
        })}
        required
        onChange={emailOnChange}
        helperText={
          email.error &&
          intl.formatMessage({
            id: 'please_insert_valid_email',
          })
        }
      />
      <TextField
        id="add-customer-contact-number"
        error={contactNumber.error}
        placeholder={intl.formatMessage({
          id: 'contact_number',
        })}
        required
        type="tel"
        onChange={contactNumberOnChange}
        helperText={
          contactNumber.error &&
          intl.formatMessage({
            id: 'please_insert_valid_contact_number',
          })
        }
      />
      {auth.user?.role !== 'subadmin' ? (
        <SelectTextField
          selectItems={_userRolesArray}
          value={role.value}
          label={intl.formatMessage({
            id: 'role',
          })}
          onChange={_roleOnChange}
        />
      ) : (
        ''
      )}
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddCustomerModal));
