import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import {
  TextField,
  PasswordTextField,
  SelectTextField,
} from '../../../components/TextField';
import { Card } from '../../../components/Card';
// import styles from '../settings.styles';
import { validatePassword } from '../../../helper/validation';
import { ImageUploadInput } from '../../../components/ImageUploadInput';
import { getLanguageArr } from '../../../helper/translation';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
    },
    rightCard: {
      marginLeft: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    description: {
      margin: theme.spacing(1, 0),
    },
    textField: {
      '& .MuiOutlinedInput-input': {
        textTransform: 'capitalize',
      },
    },
  });
};

interface IProfileSettingsView extends WithStyles<typeof styles> {
  user: any;
  settings: any;
  handleUpdateUserProfile: (data: any) => void;
  handleUpdateUserPassword: (data: any) => void;
  handleUploadFile: (data: any) => any;
}

export const getRole = (role: string) => {
  if (role === 'member') {
    return 'member';
  }
  if (role === 'customer') {
    return 'customer';
  }
  if (role === 'user') {
    return 'user';
  }
  if (role === 'subadmin') {
    return 'subadmin';
  }
  if (role === 'admin') {
    return 'admin2';
  }
  if (role === 'super-admin') {
    return 'super_admin';
  }
  return 'none';
};

const ProfileSettingsView: React.FC<IProfileSettingsView> = ({
  classes,
  user,
  settings,
  handleUpdateUserProfile,
  handleUpdateUserPassword,
  handleUploadFile,
}: IProfileSettingsView) => {
  const [name, setName] = useState<any>({ value: '', error: false });
  const [currentPassword, setCurrentPassword] = useState<any>({
    value: '',
    error: false,
  });
  const [newPassword, setNewPassword] = useState<any>({
    value: '',
    error: false,
  });
  const [confirmPassword, setConfirmPassword] = useState<any>({
    value: '',
    error: false,
  });
  const [locale, setLocale] = useState<any>({
    value: '',
    error: false,
  });
  const [profileImage, setProfileImage] = useState<any>({
    value: null,
    error: false,
  });

  const _languagesArray = getLanguageArr();
  const intl = useIntl();

  useEffect(() => {
    if (user) {
      setName({ value: user?.profile?.name, error: false });
      setProfileImage({ value: user?.profile?.picture, error: false });
      setLocale({ value: user?.profile?.locale, error: false });
    }
  }, []);

  useEffect(() => {
    if (user) {
      setName({ value: user?.profile?.name, error: false });
      setProfileImage({ value: user?.profile?.picture, error: false });
      setLocale({ value: user?.profile?.locale, error: false });
    }
  }, [user]);

  const _nameOnChange = (e: any) => {
    setName({ value: e.target.value, error: false });
  };

  const _localeOnChange = (e: any) => {
    setLocale({ value: e.target.value, error: false });
  };

  const _updateProfileButtonOnClick = () => {
    const data = {
      name: name.value,
      picture:
        profileImage.value?.location ||
        profileImage.value?.path ||
        profileImage.value,
      locale: locale.value,
    };
    handleUpdateUserProfile(data);
  };

  const _currentPasswordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setCurrentPassword({ value: e.target.value, error });
  };

  const _newPasswordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setNewPassword({ value: e.target.value, error });
  };

  const _confirmPasswordOnChange = (e: { target: { value: string } }) => {
    const error = e.target.value !== newPassword.value;
    setConfirmPassword({ value: e.target.value, error });
  };

  const _validateChangePassword = () => {
    let isValidate = true;

    if (
      currentPassword.error ||
      newPassword.error ||
      confirmPassword.error ||
      profileImage.error
    )
      isValidate = false;

    if (!currentPassword.value) {
      isValidate = false;
      setCurrentPassword({ ...currentPassword, error: true });
    }

    if (!newPassword.value) {
      isValidate = false;
      setNewPassword({ ...newPassword, error: true });
    }

    if (confirmPassword.value !== newPassword.value) {
      isValidate = false;
      setConfirmPassword({ ...confirmPassword, error: true });
    }

    return isValidate;
  };

  const _updatePasswordeButtonOnClick = () => {
    if (_validateChangePassword()) {
      const data = {
        oldPassword: currentPassword.value,
        password: newPassword.value,
      };
      handleUpdateUserPassword(data);
    }
  };

  const _fileOnChange = async (e: any, type: string) => {
    const file = new FormData();
    file.append('inputFile', e.target?.files[0]);
    const data = {
      file,
      type,
    };

    const response = await handleUploadFile(data);
    if (response?.success)
      setProfileImage({ value: response?.data[0], error: '' });
    else
      setProfileImage({
        value: null,
        error: intl.formatMessage({
          id: 'file_not_uploaded',
        }),
      });
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        <Card>
          <Typography component="h3" variant="h6">
            <FormattedMessage id="update_user_profile" />
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            <FormattedMessage id="required_input_fields" />
          </Typography>
          <TextField
            label={intl.formatMessage({
              id: 'name',
            })}
            placeholder={intl.formatMessage({
              id: 'name',
            })}
            value={name.value}
            onChange={_nameOnChange}
          />
          <TextField
            label={intl.formatMessage({
              id: 'email',
            })}
            value={user?.email}
            disabled
          />
          <TextField
            customStyles={classes.textField}
            label={intl.formatMessage({
              id: 'role',
            })}
            value={intl.formatMessage({
              id: getRole(user?.role),
            })}
            disabled
          />
          <SelectTextField
            selectItems={_languagesArray}
            value={locale.value}
            label={intl.formatMessage({
              id: 'locale',
            })}
            onChange={_localeOnChange}
          />
          <ImageUploadInput
            id="profile-image-upload-input"
            inputTitle={intl.formatMessage({
              id: 'profile_picture',
            })}
            name={intl.formatMessage({
              id: 'profile_picture',
            })}
            value={
              profileImage.value?.location ||
              profileImage.value?.path ||
              profileImage.value
            }
            onChange={(e) => _fileOnChange(e, 'profileImage')}
          />
          <Button
            customStyles={classes.button}
            id="update-profile-save-button"
            variant="contained"
            label={intl.formatMessage({
              id: 'save',
            })}
            onClick={_updateProfileButtonOnClick}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card customStyles={classes.rightCard}>
          <Typography component="h3" variant="h6">
            <FormattedMessage id="change_password" />
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            <FormattedMessage id="required_input_fields" />
          </Typography>
          <PasswordTextField
            id="current-password"
            label={intl.formatMessage({
              id: 'current_password',
            })}
            onChange={_currentPasswordOnChange}
            error={currentPassword.error}
            helperText={
              currentPassword.error &&
              intl.formatMessage({
                id: 'please_insert_valid_password',
              })
            }
          />
          <PasswordTextField
            id="new-password"
            label={intl.formatMessage({
              id: 'new_password',
            })}
            onChange={_newPasswordOnChange}
            error={newPassword.error}
            helperText={
              newPassword.error &&
              intl.formatMessage({
                id: 'please_insert_valid_password',
              })
            }
          />
          <PasswordTextField
            id="confirm-password"
            label={intl.formatMessage({
              id: 'confirm_password',
            })}
            onChange={_confirmPasswordOnChange}
            error={confirmPassword.error}
            helperText={
              confirmPassword.error &&
              intl.formatMessage({
                id: 'password_mismatch',
              })
            }
          />
          <Button
            customStyles={classes.button}
            id="update-profile-save-button"
            variant="contained"
            label={intl.formatMessage({
              id: 'update_password',
            })}
            onClick={_updatePasswordeButtonOnClick}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(ProfileSettingsView));
