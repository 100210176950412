/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addMemberAsync,
  deleteMembersAsync,
  editMembersAsync,
  fetchMembersAsync,
  resetMembersPasswordAsync,
  selectMembers,
} from './members.slice';
import { emptyUser, selectAuth } from '../auth/auth.slice';
import MembersView from './members.view';
import {
  fetchCustomersAsync,
  selectCustomers,
} from '../customers/customers.slice';
import { actions as appActions } from '../app/app.slice';
import { IGetMembersArgs } from './members.api';

export interface IMembersContainer {}

const MembersContainer: React.FC<IMembersContainer> = ({}: IMembersContainer) => {
  const customers = useAppSelector(selectCustomers);
  const members = useAppSelector(selectMembers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCustomersAsync({}));
  }, []);

  useEffect(() => {
    if (auth && auth.user && auth.user.id) {
      if (auth.user.role === 'member' && auth.user.parentId) {
        dispatch(fetchMembersAsync({ id: auth.user.parentId._id }));
      } else {
        dispatch(fetchMembersAsync({}));
      }
    }
  }, [auth]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10104'));
  }, []);

  const handleAddMember = (data: any) => {
    const { email, name, contactNumber } = data;
    dispatch(addMemberAsync({ email, name, contactNumber }));
  };

  const handleDeleteMember = (id: string) => {
    dispatch(deleteMembersAsync({ id }));
  };

  const handleRestMemberPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetMembersPasswordAsync(data));
  };

  const handleEditMember = (data: any) => {
    dispatch(editMembersAsync(data));
  };

  const handleGetMembers = (data: IGetMembersArgs) => {
    if (
      auth &&
      auth.user &&
      auth.user.role === 'member' &&
      auth.user.parentId
    ) {
      dispatch(fetchMembersAsync({ ...data, id: auth.user.parentId._id }));
    } else {
      dispatch(fetchMembersAsync(data));
    }
  };

  return (
    <MembersView
      selectMember={members}
      membersList={members.list}
      managedByList={
        customers.list.data && Array.isArray(customers.list.data)
          ? customers.list.data.concat({
              ...emptyUser,
              _id: auth?.user?.id,
              profile: auth?.user?.profile,
              role: auth?.user?.role,
            })
          : customers.list.data
      }
      permissions={auth.permissions}
      handleAddMember={handleAddMember}
      handleDeleteMember={handleDeleteMember}
      handleRestMemberPassword={handleRestMemberPassword}
      handleEditMember={handleEditMember}
      handleGetMembers={handleGetMembers}
    />
  );
};

export default memo(MembersContainer);
