/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';
import { CircularProgress, IconButton } from '@material-ui/core';
import styles from './groups.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import {
  AddGroupModal,
  DeleteGroupModal,
  DeleteGroupsModal,
  EditGroupModal,
} from './components';
import GroupInfoModal from './components/groupInfoModal';
import { IDevicesState } from '../devices/devices.slice';
import { IMembersState } from '../members/members.slice';
import {
  emptyGroup,
  IGroup,
  IGroupsState,
  IGetGroupResponse,
} from './groups.slice';
import { IRequestParams } from '../../components/types';
import { IAddGroupData, IEditGroupData } from './groups.api';
import { IPermissions } from '../../app/permission';

interface IGroupsView extends WithStyles<typeof styles> {
  selectGroup: IGroupsState;
  selectMember: IMembersState;
  selectDevice: IDevicesState;
  groupsList: IGetGroupResponse;
  permissions: IPermissions;
  handleAddGroup: (data: IAddGroupData) => void;
  handleDeleteGroup: (id: string) => void;
  handleDeleteGroups: (ids: string[]) => void;
  handleEditGroup: (data: IEditGroupData) => void;
  handleGetGroups: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

const GroupsView: React.FC<IGroupsView> = ({
  classes,
  selectGroup,
  selectMember,
  selectDevice,
  groupsList,
  permissions,
  handleAddGroup,
  handleDeleteGroup,
  handleDeleteGroups,
  handleEditGroup,
  handleGetGroups,
}: IGroupsView) => {
  const intl = useIntl();
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
  const [openDeleteGroupsModal, setOpenDeleteGroupsModal] = useState(false);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [openGroupInfoModal, setOpenGroupInfoModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroup, setSelectedGroup] = useState<IGroup>({
    ...emptyGroup,
  });
  const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);

  const tableHeadings = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: intl.formatMessage({
        id: 'description',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'membersCount',
      label: intl.formatMessage({
        id: 'member_count',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'devicesCount',
      label: intl.formatMessage({
        id: 'devices_count',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const optionList = [
    {
      label: intl.formatMessage({
        id: 'view',
      }),
      id: 'group-view',
      onClick: () => setOpenGroupInfoModal(true),
      icon: <VisibilityOutlinedIcon fontSize="small" />,
    },
    {
      label: intl.formatMessage({
        id: 'edit',
      }),
      id: 'group-edit',
      onClick: () => setOpenEditGroupModal(true),
      icon: <EditIcon fontSize="small" />,
    },
    {
      label: intl.formatMessage({
        id: 'delete',
      }),
      id: 'group-delete',
      onClick: () => setOpenDeleteGroupModal(true),
      icon: <DeleteOutlinedIcon fontSize="small" />,
    },
  ];

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    // setAnchorEl(null);
    setOpenMoreOption(false);
  };

  const renderTableRow = useCallback(() => {
    const handleOpenMoreOption = (
      event: React.MouseEvent<HTMLElement>,
      data: IGroup
    ) => {
      setSelectedGroup(data);
      setOpenMoreOption(!openMoreOption);
      setAnchorEl(event.currentTarget);
    };

    return map(groupsList.data, (row) => {
      return {
        ...row,
        more: (
          <IconButton
            id="more-option-in-groups"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [groupsList.data]);

  const renderAddGroupButton = () => {
    return (
      <div>
        <Button
          customStyles={classes.button}
          onClick={() => setOpenAddGroupModal(true)}
          label={intl.formatMessage({
            id: 'add_group',
          })}
          id="add-group-button"
          startIcon={<PersonAddIcon />}
          variant="contained"
          color="primary"
        />
      </div>
    );
  };
  if (!groupsList.data) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first group">
        {renderAddGroupButton()}
        <AddGroupModal
          selectMember={selectMember}
          selectGroup={selectGroup}
          selectDevice={selectDevice}
          open={openAddGroupModal}
          handleClose={() => setOpenAddGroupModal(false)}
          handleAddGroup={handleAddGroup}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="groups-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          <FormattedMessage id="groups" />
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddGroupButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="groups-list-table"
            title={
              <div id="total-groups">
                <FormattedMessage
                  id="total_groups"
                  values={{ n: groupsList.total }}
                />
                {selectGroup.loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination
            serverSide
            filter
            count={groupsList.total}
            dataRefreshFunc={handleGetGroups}
            selectableRows
            selectableRowsFunc={setSelectedGroups}
            openDeleteModalFunc={setOpenDeleteGroupsModal}
          />
        </Card>
      </div>
      <AddGroupModal
        selectGroup={selectGroup}
        selectMember={selectMember}
        selectDevice={selectDevice}
        open={openAddGroupModal}
        handleClose={() => setOpenAddGroupModal(false)}
        handleAddGroup={handleAddGroup}
      />
      <DeleteGroupModal
        selectGroup={selectGroup}
        open={openDeleteGroupModal}
        handleClose={() => setOpenDeleteGroupModal(false)}
        selectedGroup={selectedGroup}
        handleDeleteGroup={handleDeleteGroup}
      />
      <DeleteGroupsModal
        selectGroup={selectGroup}
        open={openDeleteGroupsModal}
        handleClose={() => setOpenDeleteGroupsModal(false)}
        selectedGroups={selectedGroups}
        handleDeleteGroups={handleDeleteGroups}
      />
      <EditGroupModal
        selectGroup={selectGroup}
        selectMember={selectMember}
        selectDevice={selectDevice}
        open={openEditGroupModal}
        handleClose={() => setOpenEditGroupModal(false)}
        selectedGroup={selectedGroup}
        handleEditGroup={handleEditGroup}
        allGroups={false}
      />
      <GroupInfoModal
        selectedGroup={selectedGroup}
        selectDevice={selectDevice}
        open={openGroupInfoModal}
        showParent={false}
        handleClose={() => setOpenGroupInfoModal(false)}
      />
      <Dropdown
        id="group-more-option-button"
        open={Boolean(selectedGroup) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={optionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(GroupsView));
