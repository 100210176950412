import React, { memo, useState, useEffect } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField, PasswordTextField } from '../../../components/TextField';
import { validatePassword } from '../../../helper/validation';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IChangePasswordModalProps extends WithStyles<typeof styles> {
  open: boolean;
  handleClose: () => void;
  handleRestCustomerPassword: (data: any) => void;
  selectCustomer: any;
  selectedCustomer: any;
}

const ChangePasswordModal = ({
  classes,
  open,
  handleClose,
  handleRestCustomerPassword,
  selectCustomer,
  selectedCustomer,
}: IChangePasswordModalProps) => {
  const [password, setPassword] = useState({ error: false, value: '' });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectCustomer.loading]);

  const passwordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setPassword({ value: e.target.value, error });
  };

  const _validate = () => {
    let isValidate = true;

    if (!password.value || password.error) {
      isValidate = false;
      setPassword({ ...password, error: true });
    }

    return isValidate;
  };
  const onClickActionButton = () => {
    if (_validate()) {
      const customerData = {
        _id: selectedCustomer?._id,
        password: password.value,
      };
      handleRestCustomerPassword(customerData);
    }
  };
  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'change_customer_password',
      })}
      selectedItem={selectedCustomer?.profile?.name}
      modalDescription={intl.formatMessage({
        id: 'change_password_customer_description',
      })}
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'copy_password_and_save',
      })}
      actinButtonOnClick={onClickActionButton}
    >
      <TextField
        id="change-password-email"
        value={selectedCustomer?.email}
        disabled
      />
      <PasswordTextField
        id="change-password-input"
        placeholder={intl.formatMessage({
          id: 'new_password',
        })}
        onChange={passwordOnChange}
        required
        error={password.error}
        helperText={
          password.error &&
          intl.formatMessage({
            id: 'please_insert_valid_password',
          })
        }
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(ChangePasswordModal));
