/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addMemberAsync,
  deleteMembersAsync,
  editMembersAsync,
  fetchAllMembersAsync,
  resetMembersPasswordAsync,
  selectMembers,
} from './members.slice';
import { selectAuth } from '../auth/auth.slice';
import MembersView from './allmembers.view';
import {
  fetchAllCustomersAsync,
  selectCustomers,
} from '../customers/customers.slice';
import { actions as appActions } from '../app/app.slice';
import { IGetMembersArgs } from './members.api';

export interface IMembersContainer {}

const MembersContainer: React.FC<IMembersContainer> = ({}: IMembersContainer) => {
  const members = useAppSelector(selectMembers);
  const customers = useAppSelector(selectCustomers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllMembersAsync({}));
  }, []);

  useEffect(() => {
    dispatch(fetchAllCustomersAsync({ limit: 500 }));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10105'));
  }, []);

  const handleAddMember = (data: any) => {
    dispatch(addMemberAsync(data));
  };

  const handleDeleteMember = (id: string) => {
    dispatch(deleteMembersAsync({ id }));
  };

  const handleRestMemberPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetMembersPasswordAsync(data));
  };

  const handleEditMember = (data: any) => {
    dispatch(editMembersAsync(data));
  };

  const handleGetMembers = (data: IGetMembersArgs) => {
    dispatch(fetchAllMembersAsync(data));
  };

  return (
    <MembersView
      selectMember={members}
      membersList={members.list}
      managedByList={customers.list.data}
      permissions={auth.permissions}
      handleAddMember={handleAddMember}
      handleDeleteMember={handleDeleteMember}
      handleRestMemberPassword={handleRestMemberPassword}
      handleEditMember={handleEditMember}
      handleGetMembers={handleGetMembers}
    />
  );
};

export default memo(MembersContainer);
