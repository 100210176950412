import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export interface IScheduledMeetingData {
  id?: string;
  name: string;
  location: string;
  groupId: string | null;
  deviceId: string | null;
  members: string[];
  devices: string[];
  endTime: Date;
  beginTime: Date;
  participantCount: number;
}

export interface ICreateInstant {
  name: string;
  location: string;
  deviceId: string;
  beginTime: Date;
}
export const scheduleMeetingApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post('meetings/createscheduled', data);
};

export const instantMeetingApi = (
  data: ICreateInstant
): Promise<AxiosResponse> => {
  return api.post('meetings/createinstant', data);
};

export const editMeetingApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post(`meetings/${data.id}`, data);
};
