import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import {
  validateContactNumber,
  validateEmail,
} from '../../../helper/validation';
import { TextField } from '../../../components/TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IDeleteAndRevokeModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  onClickActionButton?: any;
  typeWord?: string;
  modalTitle?: string;
  modalDescription?: string;
  actinButtonLabel?: string;
}

const DeleteAndRevokeModal = ({
  classes,
  open,
  handleClose,
  onClickActionButton,
  typeWord,
  modalTitle,
  modalDescription,
  actinButtonLabel,
}: IDeleteAndRevokeModalProps) => {
  const [confirmationWord, setConfirmationWord] = useState('');
  const [isActionConfirm, setIsActionConfirm] = useState(false);
  const intl = useIntl();

  const confirmationWordOnChange = (e: { target: { value: string } }) => {
    setConfirmationWord(e.target.value);
  };

  useEffect(() => {
    if (confirmationWord === typeWord) {
      setIsActionConfirm(true);
      return;
    }
    setIsActionConfirm(false);
  }, [confirmationWord]);

  return (
    <ActionModal
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      id="add-customer-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: actinButtonLabel,
      })}
      actionButtonDisabled={!isActionConfirm}
    >
      <TextField
        placeholder={intl.formatMessage({
          id: 'type_the_word',
        })}
        onChange={confirmationWordOnChange}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteAndRevokeModal));
