import React from 'react';
import { Theme, Tooltip, Divider, Typography } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(2, 0),
      '& .MuiSvgIcon-root': {
        height: '0.75em',
        color: theme.palette.secondary.main,
      },
    },
    content: {
      display: 'flex',
    },
    label: {
      margin: theme.spacing(1, 0),
      width: 300,
      color: theme.palette.secondary.dark,
      // fontWeight: 600,
    },
    icon: {
      margin: theme.spacing(1),
      width: '200px',
    },
    control: {},
  });
};

export interface ISettingsItemProps extends WithStyles<typeof styles> {
  id: string;
  label: string;
  helperText?: string;
  control?: any;
}

export const SettingsItem: React.FC<ISettingsItemProps> = ({
  classes,
  id,
  label,
  helperText = '',
  control,
}: ISettingsItemProps) => {
  return (
    <div id={id} className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.label} variant="body2">
          {label}
        </Typography>
        <div className={classes.icon}>
          <Tooltip title={helperText} placement="top">
            <HelpOutline fontSize="small" />
          </Tooltip>
        </div>
        <div className={classes.control}>{control}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SettingsItem);
