/* eslint-disable react/no-danger */
import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectSettings } from '../settings/settings.slice';
import { actions as appActions } from '../app/app.slice';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      flexGrow: 1,
      overflow: 'auto',
      marginTop: theme.spacing(2),
    },
  });
};

type ITermsView = WithStyles<typeof styles>;

const TermsView: React.FC<ITermsView> = ({ classes }) => {
  const settings = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const [terms, setTerms] = useState<string>('');
  useEffect(() => {
    let data;
    if (settings?.data?.terms) {
      data = stateToHTML(convertFromRaw(JSON.parse(settings?.data?.terms)));
      setTerms(data);
    }
  }, [settings?.data?.terms]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10111'));
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h2" color="textPrimary">
        <FormattedMessage id="terms" />
      </Typography>
      <Card customStyles={classes.card}>
        <div dangerouslySetInnerHTML={{ __html: terms }} />
      </Card>
    </div>
  );
};

export default memo(withStyles(styles)(TermsView));
