/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../../components/Modal';
import { TextField } from '../../../../components/TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    bottomGrid: {
      marginBottom: 0,
    },
    modal: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: theme.palette.secondary.main,
      },
    },
    textCapitalize: {
      '& .MuiOutlinedInput-input': {
        textTransform: 'capitalize',
      },
    },
  });
};

export interface IScheduleMeetingModalProps extends WithStyles<typeof styles> {
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  meeting: any;
}

const ViewReportModal = ({
  classes,
  open,
  meeting,
  handleClose,
}: IScheduleMeetingModalProps) => {
  const intl = useIntl();

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'view_meeting',
      })}
      modalDescription={intl.formatMessage({
        id: 'view_meeting_description',
      })}
      id="view-meeting-report-modal"
      open={open}
      customStyles={classes.modal}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'ok',
      })}
    >
      <TextField
        label={intl.formatMessage({
          id: 'meeting_name',
        })}
        value={meeting[0]}
        disabled
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: 'started_time',
            })}
            value={meeting[1]}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: 'duration',
            })}
            value={meeting[3]}
            disabled
          />
        </Grid>
      </Grid>

      <TextField
        label={intl.formatMessage({
          id: 'host',
        })}
        customStyles={classes.textCapitalize}
        value={meeting[2]}
        disabled
      />
      <Grid container spacing={2} className={classes.bottomGrid}>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: 'participants',
            })}
            value={meeting[4]}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: 'status',
            })}
            customStyles={classes.textCapitalize}
            value={meeting[5]}
            disabled
          />
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(ViewReportModal));
