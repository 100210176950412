import axios from 'axios';
import { getToken } from '../helper/localStorage';
import { apiBaseUrl } from './config';

const token = getToken();

const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const currentLocation = window.location.href.split('/').pop();
      if (currentLocation !== 'signin') {
        window.location.href = '/signin';
      }
    }
    return Promise.reject(error);
  }
);

export const setHeader = (accessToken: string): void => {
  api.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    return config;
  });
};

export default api;
