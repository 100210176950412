import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import {
  validateContactNumber,
  validateEmail,
} from '../../../helper/validation';
import { TextField } from '../../../components/TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddMemberModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleAddMember: (data: any) => void;
  selectMember: any;
}

const AddMemberModal = ({
  classes,
  open,
  handleClose,
  handleAddMember,
  selectMember,
}: IAddMemberModalProps) => {
  const [name, setName] = useState({ error: false, value: '' });
  const [email, setEmail] = useState({ error: false, value: '' });
  const [contactNumber, setContactNumber] = useState({
    error: false,
    value: '',
  });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectMember.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    if (!contactNumber.value || contactNumber.error) {
      isValidate = false;
      setContactNumber({ ...contactNumber, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberData = {
        name: name.value,
        email: email.value,
        contactNumber: contactNumber.value,
      };
      handleAddMember(memberData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    const error = !e.target.value;
    setName({ value: e.target.value, error });
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = !e.target.value || validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const contactNumberOnChange = (e: { target: { value: string } }) => {
    const error = validateContactNumber(e.target.value);
    setContactNumber({ value: e.target.value, error });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'add_member',
      })}
      modalDescription={intl.formatMessage({
        id: 'make_member_manage_meetings',
      })}
      id="add-member-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'add_member',
      })}
    >
      <TextField
        id="add-member-name"
        error={name.error}
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        required
        onChange={nameOnChange}
        helperText={
          name.error &&
          intl.formatMessage({
            id: 'please_insert_valid_name',
          })
        }
      />
      <TextField
        id="add-member-email"
        error={email.error}
        placeholder={intl.formatMessage({
          id: 'email',
        })}
        required
        onChange={emailOnChange}
        helperText={
          email.error &&
          intl.formatMessage({
            id: 'please_insert_valid_email',
          })
        }
      />
      <TextField
        id="add-customer-contact-number"
        error={contactNumber.error}
        placeholder={intl.formatMessage({
          id: 'contact_number',
        })}
        required
        type="tel"
        onChange={contactNumberOnChange}
        helperText={
          contactNumber.error &&
          intl.formatMessage({
            id: 'please_insert_valid_contact_number',
          })
        }
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddMemberModal));
