import { IntlShape } from 'react-intl';
import { IScheduleMeetings } from '../features/scheduleMeeting/scheduleMeeting.slice';

export const getDeviceStatus = (
  intl: IntlShape,
  rebootStatus: string
): string => {
  if (rebootStatus === 'ready' || rebootStatus === 'device_ready') {
    return intl.formatMessage({
      id: 'device_ready',
    });
  }
  if (rebootStatus === 'unavailable' || rebootStatus === 'device_powered_off') {
    return intl.formatMessage({
      id: 'device_powered_off',
    });
  }
  if (rebootStatus === 'rebooting' || rebootStatus === 'device_rebooting') {
    return intl.formatMessage({
      id: 'device_rebooting',
    });
  }
  if (rebootStatus === 'ongoing' || rebootStatus === 'device_ongoing') {
    return intl.formatMessage({
      id: 'device_call_ongoing',
    });
  }
  return rebootStatus;
};

export const getBooleanString = (intl: IntlShape, boolean: boolean): string => {
  if (boolean) {
    return intl.formatMessage({
      id: 'true',
    });
  }
  return intl.formatMessage({
    id: 'false',
  });
};

export const getMeetingName = (
  intl: IntlShape,
  row: IScheduleMeetings
): string => {
  if (!row.name && row.type === 'instant') {
    return intl.formatMessage({
      id: 'speed_dial',
    });
  }
  return row.name;
};

export const getUserStatus = (
  intl: IntlShape,
  userStatus: string,
  pendingAltText = false
): string => {
  if (pendingAltText && userStatus === 'pending') {
    return intl.formatMessage({
      id: 'awaiting_user_confirmation',
    });
  }
  if (userStatus === 'pending') {
    return intl.formatMessage({
      id: 'pending',
    });
  }
  if (userStatus === 'suspend') {
    return intl.formatMessage({
      id: 'suspend_simple',
    });
  }
  if (userStatus === 'active') {
    return intl.formatMessage({
      id: 'active',
    });
  }
  if (userStatus === 'deleted') {
    return intl.formatMessage({
      id: 'deleted',
    });
  }
  return 'none';
};
