import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons';
import RestoreIcon from '@material-ui/icons/Restore';
import { map } from 'lodash';
import { CircularProgress, IconButton } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './devices.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { Dropdown } from '../../components/Dropdown';
import DeleteDeviceModal from './components/deleteDeviceModal';
import { ResetDeviceModal, EditDeviceModal } from './components';
import {
  getBooleanString,
  getDeviceStatus,
} from '../../helper/translationFunctions';
import { IRequestParams } from '../../components/types';

interface IDevicesView extends WithStyles<typeof styles> {
  selectDevice: any;
  devicesList: any;
  permissions: any;
  handleDeleteDevice: (id: string) => void;
  handleEditDevice: (data: { id: string; name: string }) => void;
  handleResetDevice: (id: string) => void;
  handleGetDevices: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

const DevicesView: React.FC<IDevicesView> = ({
  classes,
  selectDevice,
  devicesList,
  permissions,
  handleDeleteDevice,
  handleEditDevice,
  handleResetDevice,
  handleGetDevices,
}: IDevicesView) => {
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [openEditDeviceModal, setOpenEditDeviceModal] = useState(false);
  const [openResetDeviceModal, setOpenResetDeviceModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const intl = useIntl();

  const tableHeadings = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: 'deviceType',
    //   label: intl.formatMessage({
    //     id: 'type',
    //   }),
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: 'tvAvailable',
      label: intl.formatMessage({
        id: 'tvavailable',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'PrivacyEnabled',
      label: intl.formatMessage({
        id: 'privacy_enabled',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'PrivacyTimeOut',
      label: intl.formatMessage({
        id: 'privacy_timeout',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'rebootStatus',
      label: intl.formatMessage({
        id: 'reboot_status',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'managedBy',
      label: intl.formatMessage({
        id: 'managed_by',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'reset',
      label: intl.formatMessage({
        id: 'reset',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const DeviceOptionList = [
    {
      label: intl.formatMessage({
        id: 'unassign_this_device',
      }),
      id: 'device-unassign_this_device',
      onClick: () => setOpenDeleteDeviceModal(true),
      icon: <DeleteOutlinedIcon fontSize="small" />,
    },
    {
      label: intl.formatMessage({
        id: 'edit_this_device',
      }),
      id: 'device-edit_this_device',
      onClick: () => setOpenEditDeviceModal(true),
      icon: <EditOutlinedIcon fontSize="small" />,
    },
  ];

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedDevice(data);
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    // setAnchorEl(null);
    setOpenMoreOption(false);
  };

  const handleOpenResetModal = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedDevice(data);
    setOpenResetDeviceModal(!openResetDeviceModal);
    setAnchorEl(event.currentTarget);
  };

  const renderTableRow = useCallback(() => {
    return map(devicesList.data, (row) => {
      return {
        ...row,
        name: row.userDeviceName || row.deviceName,
        tvAvailable: getBooleanString(intl, !!row.tvAvailable),
        PrivacyEnabled: getBooleanString(intl, !!row.PrivacyEnabled),
        PrivacyTimeOut: row.PrivacyTimeOut,
        rebootStatus: getDeviceStatus(intl, row.rebootStatus),
        managedBy: row.userId.profile.name,
        reset: (
          <IconButton
            id="reset-in-devices"
            size="small"
            onClick={(e) => handleOpenResetModal(e, row)}
          >
            <RestoreIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
        more: (
          <IconButton
            id="more-option-in-devices"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [devicesList]);

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="devices-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          <FormattedMessage id="devices" />
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="devices-list-table"
            title={
              <div id="total-devices">
                <FormattedMessage
                  id="total_devices"
                  values={{ n: devicesList.total }}
                />
                {selectDevice.loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination
            serverSide={false}
            filter
          />
        </Card>
      </div>
      <DeleteDeviceModal
        selectDevice={selectDevice}
        open={openDeleteDeviceModal}
        handleClose={() => setOpenDeleteDeviceModal(false)}
        selectedDevice={selectedDevice}
        handleDeleteDevice={handleDeleteDevice}
      />
      <EditDeviceModal
        selectDevice={selectDevice}
        open={openEditDeviceModal}
        handleClose={() => setOpenEditDeviceModal(false)}
        selectedDevice={selectedDevice}
        handleEditDevice={handleEditDevice}
      />
      <ResetDeviceModal
        selectDevice={selectDevice}
        open={openResetDeviceModal}
        handleClose={() => setOpenResetDeviceModal(false)}
        selectedDevice={selectedDevice}
        handleResetDevice={handleResetDevice}
      />
      <Dropdown
        id="device-more-option-button"
        open={Boolean(selectedDevice) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={DeviceOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(DevicesView));
