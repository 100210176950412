import React, { memo } from 'react';
import { Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography } from '../../Typography';
import { version } from '../../../app/config';
import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../features/app/app.slice';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: '16px 24px',
      color: theme.palette.grey[600],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px -1px 1.5px 0px #0000000D',
      height: 70,
      [theme.breakpoints.down('lg')]: {
        height: 48,
      },
    },
    footerWrapper: {
      width: '100%',
      display: 'flex',
    },
    containerLeft: {
      flexGrow: 1,
    },
    containerRight: {
      '& a': {
        textDecoration: 'none',
        marginLeft: '1vw',
        color: theme.palette.grey[600],
        '&:hover': {
          color: theme.palette.grey[600],
        },
      },
    },
  });
};

export type IFooterProps = WithStyles<typeof styles>;

const Footer = ({ classes }: IFooterProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { locationCode } = useAppSelector(selectApp);

  return (
    <div className={classes.root}>
      <div className={clsx(classes.footerWrapper, 'max-width-responsive')}>
        <div className={classes.containerLeft}>
          <Typography variant="body2">
            VLAP {version} {locationCode}
          </Typography>
        </div>
        <div className={classes.containerRight}>
          <Typography variant="body2">
            {/* <a href="/">Feedbacks</a> */}
            <a href="/contact-us" id="contact-us">
              <FormattedMessage id="contact_us" />
            </a>
            {!isMobile && (
              <>
                <a href="/terms-of-use" id="terms-of-use">
                  <FormattedMessage id="terms" />
                </a>
                <a href="/privacy-policy" id="privacy-policy">
                  <FormattedMessage id="privacy_policy" />
                </a>
              </>
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(Footer));
