/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React, { memo, useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { WithStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { map } from 'lodash';
import styles from './meetingReport.styles';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { Table } from '../../../components/Table';
import ViewReportModal from './components/viewReportModal';

interface IMeetingReport extends WithStyles<typeof styles> {
  meetingsReports: Array<any>;
}

const MeetingReport: React.FC<IMeetingReport> = ({
  classes,
  meetingsReports,
}: IMeetingReport) => {
  const intl = useIntl();

  const [viewMeetingData, setViewMeetingData] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const getStatus = (status: string) => {
    if (status === 'pending') {
      return intl.formatMessage({
        id: 'pending',
      });
    }
    if (status === 'waiting') {
      return intl.formatMessage({
        id: 'waiting',
      });
    }
    if (status === 'inprogress') {
      return intl.formatMessage({
        id: 'inprogress',
      });
    }
    if (status === 'expired') {
      return intl.formatMessage({
        id: 'expired',
      });
    }
    return intl.formatMessage({
      id: 'concluded',
    });
  };

  const tooltipText = (props: any) => {
    if (props === 'pending') {
      return intl.formatMessage({
        id: 'meeting_scheduled',
      });
    }
    if (props === 'waiting') {
      return intl.formatMessage({
        id: 'waiting_host',
      });
    }
    if (props === 'inprogress') {
      return intl.formatMessage({
        id: 'meeting_started',
      });
    }
    if (props === 'expired') {
      return intl.formatMessage({
        id: 'meeting_expired',
      });
    }
    return intl.formatMessage({
      id: 'meeting_concluded',
    });
  };

  const MeetingTableColumns = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
      },
    },
    {
      name: 'beginTime',
      label: intl.formatMessage({
        id: 'started_time',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: `host`,
      label: intl.formatMessage({
        id: 'host',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'duration',
      label: intl.formatMessage({
        id: 'duration_minutes',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'participants',
      label: intl.formatMessage({
        id: 'participants',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            textTransform: 'capitalize',
          },
        }),
      },
    },
  ];

  const convertSecondsToHMS = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const secs = duration.seconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${secs}`;
  };

  const renderTableRow = useCallback(() => {
    return map(meetingsReports, (row) => {
      return {
        ...row,
        id: row.id,
        name: row.name || intl.formatMessage({ id: 'speed_dial' }),
        beginTime: moment(row.beginTime).format('YYYY-MM-DD HH:mm:ss'),
        host: row?.host?.profile?.name,
        duration: convertSecondsToHMS(row.duration),
        participants: row?.participants,
        status: getStatus(row.status),
      };
    });
  }, [meetingsReports]);

  const options = {};

  const handleRowOnClick = (data: any) => {
    setViewMeetingData(data);
    setViewModalOpen(true);
  };

  return (
    <div className={classes.root} id="meeting-report-view">
      <div className={classes.topWrapper}>
        <Typography variant="h3" component="h2" color="textPrimary">
          <FormattedMessage id="reports" />
          <span className={classes.lightText}>
            &nbsp;/&nbsp;
            <FormattedMessage id="meetings" />
          </span>
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            title={intl.formatMessage(
              {
                id: 'total_meetings',
              },
              {
                count: meetingsReports.length,
              }
            )}
            id="report-meeting"
            columns={MeetingTableColumns}
            options={options}
            data={renderTableRow()}
            customStyles={classes.table}
            pagination
            filter
            onRowClick={handleRowOnClick}
          />
        </Card>
      </div>
      <ViewReportModal
        open={viewModalOpen}
        meeting={viewMeetingData}
        handleClose={() => setViewModalOpen(false)}
      />
    </div>
  );
};

export default memo(withStyles(styles)(MeetingReport));
