import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  IGetCustomersArgs,
  addCustomerApi,
  deleteCustomerApi,
  demoteSubadminApi,
  editCustomerApi,
  fetchAllCustomersApi,
  fetchAllSubadminsApi,
  fetchCustomerApi,
  fetchCustomersApi,
  promoteCustomerApi,
  resetCustomerPasswordApi,
  suspendCustomerApi,
  unsuspendCustomerApi,
} from './customers.api';
import { actions as appActions } from '../app/app.slice';
import { IUser, emptyUser } from '../auth/auth.slice';
import { ApiError, IResponseParams } from '../../components/types';

export interface IGetCustomerResponse extends IResponseParams {
  data: IUser[];
}

export interface ICustomersState {
  list: IGetCustomerResponse;
  listSubadmin: IGetCustomerResponse;
  deleteCustomer: any;
  resetCustomerPassword: any;
  addCustomer: any;
  editCustomer: any;
  promoteCustomer: any;
  demoteSubadmin: any;
  unsuspendCustomer: any;
  suspendCustomer: any;
  loading: boolean;
  success: boolean;
  error: any;
  all: boolean;
  customer: IUser;
}

const initialState: ICustomersState = {
  list: { data: [], total: 0 },
  listSubadmin: { data: [], total: 0 },
  deleteCustomer: null,
  resetCustomerPassword: null,
  addCustomer: null,
  editCustomer: null,
  promoteCustomer: null,
  demoteSubadmin: null,
  suspendCustomer: null,
  unsuspendCustomer: null,
  loading: false,
  success: false,
  error: null,
  all: false,
  customer: emptyUser,
};

export const fetchCustomerAsync = createAsyncThunk(
  'customer/fetch',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchCustomerApi(id);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchCustomersAsync = createAsyncThunk(
  'customers/fetch',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetCustomersArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchCustomersApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllCustomersAsync = createAsyncThunk(
  'customers/fetchAll',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetCustomersArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchAllCustomersApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllSubadminsAsync = createAsyncThunk(
  'subadmins/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllSubadminsApi(data);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addCustomerAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'customers/addCustomer',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await addCustomerApi(data);
      const { customers } = getState();
      if (customers.all) {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_customer',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteCustomersAsync = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'customers/deleteCustomer',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await deleteCustomerApi(data);
      const { customers } = getState();
      if (customers.all) {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_customer',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editCustomerAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'customers/editCustomer',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const { nextApiCallType, ...filteredData } = data;
      const response = await editCustomerApi(filteredData);
      if (nextApiCallType === 'fetchCustomer') {
        dispatch(fetchCustomerAsync(filteredData.id));
      } else if (nextApiCallType === 'fetchAllCustomers') {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_customer',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetCustomersPasswordAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'customers/resetCustomerPassword',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await resetCustomerPasswordApi(data);
      const { customers } = getState();
      if (customers.all) {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_reset_password',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const promoteCustomerAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'customers/promotecustomer',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await promoteCustomerApi(data);
      const { customers } = getState();
      if (customers.all) {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_promoted_customer',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const demoteSubadminAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'subadmins/demotesubadmin',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await demoteSubadminApi(data);
      const { customers } = getState();
      if (customers.all) {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_demoted_subadmin',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const suspendCustomerAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'customers/suspendCustomer',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const { nextApiCallType, ...filteredData } = data;
      const response = await suspendCustomerApi(filteredData.id);
      if (nextApiCallType === 'fetchCustomer') {
        dispatch(fetchCustomerAsync(filteredData.id));
      } else if (nextApiCallType === 'fetchAllCustomers') {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_suspended_customer',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unsuspendCustomerAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'customers/unsuspendCustomer',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const { nextApiCallType, ...filteredData } = data;
      const response = await unsuspendCustomerApi(filteredData.id);
      if (nextApiCallType === 'fetchCustomer') {
        dispatch(fetchCustomerAsync(filteredData.id));
      } else if (nextApiCallType === 'fetchAllCustomers') {
        dispatch(fetchAllCustomersAsync({}));
      } else {
        dispatch(fetchCustomersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_unsuspended_customer',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch customer
    builder.addCase(fetchCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.customer = action?.payload?.data;
      state.all = false;
    });
    builder.addCase(fetchCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fetch customers
    builder.addCase(fetchCustomersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
      state.all = false;
    });
    builder.addCase(fetchCustomersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fetch all customers
    builder.addCase(fetchAllCustomersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCustomersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
      state.all = true;
    });
    builder.addCase(fetchAllCustomersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fetch all subadmins
    builder.addCase(fetchAllSubadminsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSubadminsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.listSubadmin = action?.payload;
      state.all = true;
    });
    builder.addCase(fetchAllSubadminsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new customer
    builder.addCase(addCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addCustomer = action.payload;
    });
    builder.addCase(addCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete customer
    builder.addCase(deleteCustomersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCustomersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteCustomer = action.payload;
    });
    builder.addCase(deleteCustomersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Edit customer
    builder.addCase(editCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.editCustomer = action.payload;
    });
    builder.addCase(editCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset customer password
    builder.addCase(resetCustomersPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetCustomersPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetCustomerPassword = action.payload;
    });
    builder.addCase(resetCustomersPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Promote customer
    builder.addCase(promoteCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(promoteCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.promoteCustomer = action.payload;
    });
    builder.addCase(promoteCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Demote subadmin
    builder.addCase(demoteSubadminAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(demoteSubadminAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.demoteSubadmin = action.payload;
    });
    builder.addCase(demoteSubadminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Suspend customer
    builder.addCase(suspendCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(suspendCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.suspendCustomer = action.payload;
    });
    builder.addCase(suspendCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Unsuspend customer
    builder.addCase(unsuspendCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unsuspendCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.unsuspendCustomer = action.payload;
    });
    builder.addCase(unsuspendCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = customersSlice;

export const selectCustomers = (state: RootState) => state.customers;

export default customersSlice.reducer;
