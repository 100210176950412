import React, { memo, useEffect } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    customerCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IDeleteCustomerModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  selectedCustomer: any;
  handleClose?: any;
  handleDeleteCustomer: (id: string) => void;
  selectCustomer: any;
}

const DeleteCustomerModal = ({
  classes,
  open,
  selectedCustomer,
  handleClose,
  handleDeleteCustomer,
  selectCustomer,
}: IDeleteCustomerModalProps) => {
  const intl = useIntl();
  const members =
    (selectedCustomer?.members && selectedCustomer?.members.length) || 0;
  const customers =
    (selectedCustomer?.customers && selectedCustomer?.customers.length) || 0;
  const devices =
    (selectedCustomer?.devices && selectedCustomer?.devices.length) || 0;
  const groups =
    (selectedCustomer?.groups && selectedCustomer?.groups.length) || 0;

  useEffect(() => {
    handleClose();
  }, [selectCustomer.loading]);

  return (
    <ActionModal
      modalTitle={
        selectedCustomer?.role === 'subadmin'
          ? intl.formatMessage({
              id: 'do_you_want_to_delete_this_subadmin',
            })
          : intl.formatMessage({
              id: 'do_you_want_to_delete_this_customer',
            })
      }
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'delete',
      })}
      actinButtonOnClick={() => handleDeleteCustomer(selectedCustomer?._id)}
    >
      <Grid container className={classes.customerCard}>
        <Grid item xs={1}>
          <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>
              {selectedCustomer?.profile?.name ||
                intl.formatMessage({
                  id: 'not_available',
                })}
            </b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedCustomer?.email ||
              intl.formatMessage({
                id: 'not_available',
              })}
          </Typography>
          <Typography variant="body2" color="secondary">
            &nbsp;
          </Typography>
          {customers ? (
            <Typography variant="body2" color="secondary">
              {customers} &nbsp;
              <FormattedMessage id="customer_will_be_deleted" />
            </Typography>
          ) : null}
          {members ? (
            <Typography variant="body2" color="secondary">
              {members} &nbsp;
              <FormattedMessage id="member_will_be_deleted" />
            </Typography>
          ) : null}
          {devices ? (
            <Typography variant="body2" color="secondary">
              {devices} &nbsp;
              <FormattedMessage id="device_will_be_unassigned" />
            </Typography>
          ) : null}
          {groups ? (
            <Typography variant="body2" color="secondary">
              {groups} &nbsp;
              <FormattedMessage id="group_will_be_deleted" />
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteCustomerModal));
