import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Button } from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import { TextField, CopyTextField } from '../../../components/TextField';
import { Card } from '../../../components/Card';
import { SettingsSwitch } from '../../../components/SettingsItem';
import { jitsiDomain } from '../../../app/config';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
    },
    rightCard: {
      marginLeft: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(5),
    },
    description: {
      marginBottom: theme.spacing(3),
      color: theme.palette.grey[600],
    },
  });
};

interface IScreenAppSettingsView extends WithStyles<typeof styles> {
  settings: any;
  handleUpdateSettings: (data: any) => void;
}

const ScreenAppSettingsView: React.FC<IScreenAppSettingsView> = ({
  classes,
  settings,
  handleUpdateSettings,
}: IScreenAppSettingsView) => {
  const [token, setToken] = useState<any>({ value: '', error: '' });
  const [recForParticipant, setRecForParticipant] = useState<any>({
    value: false,
    error: '',
  });
  const [recForModerator, setRecForModerator] = useState<any>({
    value: false,
    error: '',
  });

  useEffect(() => {
    if (settings?.data?.screenApp) {
      setToken({ value: settings?.data?.screenApp?.token, error: false });
      setRecForParticipant({
        value: settings?.data?.screenApp?.recForParticipant,
        error: '',
      });
      setRecForModerator({
        value: settings?.data?.screenApp?.recForModerator,
        error: '',
      });
    }
  }, [settings.data]);

  const _tokenOnChange = (e: any) => {
    setToken({ value: e.target.value, error: false });
  };

  const _recForParticipantOnChange = (e: any) => {
    setRecForParticipant({ value: e.target.checked, error: '' });
  };

  const _recForModeratorOnChange = (e: any) => {
    setRecForModerator({ value: e.target.checked, error: '' });
  };

  const _saveButtonOnClick = () => {
    const data = {
      category: 'screenapp',
      setting: {
        token: token.value,
        recForParticipant: recForParticipant.value,
        recForModerator: recForModerator.value,
      },
    };
    console.log('data   ', data);
    handleUpdateSettings(data);
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Card>
          <Typography component="h3" variant="h5">
            Intergrate Screenapp Screen Recording
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            The Field Labels Marked With * Are Required Input Fields.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CopyTextField
                label="MM Domain URL"
                value={jitsiDomain}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Paste Screenapp token here*"
                value={token.value}
                onChange={_tokenOnChange}
              />
            </Grid>
          </Grid>
          <SettingsSwitch
            id="enable-recorder-participants"
            label="Enable Screen record for participants"
            checked={recForParticipant?.value}
            onChange={_recForParticipantOnChange}
            helperText="Enable Screen record for participants"
          />
          <SettingsSwitch
            id="enable-recorder-moderators"
            label="Enable Screen record for Moderators"
            checked={recForModerator?.value}
            onChange={_recForModeratorOnChange}
            helperText="Enable Screen record for Moderators"
          />
          <Button
            customStyles={classes.button}
            id="screenapp-save-button"
            variant="contained"
            label="Save"
            onClick={_saveButtonOnClick}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(ScreenAppSettingsView));
