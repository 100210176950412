import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  time: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
  },
}));

interface WaitingTimeProps {
  time: string;
}

const WaitingTime: React.FC<WaitingTimeProps> = ({ time }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Typography className={classes.time}>{time}</Typography>
      <Typography className={classes.text}>
        {intl.formatMessage({
          id: 'please_wait_for_the_stream_to_start',
        })}
      </Typography>
    </Box>
  );
};

export default WaitingTime;
