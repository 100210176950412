import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export const fetchAllMeetingsApi = (data: any): Promise<AxiosResponse> => {
  if (data) {
    return api.post('meetings', data);
  }
  return api.post('meetings');
};

export const scheduleMeetingApi = (data: any): Promise<AxiosResponse> => {
  return api.post('meetings', data);
};

export const removeMeetingApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`meetings/${id}`);
};
