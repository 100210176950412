/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { validateEmail } from '../../helper/validation';
import styles from './auth.styles';
import { Typography } from '../../components/Typography';

interface IForgotPasswordView extends WithStyles<typeof styles> {
  handleForgotPassword: Function;
  handleCancel: Function;
  forgotPasswordSuccess: boolean;
}

const ForgotPasswordView: React.FC<IForgotPasswordView> = ({
  classes,
  handleForgotPassword,
  handleCancel,
  forgotPasswordSuccess,
}: IForgotPasswordView) => {
  const intl = useIntl();
  const [email, setEmail] = useState({ error: false, value: '' });

  const _validate = () => {
    let isValidate = true;

    if (email.error) isValidate = false;
    if (!email.value) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }

    return isValidate;
  };

  const _handleForgotPasswordButton = () => {
    if (_validate()) {
      handleForgotPassword({ email: email.value });
    }
  };

  const _handleCancelButton = () => {
    handleCancel();
  };

  const _handleEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      _handleForgotPasswordButton();
    }
  };

  const _emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={7} className={classes.leftGrid}>
        <div>
          {/* <Typography component="h3" variant="h4" className={classes.heading}>
            Use bellow credentials for login to MM system.
          </Typography>
          <br />
          <Typography component="h4" variant="h5" className={classes.heading}>
            Username - testdemo@meetrix.io
            <br />
            Password - test@123
          </Typography>
          <br />
          <Typography component="h4" variant="h5" className={classes.heading}>
            Note - Please kindly note that your data will not be saved in the
            system.
          </Typography> */}
        </div>
      </Grid>
      <Grid item xs={12} sm={5} className={classes.rightGrid}>
        <div className={classes.formWrapper}>
          {forgotPasswordSuccess ? (
            <>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
                style={{ marginBottom: '2vh' }}
              >
                <FormattedMessage id="forgot_password_email_sent" />
              </Typography>
              <Typography
                component="h7"
                variant="h7"
                className={classes.heading}
                style={{ marginBottom: '12vh' }}
              >
                <FormattedMessage id="forgot_password_email_sent_description" />
              </Typography>
              <Button
                id="forgot-password-ok-button"
                align="right"
                variant="contained"
                label={intl.formatMessage({
                  id: 'ok',
                })}
                onClick={_handleCancelButton}
                customStyles={classes.forgotPasswordOkButton}
              />
            </>
          ) : (
            <>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
                style={{ marginBottom: '2vh' }}
              >
                <FormattedMessage id="forgot_password" />
              </Typography>
              <Typography
                component="h7"
                variant="h7"
                className={classes.heading}
                style={{ marginBottom: '12vh' }}
              >
                <FormattedMessage id="forgot_password_description" />
              </Typography>
              <TextField
                id="forgot-password-email"
                label={intl.formatMessage({
                  id: 'email',
                })}
                onChange={_emailOnChange}
                error={email.error}
                helperText={
                  email.error &&
                  intl.formatMessage({
                    id: 'please_insert_valid_email',
                  })
                }
                onKeyDown={_handleEnterPress}
              />
              <Button
                id="forgot-password-button"
                align="left"
                variant="contained"
                label={intl.formatMessage({
                  id: 'forgot_password',
                })}
                onClick={_handleForgotPasswordButton}
                customStyles={classes.forgotPasswordButton2}
              />
              <Button
                id="forgot-password-button"
                align="right"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'cancel',
                })}
                onClick={_handleCancelButton}
                customStyles={classes.cancelButton}
              />
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(ForgotPasswordView));
