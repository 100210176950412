/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import ScheduleReplayStreamingModal from './scheduleReplayStreamingModal.view';
import { ShareMeetingModal } from '../../../meetings/components';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import {
  selectReplaystreaming,
  actions,
  updateReplaystreamingAsync,
  createReplaystreamingAsync,
  getLocalLivestreamingAsync,
} from '../../replaystreaming.slice';
import { getGroupsAsync, selectGroups } from '../../../group/groups.slice';
import {
  fetchMembersAsync,
  selectMembers,
} from '../../../members/members.slice';
import {
  fetchDevicesAsync,
  selectDevices,
} from '../../../devices/devices.slice';
import { IScheduledMeetingData } from '../../replaystreaming.api';

const ScheduleReplayStreamingModalContainer: React.FC<unknown> = () => {
  const replaystreamings = useAppSelector(selectReplaystreaming);
  const groups = useAppSelector(selectGroups);
  const devices = useAppSelector(selectDevices);
  const member = useAppSelector(selectMembers);
  const dispatch = useAppDispatch();

  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);

  const _scheduleMeetinOnClose = useCallback(() => {
    if (replaystreamings.isUpdateReplaystreaming)
      dispatch(actions.closeEditMeetingModal());
    else dispatch(actions.closeScheduleModal());
  }, [dispatch, replaystreamings.isUpdateReplaystreaming]);

  useEffect(() => {
    if (
      replaystreamings.success &&
      replaystreamings.isScheduleModalOpen &&
      !isShareMeetingOpen
    ) {
      _scheduleMeetinOnClose();
      // setIsShareMeetingOpen(true);
    }
  }, [replaystreamings.success]);

  useEffect(() => {
    dispatch(getGroupsAsync({ limit: 500 }));
    dispatch(fetchMembersAsync({ limit: 500 }));
    dispatch(fetchDevicesAsync({ limit: 500 }));
    dispatch(getLocalLivestreamingAsync({ search: '' }));
  }, []);

  const _saveMeetingBtnClick = (data: IScheduledMeetingData) => {
    if (replaystreamings.isUpdateReplaystreaming)
      dispatch(updateReplaystreamingAsync(data));
    else dispatch(createReplaystreamingAsync(data));
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
    dispatch(actions.resetScheduleMeeting());
  };
  return (
    <>
      {replaystreamings.isScheduleModalOpen && (
        <ScheduleReplayStreamingModal
          open={replaystreamings.isScheduleModalOpen}
          groups={groups.list}
          devicesList={devices.list.data}
          membersList={member.list.data}
          handleClose={_scheduleMeetinOnClose}
          saveMeetingButtonClick={_saveMeetingBtnClick}
          isUpdateReplaystreaming={replaystreamings.isUpdateReplaystreaming}
          isCreateReplayStreaming={replaystreamings.isCreateReplayStreaming}
          isViewReplayStreaming={replaystreamings.isViewReplayStreaming}
          meeting={replaystreamings.data}
          loading={replaystreamings.loading}
          streamingUrlsList={replaystreamings.streamingUrls}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={replaystreamings?.data}
        />
      )}
    </>
  );
};

export default memo(ScheduleReplayStreamingModalContainer);
