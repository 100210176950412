import React, { memo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, Avatar, IconButton } from '@material-ui/core';
import { MicNone, MicOff, Videocam, VideocamOff } from '@material-ui/icons';
import Webcam from 'react-webcam';
import styles from './meetingRoom.styles';
import { Button } from '../../components/Button';
import { Typography } from '../../components/Typography';
import { TextField } from '../../components/TextField';
import { Card } from '../../components/Card';
import VideoConference from './videoConference/VideoConference';
import { validateEmail } from '../../helper/validation';
import { Clock } from '../../components/Clock';
import { LoadingLayout } from '../../components/layout';
import { useAppDispatch } from '../../app/hooks';
import { actions } from './meetingRoom.slice';

interface IMeetingRoomView extends WithStyles<typeof styles> {
  roomName: string;
  roomTitle: string;
  jwtToken: string;
  jwtTokenSuccess: boolean;
  user: any;
  backToHomeButtonOnClick: () => void;
  getJwtTokenButtonOnClick: (data: any) => void;
  showScreenappForModerator: boolean;
  showScreenappForParticipants: boolean;
}

const MeetingRoomView: React.FC<IMeetingRoomView> = ({
  classes,
  roomName = '',
  roomTitle = '',
  jwtToken = '',
  jwtTokenSuccess,
  user = null,
  backToHomeButtonOnClick,
  getJwtTokenButtonOnClick,
  showScreenappForModerator,
  showScreenappForParticipants,
}: IMeetingRoomView) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLobby, setIsLobby] = useState<boolean>(true);
  const [conferenceLeft, setConferenceLeft] = useState<boolean>(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [name, setName] = useState<any>({ value: '', error: false });
  const [email, setEmail] = useState<any>({ value: '', error: false });
  const [skipJoinPage, setSkipJoinPage] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (user?.email && user?.profile?.name) {
      setName({ value: user?.profile?.name, error: false });
      setEmail({ value: user?.email, error: false });
      // when authenticated user arrives, set skip join page to true
      setSkipJoinPage(true);
    }
  }, [user]);

  // skip join page
  useEffect(() => {
    if (skipJoinPage && name && email) _getJwtTokenButtonOnClick();
  }, [skipJoinPage, name, email]);

  useEffect(() => {
    if (jwtToken && jwtTokenSuccess) {
      setIsLobby(false);
      setConferenceLeft(false);
      setIsLoading(true);
      dispatch(actions.resetSuccess());
    }
  }, [jwtToken]);

  const _conferenceJoined = () => {
    setIsLoading(false);
  };

  const _lobbyJoined = () => {
    setIsLoading(false);
  };

  const _setConferenceLeft = () => {
    // setIsLobby(true);
    // setConferenceLeft(true);
    backToHomeButtonOnClick(); // skip call end page and redirects to speed dials page
  };

  const _backToHomeButtonOnClick = () => {
    backToHomeButtonOnClick();
  };

  const _rejoinButtonOnClick = () => {
    setIsLobby(false);
    setConferenceLeft(false);
    setIsLoading(true);
  };

  const _nameOnChange = (e: { target: { value: string } }) => {
    setName({ value: e.target.value, error: false });
  };

  const _emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const _validate = () => {
    let isValidate = true;
    if (email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    return isValidate;
  };

  const _getJwtTokenButtonOnClick = () => {
    if (_validate()) {
      const data = {
        roomId: '',
        roomName,
        userName: name?.value,
        userEmail: email?.value,
      };
      getJwtTokenButtonOnClick(data);
    }
  };
  const videoConstraints = {
    aspectRatio: 1.777777778,
  };

  const _renderConferenceLeftView = () => (
    <>
      <Card customStyles={classes.bannerCard}>
        <Clock id="lobby-clock" />
      </Card>
      <Card customStyles={classes.useInfoCard}>
        <Typography variant="h5" className={classes.cardHeading}>
          Thank you for joining with us!
        </Typography>
        {(email?.value || name?.value) && (
          <>
            <Typography variant="body2" color="textSecondary">
              You logged in as a
            </Typography>
            <Grid container className={classes.profileDetailsWrapper}>
              <Grid xs={3}>
                <Avatar alt="Remy Sharp" />
              </Grid>
              <Grid>
                <Typography variant="body2">{name?.value}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {email?.value}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            id="rejoin-button-in-disconnect"
            variant="outlined"
            color="primary"
            label={intl.formatMessage({
              id: 'rejoin',
            })}
            customStyles={classes.rejoin}
            onClick={_rejoinButtonOnClick}
          />
          <Button
            id="back-to-home-in-disconnect"
            variant="contained"
            color="primary"
            label={intl.formatMessage({
              id: 'back_to_home',
            })}
            onClick={_backToHomeButtonOnClick}
          />
        </div>
      </Card>
    </>
  );
  const _renderCollectUserInfoView = () => (
    <>
      <Card customStyles={classes.bannerCard}>
        <Clock id="lobby-clock" />
      </Card>
      <Card customStyles={classes.useInfoCard}>
        <Typography
          component="h1"
          variant="h5"
          className={classes.textOverflow}
        >
          {roomTitle}
        </Typography>
        <TextField
          label={intl.formatMessage({
            id: 'name',
          })}
          value={name.value}
          onChange={_nameOnChange}
        />
        <TextField
          label={intl.formatMessage({
            id: 'email',
          })}
          value={email.value}
          onChange={_emailOnChange}
          error={email.error}
          helperText={
            email.error &&
            intl.formatMessage({
              id: 'please_insert_valid_email',
            })
          }
        />
        <Button
          id="get-jwt-token-button"
          variant="contained"
          label={intl.formatMessage({
            id: 'join',
          })}
          onClick={_getJwtTokenButtonOnClick}
        />
      </Card>
    </>
  );

  const _renderLobby = () => (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4} className={classes.leftGrid}>
        {/* <Card>ssdsd</Card> */}
        {conferenceLeft
          ? _renderConferenceLeftView()
          : _renderCollectUserInfoView()}
      </Grid>
      <Grid item xs={12} md={8} className={classes.rightGrid}>
        <div className={classes.videoPreviewWrapper}>
          {videoMuted ? (
            <div className={classes.videoMutedPreview}>Camara preview</div>
          ) : (
            <Webcam videoConstraints={videoConstraints} />
          )}
        </div>
        <div className={classes.mediaButtonsWrapper}>
          <IconButton
            className={classes.mediaButton}
            id="audio-button"
            onClick={() => setAudioMuted((prev) => !prev)}
          >
            {audioMuted ? <MicOff /> : <MicNone />}
          </IconButton>
          <IconButton
            className={classes.mediaButton}
            id="video-button"
            onClick={() => setVideoMuted((prev) => !prev)}
          >
            {videoMuted ? <VideocamOff /> : <Videocam />}
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );

  const _renderConferene = () => (
    <>
      {isLoading && <LoadingLayout />}
      {jwtToken && (
        <div className={classes.conferenceContainer}>
          <VideoConference
            location={roomName}
            jwtToken={jwtToken}
            setConferenceLeft={_setConferenceLeft}
            audioMuted={audioMuted}
            videoMuted={videoMuted}
            showScreenappForModerator={showScreenappForModerator}
            showScreenappForParticipants={showScreenappForParticipants}
            conferenceJoined={_conferenceJoined}
            lobbyJoined={_lobbyJoined}
          />
        </div>
      )}
    </>
  );

  return <>{isLobby ? _renderLobby() : _renderConferene()}</>;
};

export default memo(withStyles(styles)(MeetingRoomView));
