import React, { memo, useState } from 'react';
import moment from 'moment';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import PermContactCalendarRoundedIcon from '@material-ui/icons/PermContactCalendarRounded';
import { Typography } from '../../../components/Typography';
import { Button, IconOnlyButton } from '../../../components/Button';
import { ActionModal } from '../../../components/Modal';
import { MeetingCard } from '../../../components/MeetingCard';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      all: 'unset',
      cursor: 'pointer',
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: '15px',
      },
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'end',
      },
    },
    streamTitle: {
      marginBottom: '16px',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    infoWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '40px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '15px',
      },
    },
    infoItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '7px',

      [theme.breakpoints.down('xs')]: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
      },
    },
    infoItemText: {
      color: theme.palette.secondary.contrastText,
      textTransform: 'capitalize',
    },
  });
};

interface IEventCardProps extends WithStyles<typeof styles> {
  key?: any;
  data?: any;
  type: 'recent' | 'ongoing' | 'upcoming';
  removeMeeting?: (id: string) => void;
  joinToMeetingButtonClick?: (data: any) => void;
  openUpdateScheduledEventModal?: (data: any) => void;
  openViewScheduledEventModal?: (data: any) => void;
  stopMeeting?: (id: string) => void;
}

const EventCard = ({
  classes,
  key,
  data,
  type,
  removeMeeting,
  joinToMeetingButtonClick,
  openUpdateScheduledEventModal,
  openViewScheduledEventModal,
  stopMeeting,
}: IEventCardProps) => {
  const intl = useIntl();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);
  const { _id, name, beginTime, host, location } = data;

  const _onClickDeleteButton = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(true);
  };
  const _onClickDeleteModalClose = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(false);
  };

  const _removeMeetingButtonOnClick = () => {
    if (_id && removeMeeting) {
      removeMeeting(_id);
      setOpenDeleteModal(false);
    }
  };

  const _onClickStopModalOpen = (e: any) => {
    e.stopPropagation();
    setOpenStopModal(true);
  };
  const _onClickStopModalClose = (e: any) => {
    e.stopPropagation();
    setOpenStopModal(false);
  };

  const _stopMeetingButtonOnClick = (e: any) => {
    e.stopPropagation();
    if (_id && stopMeeting) {
      stopMeeting(_id);
      setOpenStopModal(false);
    }
  };

  const _handleOnClickCard = () => {
    if (!openDeleteModal) {
      if (openUpdateScheduledEventModal) openUpdateScheduledEventModal(data);

      if (openViewScheduledEventModal) openViewScheduledEventModal(data);
    }
  };

  const InfoItem = ({ icon, text }: any) => {
    return (
      <div className={classes.infoItem}>
        {icon}
        <Typography variant="body1" className={classes.infoItemText}>
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <button
      className={classes.root}
      key={key}
      onClick={_handleOnClickCard}
      type="submit"
    >
      <MeetingCard customStyles={classes.card}>
        <div>
          <Typography variant="h5" className={classes.streamTitle}>
            {name ||
              intl.formatMessage({
                id: 'no_title',
              })}
          </Typography>
          <div className={classes.infoWrapper}>
            <InfoItem
              icon={<EventRoundedIcon />}
              text={`${intl.formatMessage({
                id: 'date',
              })} : ${moment(beginTime).format('YYYY/MM/DD')}`}
            />
            <InfoItem
              icon={<ScheduleRoundedIcon />}
              text={`${intl.formatMessage({
                id: 'time',
              })} : ${moment(beginTime).format('hh:mm A')}`}
            />
            <InfoItem
              icon={<PermContactCalendarRoundedIcon />}
              text={`${intl.formatMessage({
                id: 'host',
              })} : ${host?.profile?.name || '-'}`}
            />
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          {type === 'upcoming' && (
            <IconOnlyButton onClick={_onClickDeleteButton}>
              <DeleteForeverRoundedIcon />
            </IconOnlyButton>
          )}
          {type === 'ongoing' && (
            <Button
              color="primary"
              id="stop-scheduled-event"
              variant="contained"
              label={intl.formatMessage({
                id: 'stop',
              })}
              onClick={_onClickStopModalOpen}
              // customStyles={classes.tableActionButton}
            />
          )}
        </div>
      </MeetingCard>
      {/* Delete event modal */}
      <ActionModal
        modalTitle={intl.formatMessage({
          id: 'delete_scheduled_event',
        })}
        modalDescription={intl.formatMessage(
          {
            id: 'delete_scheduled_event_description',
          },
          {
            name,
          }
        )}
        open={openDeleteModal}
        handleClose={_onClickDeleteModalClose}
        disableBackdropClick
        actinButtonOnClick={_removeMeetingButtonOnClick}
        actinButtonLabel={intl.formatMessage({
          id: 'delete',
        })}
      />

      {/* Stop event modal */}
      <ActionModal
        modalTitle={intl.formatMessage({
          id: 'stop_scheduled_event',
        })}
        modalDescription={intl.formatMessage(
          {
            id: 'stop_scheduled_event_description',
          },
          {
            name,
          }
        )}
        open={openStopModal}
        handleClose={_onClickStopModalClose}
        disableBackdropClick
        actinButtonOnClick={_stopMeetingButtonOnClick}
        actinButtonLabel={intl.formatMessage({
          id: 'stop',
        })}
      />
    </button>
  );
};

EventCard.defaultProps = {
  key: '',
  data: '',
  removeMeeting: (id: any) => console.log('Deleted ', id),
  joinToMeetingButtonClick: (location: any) => console.log('Joined ', location),
  openUpdateScheduledEventModal: (data: any) => console.log('Edit ', data),
  openViewScheduledEventModal: (data: any) => console.log('View ', data),
  stopMeeting: (id: any) => console.log('Stoped ', id),
};

export default memo(withStyles(styles)(EventCard));
