import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    meetingCard: {},
    title: {
      marginBottom: 16,
      fontWeight: 700,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        // fontSize: theme.typography.subtitle1.fontSize,
      },
    },
    infoItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',

      [theme.breakpoints.down('xs')]: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
      },
    },
    infoItemText: {
      whiteSpace: 'nowrap',
      // width: 50,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    largeItem: {
      gridColumnStart: 2,
      gridColumnEnd: 4,
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      gap: '10px',
      [theme.breakpoints.down('md')]: {
        gap: 8,
      },
    },
    meetingInfoTopWrapper: {
      display: 'flex',
      gap: 60,
      borderBottom: '1px solid #E9E9E9',
      paddingBottom: 16,
      [theme.breakpoints.down('lg')]: {
        gap: 32,
      },
      '@media(max-width: 600px)': {
        flexDirection: 'column',
        gap: 12,
      },
    },
    meetingInfoBottomWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 16,
      [theme.breakpoints.down('md')]: {
        gap: 15,
        flexDirection: 'column',
      },
    },
    infoItemsWrapper: {
      display: 'flex',
      gap: 25,
      [theme.breakpoints.down('xs')]: {
        gap: 15,
        flexDirection: 'column',
      },
    },
    overflowHidden: {
      overflow: 'hidden',
    },
  });
};

export default styles;
