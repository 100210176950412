/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MeetingRoomView from './meetingRoom.view';
import { actions as scheduleMeetingAction } from '../scheduleMeeting/scheduleMeeting.slice';
import {
  getMeetingTokenAsync,
  selectMeetingRoom,
  actions,
  getMeetingByLocationAsync,
} from './meetingRoom.slice';
import { getProfileAsync, selectAuth } from '../auth/auth.slice';
import { selectSettings } from '../settings/settings.slice';
import { getToken } from '../../helper/localStorage';
import { actions as appActions } from '../app/app.slice';

export interface IMeetingRoomContainer {}

const MeetingRoomContainer: React.FC<IMeetingRoomContainer> = ({}: IMeetingRoomContainer) => {
  const dispatch = useAppDispatch();
  const meetingRoom = useAppSelector(selectMeetingRoom);
  const auth = useAppSelector(selectAuth);
  const settings = useAppSelector(selectSettings);

  const history = useHistory();
  const [roomName, setRoomName] = useState<string>('');
  const [roomTitle, setRoomTitle] = useState<string>('');

  useEffect(() => {
    const token = getToken();
    if (token) {
      dispatch(getProfileAsync(null));
    }
    const pathnameArray = history.location.pathname.split('/');
    const room =
      pathnameArray.length > 0 ? pathnameArray[pathnameArray.length - 1] : '';
    dispatch(getMeetingByLocationAsync(room));
    setRoomName(room);
  }, [dispatch, history.location.pathname]);

  useEffect(() => {
    setRoomTitle(meetingRoom.roomName);
  }, [meetingRoom.roomName]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10109'));
  }, []);

  const _backToHomeButtonOnClick = () => {
    dispatch(actions.resetJwtToken());
    history.push('/');
  };

  const _resetScheduleMeeting = () => {
    dispatch(scheduleMeetingAction.resetScheduleMeeting());
  };

  const _getJwtTokenButtonOnClick = (data: any) => {
    dispatch(getMeetingTokenAsync(data));
  };
  return (
    <MeetingRoomView
      roomName={roomName}
      roomTitle={roomTitle}
      jwtToken={meetingRoom?.jwtToken}
      jwtTokenSuccess={meetingRoom?.success}
      user={auth?.user}
      backToHomeButtonOnClick={_backToHomeButtonOnClick}
      getJwtTokenButtonOnClick={_getJwtTokenButtonOnClick}
      showScreenappForModerator={settings?.data?.screenApp?.recForModerator}
      showScreenappForParticipants={
        settings?.data?.screenApp?.recForParticipant
      }
    />
  );
};

export default memo(MeetingRoomContainer);
