import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addGroupApi,
  editGroupApi,
  deleteGroupApi,
  getGroupsApi,
  deleteAnyGroupApi,
  editAnyGroupApi,
  destroyGroupMemberApi,
  destroyAnyGroupMemberApi,
  getAllGroupsApi,
  IGetGroupArgs,
  deleteGroupsApi,
  IAddGroupData,
  IDeleteGroupData,
  IEditGroupData,
  deleteAnyGroupsApi,
} from './groups.api';
import { actions as appActions } from '../app/app.slice';
import { fetchMembersAsync } from '../members/members.slice';
import { IUser } from '../auth/auth.slice';
import { IDevice } from '../devices/devices.slice';
import { ApiError, IResponseParams, IbaseDoc } from '../../components/types';

export type Status = 'active' | 'deleted';

export interface IGroupMember {
  userId: IUser;
  groupId: string;
  status: Status;
}

export interface IGroupDevice {
  deviceId: IDevice;
  groupId: string;
  status: Status;
}

export interface IGroup extends IbaseDoc {
  name: string;
  description: string;
  members: IGroupMember[];
  devices: IGroupDevice[];
  status?: Status;
  parentId?: IUser;
}

export interface IGetGroupResponse extends IResponseParams {
  data: IGroup[];
}

export interface IDeleteGroupResponse extends IResponseParams {
  data: string[];
}

export interface ISaveGroup {
  savedDevices?: IDevice;
  savedGroup?: IGroup;
  savedMembers?: IUser;
}

export interface IAddGroupResponse extends IResponseParams {
  data: ISaveGroup;
}

export interface IEditGroupResponse extends IResponseParams {
  data: ISaveGroup;
}

export interface IDeleteGroupMemberResponse extends IResponseParams {
  data: [];
}

export interface IGroupsState {
  list: IGetGroupResponse;
  listWithSelectGroup: IGetGroupResponse;
  listAll: IGetGroupResponse;
  deleteGroup: IDeleteGroupResponse;
  editGroup: IEditGroupResponse;
  addGroup: IAddGroupResponse;
  deleteGroupMember: IDeleteGroupMemberResponse;
  loading: boolean;
  success: boolean;
  error: ApiError;
  all: boolean;
}

export const emptyGroup: IGroup = {
  id: '',
  _id: '',
  name: 'None',
  description: '',
  members: [],
  devices: [],
};

const initialState: IGroupsState = {
  list: { data: [], total: 0 },
  listWithSelectGroup: { data: [], total: 0 },
  listAll: { data: [], total: 0 },
  deleteGroup: { data: [] },
  editGroup: { data: {} },
  addGroup: { data: {} },
  deleteGroupMember: { data: [] },
  loading: false,
  success: false,
  error: null,
  all: false,
};

export const getGroupsAsync = createAsyncThunk(
  'group/read',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetGroupArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await getGroupsApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllGroupsAsync = createAsyncThunk(
  'group/readAll',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetGroupArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await getAllGroupsApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addGroupAsync = createAsyncThunk(
  'group/create',
  async (data: IAddGroupData, { rejectWithValue, dispatch }) => {
    try {
      const { nextApiCallType, ...filteredData } = data;
      const response = await addGroupApi(filteredData);
      if (nextApiCallType === 'getGroups') {
        dispatch(getGroupsAsync({}));
        dispatch(fetchMembersAsync({}));
      } else if (nextApiCallType === 'getGroupsByCustomer') {
        dispatch(getGroupsAsync({ id: data.customerId }));
        dispatch(fetchMembersAsync({ id: data.customerId }));
      } else if (nextApiCallType === 'getAllGroups') {
        dispatch(getAllGroupsAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteGroupAsync = createAsyncThunk(
  'group/destroy',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteGroupApi(data);
      dispatch(getGroupsAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteGroupsAsync = createAsyncThunk(
  'group/destroyMany',
  async (data: string[], { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteGroupsApi(data);
      dispatch(getGroupsAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteAnyGroupAsync = createAsyncThunk(
  'group/destroyAny',
  async (data: IDeleteGroupData, { rejectWithValue, dispatch }) => {
    try {
      const { customerId, nextApiCallType, ...filteredData } = data;
      const response = await deleteAnyGroupApi(filteredData.id);
      if (nextApiCallType === 'getGroupsByCustomer') {
        dispatch(getGroupsAsync({ id: customerId }));
      } else if (nextApiCallType === 'getAllGroups') {
        dispatch(getAllGroupsAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteAnyGroupsAsync = createAsyncThunk(
  'group/destroyAnyMany',
  async (data: string[], { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteAnyGroupsApi(data);
      dispatch(getAllGroupsAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editGroupAsync = createAsyncThunk(
  'group/edit',
  async (data: IEditGroupData, { rejectWithValue, dispatch }) => {
    try {
      const response = await editGroupApi(data);
      dispatch(getGroupsAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_updated_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editAnyGroupAsync = createAsyncThunk(
  'group/editAny',
  async (data: IEditGroupData, { rejectWithValue, dispatch }) => {
    try {
      const { customerId, nextApiCallType, ...filteredData } = data;
      const response = await editAnyGroupApi(filteredData);
      if (nextApiCallType === 'getGroupsByCustomer') {
        dispatch(getGroupsAsync({ id: data.customerId }));
      } else if (nextApiCallType === 'getAllGroups') {
        dispatch(getAllGroupsAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_updated_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const destroyGroupMemberAsync = createAsyncThunk(
  'group/destroyGroupMember',
  async (data: IDeleteGroupData, { rejectWithValue, dispatch }) => {
    try {
      const { customerId, nextApiCallType, ...filteredData } = data;
      const response = await destroyGroupMemberApi(filteredData.id);
      if (nextApiCallType === 'getGroups') {
        dispatch(getGroupsAsync({ id: customerId }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_updated_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const destroyAnyGroupMemberAsync = createAsyncThunk(
  'group/destroyAnyGroupMember',
  async (data: IDeleteGroupData, { rejectWithValue, dispatch }) => {
    try {
      const { customerId, nextApiCallType, ...filteredData } = data;
      const response = await destroyAnyGroupMemberApi(filteredData.id);
      if (nextApiCallType === 'getGroups') {
        dispatch(getGroupsAsync({ id: customerId }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_updated_group',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    pushEmptyGroup(state, action) {
      const index = state.listWithSelectGroup.data.findIndex(
        (group) => group.id === ''
      );
      if (index === -1) {
        let emptyGroupTemp = emptyGroup;
        if (action?.payload) {
          emptyGroupTemp = { ...emptyGroup, name: action.payload };
        }
        state.listWithSelectGroup.data.unshift(emptyGroupTemp);
      }
    },
  },
  extraReducers: (builder) => {
    // Fetch all groups
    builder.addCase(getAllGroupsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllGroupsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.listAll = action?.payload;
    });
    builder.addCase(getAllGroupsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Fetch groups (Only local users)
    builder.addCase(getGroupsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGroupsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
      state.listWithSelectGroup = action?.payload;
    });
    builder.addCase(getGroupsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new groups
    builder.addCase(addGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addGroup = action.payload;
    });
    builder.addCase(addGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete group
    builder.addCase(deleteGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroup = action.payload;
    });
    builder.addCase(deleteGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete any group
    builder.addCase(deleteAnyGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteAnyGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroup = action.payload;
    });
    builder.addCase(deleteAnyGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete many groups
    builder.addCase(deleteGroupsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGroupsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroup = action.payload;
    });
    builder.addCase(deleteGroupsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete any many groups
    builder.addCase(deleteAnyGroupsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteAnyGroupsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroup = action.payload;
    });
    builder.addCase(deleteAnyGroupsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Edit group
    builder.addCase(editGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.editGroup = action.payload;
    });
    builder.addCase(editGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Destroy group member
    builder.addCase(destroyGroupMemberAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(destroyGroupMemberAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroupMember = action.payload;
    });
    builder.addCase(destroyGroupMemberAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Destroy any group member
    builder.addCase(destroyAnyGroupMemberAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(destroyAnyGroupMemberAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroupMember = action.payload;
    });
    builder.addCase(destroyAnyGroupMemberAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = groupsSlice;

export const selectGroups = (state: RootState): IGroupsState => state.groups;

export default groupsSlice.reducer;
