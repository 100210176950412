import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addMemberApi,
  deleteMemberApi,
  fetchMembersApi,
  fetchAllMembersApi,
  resetMemberPasswordApi,
  editMemberApi,
  IGetMembersArgs,
} from './members.api';
import { actions as appActions } from '../app/app.slice';
import { getGroupsAsync } from '../group/groups.slice';
import { IUser } from '../auth/auth.slice';
import { ApiError, IResponseParams } from '../../components/types';

export interface IGetMemberResponse extends IResponseParams {
  data: IUser[];
}

export interface IMembersState {
  list: IGetMemberResponse;
  deleteMember: any;
  resetMemberPassword: any;
  addMember: any;
  editMember: any;
  loading: boolean;
  success: boolean;
  error: any;
  all: boolean;
}

const initialState: IMembersState = {
  list: { data: [], total: 0 },
  addMember: null,
  deleteMember: null,
  resetMemberPassword: null,
  editMember: null,
  loading: false,
  success: false,
  error: null,
  all: false,
};

export const fetchMembersAsync = createAsyncThunk(
  'members/fetch',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetMembersArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchMembersApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllMembersAsync = createAsyncThunk(
  'members/fetchAll',
  async (
    {
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetMembersArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchAllMembersApi({
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addMemberAsync = createAsyncThunk<void, any, { state: RootState }>(
  'members/addMember',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await addMemberApi(data);
      const { members } = getState();
      if (members.all) {
        dispatch(fetchAllMembersAsync({}));
      } else if (data?.customerId) {
        dispatch(fetchMembersAsync(data.customerId));
      } else {
        dispatch(fetchMembersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_member',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteMembersAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'members/deleteMember',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await deleteMemberApi(data.id);
      const { members } = getState();
      if (data.customerId) {
        dispatch(fetchMembersAsync(data.customerId));
        dispatch(getGroupsAsync({ id: data.customerId }));
      } else if (members.all) {
        dispatch(fetchAllMembersAsync({}));
      } else {
        dispatch(fetchMembersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_member',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editMembersAsync = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'members/editMember',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await editMemberApi(data);
      const { members } = getState();
      if (members.all) {
        dispatch(fetchAllMembersAsync({}));
      } else {
        dispatch(fetchMembersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_member',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetMembersPasswordAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'members/resetMemberPassword',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await resetMemberPasswordApi(data);
      const { members } = getState();
      if (members.all) {
        dispatch(fetchAllMembersAsync({}));
      } else {
        dispatch(fetchMembersAsync({}));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_reset_password',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch members
    builder.addCase(fetchMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
      state.all = false;
    });
    builder.addCase(fetchMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fetch all members
    builder.addCase(fetchAllMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
      state.all = true;
    });
    builder.addCase(fetchAllMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new member
    builder.addCase(addMemberAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addMemberAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addMember = action.payload;
    });
    builder.addCase(addMemberAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete member
    builder.addCase(deleteMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteMember = action.payload;
    });
    builder.addCase(deleteMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Edit member
    builder.addCase(editMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.editMember = action.payload;
    });
    builder.addCase(editMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset member password
    builder.addCase(resetMembersPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetMembersPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetMemberPassword = action.payload;
    });
    builder.addCase(resetMembersPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = membersSlice;

export const selectMembers = (state: RootState) => state.members;

export default membersSlice.reducer;
