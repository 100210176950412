import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GroupIcon from '@material-ui/icons/Group';
import ShareIcon from '@material-ui/icons/Share';
import ExploreIcon from '@material-ui/icons/Explore';
import TvRoundedIcon from '@material-ui/icons/TvRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ScheduleIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import { Typography } from '../../../components/Typography';
import { Button, IconOnlyButton } from '../../../components/Button';
import styles from './meetingCard.styles';
import {
  DeleteMeetingModal,
  ShareMeetingModal,
} from '../../meetings/components';
import { MeetingCard } from '../../../components/MeetingCard';
import { ActionModal } from '../../../components/Modal';

interface IMeetingCardProps extends WithStyles<typeof styles> {
  meeting: any;
  openEditMeetingModal: (data: any) => void;
  joinToMeetingButtonClick: (data: any) => void;
  key?: any;
  removeMeeting: (id: string) => void;
}

const AllMeetingCard = ({
  classes,
  meeting,
  openEditMeetingModal,
  joinToMeetingButtonClick,
  removeMeeting,
  key,
}: IMeetingCardProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isMeetingInfoOpen, setIsMeetingInfoOpen] = useState(false);
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const isInstant = meeting?.type === 'instant';
  const isLivestreaming = meeting?.type === 'livestreaming';
  const isScheduledEvent = meeting?.type === 'scheduledevent';

  const meetingName = meeting?.name;

  const _onClickDeleteButton = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(true);
  };
  const _onClickDeleteModalClose = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(false);
  };

  const InfoItem = ({ icon, text, maxWidth, customClass }: any) => {
    return (
      <div className={clsx(classes.infoItem, customClass)}>
        {icon}
        <Typography
          variant="body1"
          className={classes.infoItemText}
          style={{ maxWidth }}
        >
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <MeetingCard customStyles={classes.meetingCard} key={key}>
      <Typography variant="caption" component="h3" className={classes.title}>
        {meeting?.name}
      </Typography>
      <div className={classes.meetingInfoTopWrapper}>
        {!isScheduledEvent && !isLivestreaming && (
          <InfoItem
            icon={<ExploreIcon />}
            text={`${intl.formatMessage({
              id: isMediumScreen ? 'id' : 'meeting_id',
            })} : ${meeting._id}`}
            maxWidth={187}
          />
        )}
        {!isScheduledEvent && (
          <>
            <InfoItem
              icon={<PermContactCalendarIcon />}
              text={`${intl.formatMessage({
                id: 'host',
              })} : ${meeting?.host?.profile?.name}`}
              maxWidth={300}
            />
            <InfoItem
              icon={<GroupIcon />}
              text={intl.formatMessage(
                {
                  id: 'count_participants',
                },
                {
                  count: meeting?.members.length,
                }
              )}
            />
          </>
        )}
        {(isScheduledEvent || isLivestreaming) && (
          <InfoItem
            icon={<TvRoundedIcon />}
            text={`${intl.formatMessage({
              id: 'videolink_tv_count',
            })} : ${meeting?.devices?.length}`}
          />
        )}
        {isScheduledEvent && (
          <InfoItem
            icon={<LinkRoundedIcon />}
            text={`${intl.formatMessage({
              id: 'url',
            })} :  ${meeting?.scheduledevent?.viewingUrl}`}
            // maxWidth={400}
            customClass={classes.overflowHidden}
          />
        )}
      </div>

      <div className={classes.meetingInfoBottomWrapper}>
        <div className={classes.infoItemsWrapper}>
          <InfoItem
            icon={<DateRangeRoundedIcon />}
            text={moment(meeting?.beginTime).format('YYYY/MM/DD')}
          />
          <InfoItem
            icon={<ScheduleIcon />}
            text={`${moment(meeting?.beginTime).format('hh:mm A')} - ${moment(
              meeting?.endTime
            ).format('hh:mm A')}`}
          />
        </div>
        <div className={classes.buttonWrapper}>
          {/* {isMobile ? (
            <IconOnlyButton onClick={() => setIsMeetingInfoOpen(true)}>
              <VisibilityIcon />
            </IconOnlyButton>
          ) : (
            <Button
              variant="outlined"
              label={intl.formatMessage({
                id: 'view_details',
              })}
              onClick={() => setIsMeetingInfoOpen(true)}
            />
          )} */}
          {isInstant && (
            <Button
              variant="contained"
              color="primary"
              label={intl.formatMessage({
                id: 'rejoin',
              })}
              onClick={() => joinToMeetingButtonClick(meeting)}
            />
          )}
          {meeting?.meetingType === 'past' &&
            !isInstant &&
            !isLivestreaming &&
            !isScheduledEvent && (
              <Button
                variant="contained"
                color="primary"
                label={intl.formatMessage({
                  id: 'change',
                })}
                onClick={() => openEditMeetingModal(meeting)}
              />
            )}
          {meeting?.meetingType === 'ongoing' &&
            !isInstant &&
            !isLivestreaming &&
            !isScheduledEvent && (
              <>
                <IconOnlyButton onClick={() => setIsShareMeetingOpen(true)}>
                  <ShareIcon />
                </IconOnlyButton>
                <Button
                  variant="contained"
                  color="primary"
                  label={intl.formatMessage({
                    id: 'join',
                  })}
                  onClick={() => joinToMeetingButtonClick(meeting)}
                />
              </>
            )}
          {meeting?.meetingType === 'upcoming' &&
            !isInstant &&
            !isLivestreaming &&
            !isScheduledEvent && (
              <>
                <IconOnlyButton onClick={() => setIsDeleteMeetingOpen(true)}>
                  <DeleteForeverRoundedIcon />
                </IconOnlyButton>
                <Button
                  variant="contained"
                  color="primary"
                  label={intl.formatMessage({
                    id: 'launch',
                  })}
                  onClick={() => joinToMeetingButtonClick(meeting)}
                />
              </>
            )}

          {/* LiveStreaming */}
          {meeting?.meetingType === 'upcoming' &&
            (isLivestreaming || isScheduledEvent) && (
              <IconOnlyButton onClick={_onClickDeleteButton}>
                <DeleteForeverRoundedIcon />
              </IconOnlyButton>
            )}
          {meeting?.meetingType === 'upcoming' && isLivestreaming && (
            <Button
              variant="contained"
              label={intl.formatMessage({
                id: 'launch',
              })}
              onClick={() => joinToMeetingButtonClick(meeting?.location)}
            />
          )}
          {meeting?.meetingType === 'ongoing' && isLivestreaming && (
            <Button
              variant="contained"
              label={intl.formatMessage({
                id: 'join',
              })}
              onClick={() => joinToMeetingButtonClick(meeting?.location)}
            />
          )}
        </div>
      </div>
      {isDeleteMeetingOpen && (
        <DeleteMeetingModal
          open={isDeleteMeetingOpen}
          meeting={meeting}
          handleClose={() => setIsDeleteMeetingOpen(false)}
          removeMeeting={removeMeeting}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={() => setIsShareMeetingOpen(false)}
          meeting={meeting}
        />
      )}
      <ActionModal
        modalTitle={intl.formatMessage({
          id: 'delete_stream',
        })}
        modalDescription={intl.formatMessage(
          {
            id: 'delete_stream_description',
          },
          {
            name: meetingName,
          }
        )}
        open={openDeleteModal}
        handleClose={_onClickDeleteModalClose}
        disableBackdropClick
        actinButtonOnClick={() => removeMeeting(meeting._id)}
        actinButtonLabel={intl.formatMessage({
          id: 'delete',
        })}
      />
    </MeetingCard>
  );
};

AllMeetingCard.defaultProps = {
  key: '',
};

export default memo(withStyles(styles)(AllMeetingCard));
