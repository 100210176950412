import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  Edit as EditIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Lock as LockIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  PersonAddOutlined as PersonAddOutlinedIcon,
  PersonAddDisabled as PersonAddDisabledIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';
import { CircularProgress, IconButton } from '@material-ui/core';
import styles from './customers.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import {
  AddCustomerModal,
  ChangePasswordModal,
  DeleteCustomerModal,
  PromoteCustomerModal,
  DemoteSubadminModal,
} from './components';
// import EditCustomerModal from './components/editCustomerModal';
import CustomerInfoModal from './components/customerInfoModel';
import SuspendCustomerModal from './components/suspendCustomerModal';
import { ManageCustomer } from '.';
import { IRequestParams } from '../../components/types';
import { getRole } from '../settings/components/profileSettings.view';
import { getUserStatus } from '../../helper/translationFunctions';

interface ICustomersView extends WithStyles<typeof styles> {
  selectCustomer: any;
  customersList: any;
  permissions: any;
  handleAddCustomer: (data: any) => void;
  handleDeleteCustomer: (id: string) => void;
  handleRestCustomerPassword: (data: any) => void;
  handlePromoteCustomer: (data: any) => void;
  handleDemoteSubadmin: (data: any) => void;
  handleEditCustomer: (data: any) => void;
  handleSuspendCustomer: (data: any) => void;
  handleUnsuspendCustomer: (data: any) => void;
  handleGetCustomers: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

const CustomersView: React.FC<ICustomersView> = ({
  classes,
  selectCustomer,
  customersList,
  permissions,
  handleAddCustomer,
  handleDeleteCustomer,
  handleRestCustomerPassword,
  handlePromoteCustomer,
  handleDemoteSubadmin,
  handleEditCustomer,
  handleSuspendCustomer,
  handleUnsuspendCustomer,
  handleGetCustomers,
}: ICustomersView) => {
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openViewCustomerModal, setOpenViewCustomerModal] = useState(false);
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openSuspendCustomerModal, setOpenSuspendCustomerModal] = useState(
    false
  );
  const [openPromoteCustomerModal, setOpenPromoteCustomerModal] = useState(
    false
  );
  const [openDemoteSubadminModal, setOpenDemoteSubadminModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const intl = useIntl();

  const tableHeadings = [
    {
      name: 'profile.name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            minWidth: '200px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            width: '50px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
      },
    },
    {
      name: 'email',
      label: intl.formatMessage({
        id: 'email',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'profile.contactNumber',
      label: intl.formatMessage({
        id: 'contact_number',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: '180px' },
        }),
      },
    },
    {
      name: 'role',
      label: intl.formatMessage({
        id: 'role',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'parentId.profile.name',
      label: intl.formatMessage({
        id: 'managed_by',
      }),
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: '180px' },
        }),
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const _onClickEditCustomer = () => {
    setOpenEditCustomerModal(true);
    setOpenMoreOption(false);
  };

  const getMoreOptionList = (role: string, action: string) => {
    const optionList = [
      {
        label: intl.formatMessage({
          id: 'view',
        }),
        id: 'customer-view',
        onClick: () => setOpenViewCustomerModal(true),
        icon: <VisibilityOutlinedIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'customer-edit',
        onClick: () => _onClickEditCustomer(),
        icon: <EditIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'change_password',
        }),
        id: 'customer-change_password',
        onClick: () => setOpenResetPasswordModal(true),
        icon: <LockIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'delete',
        }),
        id: 'customer-delete',
        onClick: () => setOpenDeleteCustomerModal(true),
        icon: <DeleteOutlinedIcon fontSize="small" />,
      },
    ];

    if (role === 'customer') {
      optionList.push({
        label: intl.formatMessage({
          id: 'promote_to_sub_admin',
        }),
        id: 'customer-promote_to_sub_admin',
        onClick: () => setOpenPromoteCustomerModal(true),
        icon: <ArrowUpwardIcon fontSize="small" />,
      });
    } else if (role === 'subadmin') {
      optionList.push({
        label: intl.formatMessage({
          id: 'demote_to_customer',
        }),
        id: 'customer-demote_to_customer',
        onClick: () => setOpenDemoteSubadminModal(true),
        icon: <ArrowDownwardIcon fontSize="small" />,
      });
    }

    if (action === 'suspend') {
      optionList.push({
        label: intl.formatMessage({
          id: 'suspend',
        }),
        id: 'customer-suspend',
        onClick: () => setOpenSuspendCustomerModal(true),
        icon: <PersonAddDisabledIcon fontSize="small" />,
      });
    } else if (action === 'unsuspend') {
      optionList.push({
        label: intl.formatMessage({
          id: 'unsuspend',
        }),
        id: 'customer-unsuspend',
        onClick: () => setOpenSuspendCustomerModal(true),
        icon: <PersonAddOutlinedIcon fontSize="small" />,
      });
    }

    return optionList;
  };

  const [CustomerOptionList, setCustomerOptionList] = useState(
    getMoreOptionList('none', 'none')
  );

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedCustomer(data);
    const userRole = data.role;
    const userStatus = data.status === 'suspend' ? 'unsuspend' : 'suspend';

    if (data.status === 'pending') {
      setCustomerOptionList(getMoreOptionList('none', 'none'));
    } else if (permissions.promoteCustomers && permissions.suspendCustomers) {
      setCustomerOptionList(getMoreOptionList(userRole, userStatus));
    } else if (!permissions.promoteCustomers && permissions.suspendCustomers) {
      setCustomerOptionList(getMoreOptionList('none', userStatus));
    } else if (permissions.promoteCustomers && !permissions.suspendCustomers) {
      setCustomerOptionList(getMoreOptionList(userRole, 'none'));
    } else if (!permissions.promoteCustomers && !permissions.suspendCustomers) {
      setCustomerOptionList(getMoreOptionList('none', 'none'));
    }

    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    // setAnchorEl(null);
    setOpenMoreOption(false);
    setCustomerOptionList([]);
  };

  const getManagedByPrefix = (role: string) => {
    if (role === 'subadmin') return 'ABU: ';
    if (role === 'customer') return 'AU: ';
    return '';
  };

  const renderTableRow = useCallback(() => {
    return map(customersList.data, (row) => {
      return {
        ...row,
        'profile.name': row.profile.name,
        'profile.contactNumber': row.profile.contactNumber,
        role: intl.formatMessage({
          id: getRole(row.role),
        }),
        'parentId.profile.name': `${getManagedByPrefix(row.parentId.role)}${
          row.parentId.profile.name
        }`,
        status: getUserStatus(intl, row.status, true),
        more: (
          <IconButton
            id="more-option-in-customers"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [customersList]);

  const renderAddCustomerButton = () => {
    return (
      <div>
        <Button
          customStyles={classes.button}
          onClick={() => setOpenAddCustomerModal(true)}
          label={intl.formatMessage({
            id: 'add_customer',
          })}
          id="add-customer-button"
          startIcon={<PersonAddIcon />}
          variant="contained"
          color="primary"
        />
      </div>
    );
  };
  if (!customersList) {
    return (
      <NoDataLayout
        image={emptyUsersViewIcon}
        heading="Add your first customer"
      >
        {renderAddCustomerButton()}
        <AddCustomerModal
          selectCustomer={selectCustomer}
          open={openAddCustomerModal}
          handleClose={() => setOpenAddCustomerModal(false)}
          handleAddCustomer={handleAddCustomer}
        />
      </NoDataLayout>
    );
  }

  return (
    <>
      {openEditCustomerModal ? (
        <ManageCustomer
          selectedCustomer={selectedCustomer}
          allCustomer
          backToCustomers={() => setOpenEditCustomerModal(false)}
        />
      ) : (
        <div className={classes.root}>
          <div className={classes.topWrapper} id="customers-view">
            <Typography variant="h3" component="h2" color="textPrimary">
              <FormattedMessage id="customers" />
            </Typography>
            <div className={classes.buttonWrapper}>
              {renderAddCustomerButton()}
            </div>
          </div>
          <div className={classes.bottomWrapper}>
            <Card customStyles={classes.card}>
              <Table
                customStyles={classes.tableWrapper}
                id="customers-list-table"
                title={
                  <div id="total-customers">
                    <FormattedMessage
                      id="total_customers"
                      values={{ n: customersList.total }}
                    />
                    {selectCustomer.loading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </div>
                }
                columns={tableHeadings}
                data={renderTableRow()}
                pagination
                serverSide
                filter
                count={customersList.total}
                dataRefreshFunc={handleGetCustomers}
              />
            </Card>
          </div>
          <AddCustomerModal
            selectCustomer={selectCustomer}
            open={openAddCustomerModal}
            handleClose={() => setOpenAddCustomerModal(false)}
            handleAddCustomer={handleAddCustomer}
          />
          <DeleteCustomerModal
            selectCustomer={selectCustomer}
            open={openDeleteCustomerModal}
            handleClose={() => setOpenDeleteCustomerModal(false)}
            selectedCustomer={selectedCustomer}
            handleDeleteCustomer={handleDeleteCustomer}
          />
          <ChangePasswordModal
            selectCustomer={selectCustomer}
            open={openResetPasswordModal}
            handleClose={() => setOpenResetPasswordModal(false)}
            selectedCustomer={selectedCustomer}
            handleRestCustomerPassword={handleRestCustomerPassword}
          />
          <PromoteCustomerModal
            selectCustomer={selectCustomer}
            open={openPromoteCustomerModal}
            handleClose={() => setOpenPromoteCustomerModal(false)}
            selectedCustomer={selectedCustomer}
            handlePromoteCustomer={handlePromoteCustomer}
          />
          <DemoteSubadminModal
            selectCustomer={selectCustomer}
            open={openDemoteSubadminModal}
            handleClose={() => setOpenDemoteSubadminModal(false)}
            selectedCustomer={selectedCustomer}
            handleDemoteSubadmin={handleDemoteSubadmin}
          />
          <SuspendCustomerModal
            selectCustomer={selectCustomer}
            open={openSuspendCustomerModal}
            handleClose={() => setOpenSuspendCustomerModal(false)}
            selectedCustomer={selectedCustomer}
            handleSuspendCustomer={handleSuspendCustomer}
            handleUnsuspendCustomer={handleUnsuspendCustomer}
          />
          <CustomerInfoModal
            open={openViewCustomerModal}
            handleClose={() => setOpenViewCustomerModal(false)}
            selectedCustomer={selectedCustomer}
            showParent
          />
          <Dropdown
            id="customer-more-option-button"
            open={Boolean(selectedCustomer) && openMoreOption}
            anchorRef={anchorEl}
            handleClose={handleCloseMoreOption}
            itemList={CustomerOptionList}
          />
        </div>
      )}
    </>
  );
};

export default memo(withStyles(styles)(CustomersView));
