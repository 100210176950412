/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-plusplus */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { TextField, Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { Checkbox } from '../../../components/Checkbox';
import { isNumeric } from '../../../helper/validation';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface ISelectDeviceModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleSelectDevice: (data: any) => void;
  selectDevice: any;
}

const SelectDeviceModal = ({
  classes,
  open,
  handleClose,
  handleSelectDevice,
  selectDevice,
}: ISelectDeviceModalProps) => {
  const [deviceId, setDeviceId] = useState({ error: false, value: '' });
  const [privacyTimeout, setPrivacyTimeout] = useState({
    error: false,
    value: '',
  });
  const [privacyEnabled, setPrivacyEnabled] = useState({
    error: false,
    value: false,
  });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectDevice.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!deviceId.value || deviceId.error) {
      isValidate = false;
      setDeviceId({ ...deviceId, error: true });
    }
    if (!privacyTimeout.value || privacyTimeout.error) {
      isValidate = false;
      setPrivacyTimeout({ ...privacyTimeout, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const deviceData = {
        deviceId: deviceId.value,
        privacyTimeout: privacyTimeout.value,
        privacyEnabled: privacyEnabled.value,
      };
      handleSelectDevice(deviceData);
    }
  };

  const deviceIdOnChange = (
    event: ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    setDeviceId({
      value: value.id,
      error: value.length === 0,
    });
  };
  const privacyTimeoutOnChange = (e: { target: { value: string } }) => {
    const error = !isNumeric(e.target.value);
    setPrivacyTimeout({ value: e.target.value, error });
  };
  const privacyEnabledOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPrivacyEnabled({ value: checked, error: false });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'add_device',
      })}
      id="select-device-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'continue',
      })}
    >
      <AutocompleteTextField
        multiple={false}
        limitTags={1}
        id="select-device-dropdown"
        options={selectDevice.listRemote}
        getOptionLabel={(option: any) => option.deviceName}
        onChange={deviceIdOnChange}
        label={intl.formatMessage({
          id: 'devices',
        })}
        placeholder={intl.formatMessage({
          id: 'devices',
        })}
      />
      <TextField
        id="select-device-privacy-timeout"
        error={privacyTimeout.error}
        placeholder={intl.formatMessage({
          id: 'privacy_timeout',
        })}
        required
        onChange={privacyTimeoutOnChange}
        helperText={
          privacyTimeout.error &&
          intl.formatMessage({
            id: 'please_insert_valid_privacy_timeout',
          })
        }
      />
      <Checkbox
        id="select-device-privacy-enabled"
        checked={privacyEnabled.value}
        onChange={privacyEnabledOnChange}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(SelectDeviceModal));
