/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addGroupAsync,
  deleteGroupAsync,
  deleteGroupsAsync,
  editGroupAsync,
  getGroupsAsync,
  selectGroups,
} from './groups.slice';
import { selectAuth } from '../auth/auth.slice';
import GroupsView from './groups.view';
import { fetchMembersAsync, selectMembers } from '../members/members.slice';
import { fetchDevicesAsync, selectDevices } from '../devices/devices.slice';
import { IRequestParams } from '../../components/types';
import { IAddGroupData, IEditGroupData } from './groups.api';
import { actions as appActions } from '../app/app.slice';

const GroupsContainer: React.FC<unknown> = () => {
  const groups = useAppSelector(selectGroups);
  const members = useAppSelector(selectMembers);
  const auth = useAppSelector(selectAuth);
  const devices = useAppSelector(selectDevices);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGroupsAsync({}));
    dispatch(fetchMembersAsync({ limit: 500 }));
    dispatch(fetchDevicesAsync({ limit: 500 }));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10117'));
  }, []);

  const handleAddGroup = (data: IAddGroupData) => {
    dispatch(
      addGroupAsync({ ...data, nextApiCallType: 'getGroupsByCustomer' })
    );
  };

  const handleDeleteGroup = (id: string) => {
    dispatch(deleteGroupAsync(id));
  };

  const handleDeleteGroups = (ids: string[]) => {
    dispatch(deleteGroupsAsync(ids));
  };

  const handleEditGroup = (data: IEditGroupData) => {
    dispatch(editGroupAsync(data));
  };

  const handleGetGroups = (data: IRequestParams) => {
    dispatch(getGroupsAsync(data));
  };

  return (
    <GroupsView
      selectGroup={groups}
      selectMember={members}
      selectDevice={devices}
      groupsList={groups.list}
      permissions={auth.permissions}
      handleAddGroup={handleAddGroup}
      handleDeleteGroup={handleDeleteGroup}
      handleDeleteGroups={handleDeleteGroups}
      handleEditGroup={handleEditGroup}
      handleGetGroups={handleGetGroups}
    />
  );
};

export default memo(GroupsContainer);
