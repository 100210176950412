import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export const userSignUpApi = (data: any): Promise<AxiosResponse> => {
  return api.post('account/login', data);
};

export const getUserProfilepApi = (): Promise<AxiosResponse> => {
  return api.get('account/profile');
};

export const updateUserProfileApi = (data: any): Promise<AxiosResponse> => {
  return api.post('account/profile', data);
};

export const updateUserPasswordApi = (data: any): Promise<AxiosResponse> => {
  return api.post('account/password', data);
};

export const ForgotPasswordApi = (data: any): Promise<AxiosResponse> => {
  return api.post('account/forgotpassword', data);
};
