/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchAllMeetingsAsync } from '../meetings/meetings.slice';
import {
  instantMeetingAsync,
  actions as scheduleMeetingActions,
  selectSchesuleMeeting,
} from '../scheduleMeeting/scheduleMeeting.slice';
import SpeedDialsView from './speedDials.view';
import { generateMeetingUrl } from '../../helper/conference';
import {
  editDevicesAsync,
  fetchDevicesAsync,
  selectDevices,
  actions as devicesActions,
} from '../devices/devices.slice';
import { actions as appActions } from '../app/app.slice';
import { IGetDevicesArgs } from '../devices/devices.api';

export interface IDashboardContainer {}

const DashboardContainer: React.FC<IDashboardContainer> = ({}: IDashboardContainer) => {
  const scheduleMeeting = useAppSelector(selectSchesuleMeeting);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const devices = useAppSelector(selectDevices);

  useEffect(() => {
    if (scheduleMeeting.success && scheduleMeeting.isSpeeddialInprogress) {
      dispatch(scheduleMeetingActions.closeSpeeddial());
      const url = generateMeetingUrl(scheduleMeeting.data?.location, false);
      history.push(url);
    }
  }, [scheduleMeeting.success]);

  useEffect(() => {
    if (
      scheduleMeeting.meetingLocation &&
      scheduleMeeting.isSpeeddialInprogress
    ) {
      dispatch(scheduleMeetingActions.closeSpeeddial());
      const url = generateMeetingUrl(scheduleMeeting.meetingLocation, false);
      dispatch(scheduleMeetingActions.setMeetingLocation(''));
      history.push(url);
    }
  }, [scheduleMeeting.meetingLocation]);

  useEffect(() => {
    dispatch(fetchAllMeetingsAsync(null));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDevicesAsync({
        limit: 500,
        sortOrder: { name: 'favourite', direction: 'desc' },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10101'));
  }, []);

  const _handleSpeeddial = (data: any) => {
    dispatch(scheduleMeetingActions.openSpeeddial());
    dispatch(instantMeetingAsync(data));
  };

  const _handleSpeeddialJoinCall = (meetingLocation: string) => {
    dispatch(scheduleMeetingActions.openSpeeddial());
    dispatch(scheduleMeetingActions.setMeetingLocation(meetingLocation));
  };

  const handleGetDevices = (data: IGetDevicesArgs) => {
    dispatch(fetchDevicesAsync(data));
  };

  const handleEditDevice = (data: any) => {
    dispatch(editDevicesAsync(data));
  };

  const _handleOpenDeviceViewModal = useCallback(() => {
    dispatch(devicesActions.openDeviceViewModal());
  }, [dispatch, devices.openDeviceViewModal]);

  const _handleCloseDeviceViewModal = useCallback(() => {
    dispatch(devicesActions.closeDeviceViewModal());
  }, [dispatch, devices.openDeviceViewModal]);

  return (
    <>
      <SpeedDialsView
        handleSpeeddial={_handleSpeeddial}
        devices={devices}
        devicesList={devices.list}
        handleSpeeddialJoinCall={_handleSpeeddialJoinCall}
        handleGetDevices={handleGetDevices}
        handleEditDevice={handleEditDevice}
        handleViewmodalOpen={_handleOpenDeviceViewModal}
        handleViewmodalClose={_handleCloseDeviceViewModal}
      />
    </>
  );
};

export default memo(DashboardContainer);
