/* eslint-disable no-unused-vars */
import React from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';

import BasicSwitch, { IBasicSwitchProps } from '../Switch/BasicSwitch';
import SettingsItem from './SettingsItem';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
    },
  });
};

export interface ISettingsSwitchProps
  extends WithStyles<IBasicSwitchProps & typeof styles> {
  id: string;
  label: string;
  helperText?: string;
  checked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const settingsSwitch: React.FC<ISettingsSwitchProps> = ({
  classes,
  id,
  checked = true,
  onChange,
  label,
  helperText = '',
  ...otherProps
}: ISettingsSwitchProps) => {
  return (
    <SettingsItem
      id={id}
      label={label}
      helperText={helperText}
      control={
        <BasicSwitch checked={checked} onChange={onChange} {...otherProps} />
      }
    />
  );
};

export default withStyles(styles)(settingsSwitch);
