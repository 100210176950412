/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReplaystreamingView from './replaystreaming.view';
import {
  connectYoutubeAsync,
  disconnectYoutubeAsync,
  readReplaystreamingsAsync,
  getYoutubeStatusAsync,
  selectReplaystreaming,
  actions as replaystreamingActions,
  deleteReplaystreamingAsync,
} from './replaystreaming.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../auth/auth.slice';
import { generateReplaystreamingUrl } from '../../helper/conference';
import { actions as appActions } from '../app/app.slice';
import { ScheduleReplayStreamingModal } from './component/scheduleReplayStreaming';
import { IRequestParams } from '../../components/types';

const ReplaystreamingContainer: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const replaystreaming = useAppSelector(selectReplaystreaming);
  const auth = useAppSelector(selectAuth);
  const history = useHistory();

  const [upcomingMeetings, setUpcomingMeetings] = useState<Array<any>>([]);
  const [ongoingMeetings, setOngoingMeetings] = useState<Array<any>>([]);
  const [recentMeetings, setRecentMeetings] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10122'));
  }, []);

  useEffect(() => {
    if (auth?.user?.role !== 'member') {
      dispatch(getYoutubeStatusAsync());
    }
  }, []);

  useEffect(() => {
    dispatch(readReplaystreamingsAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();

    const past = replaystreaming?.list
      ?.filter(({ beginTime }) => beginTime <= now)
      .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    const all = replaystreaming?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const ongoingMeetingsList = all
      ?.filter(
        (meeting) =>
          meeting.meetingType === 'ongoing' && meeting.type !== 'instant'
      )
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const upcomingMeetingsList = all
      ?.filter(
        (meeting) =>
          meeting.meetingType === 'upcoming' && meeting.type !== 'instant'
      )
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));

    setRecentMeetings(past);
    setUpcomingMeetings(upcomingMeetingsList);
    setOngoingMeetings(ongoingMeetingsList);
  }, [replaystreaming.list]);

  const _scheduleMeetingOnClick = () => {
    dispatch(replaystreamingActions.openCreateReplaystreamingModal());
  };

  const _joinToMeetingButtonClick = (location: string) => {
    const url = generateReplaystreamingUrl(location, false);
    history.push(url);
  };

  const _handleSearch = (data: IRequestParams) => {
    dispatch(readReplaystreamingsAsync(data));
  };

  const _openUpdateReplaystreamingModal = (data: any) => {
    dispatch(replaystreamingActions.openUpdateReplaystreamingModal(data));
  };

  const _openViewReplaystreamingModal = (data: any) => {
    dispatch(replaystreamingActions.openViewReplaystreamingModal(data));
  };

  const _removeMeeting = (id: string) => {
    dispatch(deleteReplaystreamingAsync(id));
  };

  const connectYoutube = () => {
    dispatch(connectYoutubeAsync());
  };
  const disconnectYoutube = () => {
    dispatch(disconnectYoutubeAsync());
  };
  return (
    <>
      <ReplaystreamingView
        selectStream={replaystreaming}
        upcomingMeetings={upcomingMeetings}
        ongoingMeetings={ongoingMeetings}
        recentMeetings={recentMeetings}
        permissions={auth.permissions}
        connectYoutube={connectYoutube}
        disconnectYoutube={disconnectYoutube}
        removeMeeting={_removeMeeting}
        joinToMeetingButtonClick={_joinToMeetingButtonClick}
        scheduleMeetingOnClick={_scheduleMeetingOnClick}
        handleSearch={_handleSearch}
        openUpdateReplaystreamingModal={_openUpdateReplaystreamingModal}
        openViewReplaystreamingModal={_openViewReplaystreamingModal}
      />

      <ScheduleReplayStreamingModal />
    </>
  );
};

export default memo(ReplaystreamingContainer);
