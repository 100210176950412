import React, { memo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { getUserStatus } from '../../../helper/translationFunctions';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface ICustomerInfoModalProps extends WithStyles<typeof styles> {
  selectedCustomer: any;
  open: boolean;
  showParent: boolean;
  handleClose: () => void;
}

const CustomerInfoModal = ({
  classes,
  selectedCustomer,
  showParent,
  open,
  handleClose,
}: ICustomerInfoModalProps) => {
  const intl = useIntl();

  return (
    <ActionModal
      modalTitle={selectedCustomer?.profile?.name}
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'done',
      })}
      actinButtonOnClick={handleClose}
      isSingleButton
    >
      <Grid container className={classes.userCard}>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="email" /> :&nbsp;
            {selectedCustomer?.email}
          </Typography>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="contact_number" /> :&nbsp;
            {selectedCustomer?.profile?.contactNumber}
          </Typography>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="role" /> :&nbsp;
            {selectedCustomer?.role}
          </Typography>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="status" /> :&nbsp;
            {getUserStatus(intl, selectedCustomer?.status, true)}
          </Typography>
          {showParent ? (
            <Typography variant="body1" color="secondary">
              <FormattedMessage id="managed_by" /> :&nbsp;
              {selectedCustomer?.parentId?.profile?.name}
            </Typography>
          ) : (
            ''
          )}
          {/* <Typography variant="body1" color="secondary">
            <FormattedMessage id="participants" /> : &nbsp;
            {meeting?.participantCount}
          </Typography> */}
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(CustomerInfoModal));
