import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIntl } from 'react-intl';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
// import RecentMeetingCard from './recentMeetingCard';
// import UpcomingMeetingCard from './upcomingMeetingCard';
// import OngoingMeetingCard from './ongoingMeetingCard';
import { useHistory } from 'react-router-dom';
import { TableSearch } from '../../../components/Search';
import { IRequestParams } from '../../../components/types';
import { Button, IconButton } from '../../../components/Button';
import AllMeetingCard from './allMeetingCard';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    appbar: {
      backgroundColor: theme.palette.grey[100],
      boxShadow: 'none',
      borderRadius: 8,
      zIndex: 100,
    },
    tabsHeader: {
      color: theme.palette.secondary.main,
      minHeight: 'unset',

      '& .MuiTab-root': {
        textTransform: 'capitalize',
        fontFamily: 'PT Sans',
        fontSize: theme.typography.h6.fontSize,
        minWidth: '80px !important',
        '@media(max-width: 600px)': {
          fontSize: 12,
        },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiTabs-fixed': {
        padding: '3px',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },

      '& .Mui-selected': {
        backgroundColor: theme.palette.common.white,
        borderRadius: 6,
        boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.12)',

        '& .MuiTab-wrapper': {
          background: theme.custom.primaryGradient,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: theme.typography.h6.fontSize,
          fontWeight: 700,
          border: 'none !important',
          '@media(max-width: 600px)': {
            fontSize: 12,
          },
        },
      },
    },
    borderRight: {
      '& .MuiTab-wrapper': {
        borderRight: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    borderLeft: {
      '& .MuiTab-wrapper': {
        borderLeft: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    TabPanelContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        height: 1000,
      },
      [theme.breakpoints.down('xs')]: {
        // height: 'auto',
      },
      '@media(max-width: 600px)': {
        height: 1200,
      },
    },
    tabPanelWrapper: {
      overflow: 'auto',
      flexGrow: 1,
    },
    tabPanel: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tabPanelHeadar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px 0 16px',
      minHeight: 40,
    },
    tabPanelTitle: {
      fontFamily: 'PT Sans',
      background: theme.custom.primaryGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        // fontSize: 14,
      },
    },
    searchButton: {
      padding: 5,
      color: theme.palette.secondary.main,
      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
    },
    meetingsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      overflow: 'auto',
      flexGrow: 1,
      // [theme.breakpoints.down('xs')]: {
      //   maxHeight: '105vh',
      // },
    },
    paginationWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingTop: '24px',
    },
    paginationText: {
      marginRight: '26px',
      fontSize: '12px',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    arrowButton: {
      margin: 0,
      '& .MuiButton-root': {
        minWidth: '40px',
        color: theme.palette.secondary.main,
      },
      '& .Mui-disabled': {
        color: '#00000061',
      },
      '& :hover': {
        backgroundColor: 'unset',
      },
    },
    tableSearch: {
      position: 'absolute',
      top: 80,
      right: 0,
    },
    cardsWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      overflow: 'auto',
    },
    viewMoreButtonWrapper: {
      display: 'flex',
      justifyContent: 'end',
      '& .basic-button-class': {
        padding: 0,
      },
    },
  });
};

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  title: any;
}

interface ITabViewMeetingsProps extends WithStyles<typeof styles> {
  // upcomingMeetings: Array<any>;
  // ongoingMeetings: Array<any>;
  // recentMeetings: Array<any>;
  scheduleMeetingOnClick: () => void;
  joinToMeetingButtonClick: (data: any) => void;
  openEditMeetingModal: (data: any) => void;
  handleSearch: (data: IRequestParams) => void;
  removeMeeting: (id: string) => void;
  allMeetings: Array<any>;

  //
  // Live Streaming ---------------------
  allStreamings: Array<any>;
  removeStreaming: (id: string) => void;
  joinToStreamingButtonClick: (data: any) => void;
  scheduleStreamingOnClick: () => void;
  handleSearchStreaming: (data: IRequestParams) => void;
  openUpdateLivestreamingModal: (data: any) => void;
  openViewLivestreamingModal: (data: any) => void;

  //
  // ScheduledEvents---------------------
  allScheledEvents: Array<any>;
  removeScheduledEvent: (id: string) => void;
  joinScheduledEventOnClick: (data: any) => void;
  createScheduledEventOnClick: () => void;
  handleSearchScheduledEvent: (data: IRequestParams) => void;
  openUpdateScheduledEventModal: (data: any) => void;
  openViewScheduledEventModal: (data: any) => void;
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabViewMeetings = ({
  classes,
  // upcomingMeetings,
  // ongoingMeetings,
  // recentMeetings,
  // scheduleMeetingOnClick,
  joinToMeetingButtonClick,
  openEditMeetingModal,
  handleSearch,
  removeMeeting,
  allMeetings,

  //
  // LiveStreaming
  allStreamings,
  removeStreaming,
  joinToStreamingButtonClick,
  scheduleStreamingOnClick,
  handleSearchStreaming,
  openUpdateLivestreamingModal,
  openViewLivestreamingModal,

  //
  // ScheduledEvents
  allScheledEvents,
  removeScheduledEvent,
  joinScheduledEventOnClick,
  createScheduledEventOnClick,
  handleSearchScheduledEvent,
  openUpdateScheduledEventModal,
  openViewScheduledEventModal,
}: ITabViewMeetingsProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
    setValue(newValue);
    setCurrentPage(1);
    // switch (newValue) {
    //   case 0:
    //     setTotalItems(recentMeetings?.length);
    //     break;
    //   case 1:
    //     setTotalItems(ongoingMeetings?.length);
    //     break;
    //   case 2:
    //     setTotalItems(upcomingMeetings?.length);
    //     break;
    //   default:
    //     setTotalItems(recentMeetings?.length);
    // }
  };

  const [value, setValue] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(allMeetings?.length);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [lastItemIndex, setLastItemIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  const isMobile = window.innerWidth < 600;

  useEffect(() => {
    switch (value) {
      case 0:
        setTotalItems(allMeetings?.length);
        break;
      case 1:
        setTotalItems(allStreamings?.length);
        break;
      case 2:
        setTotalItems(allScheledEvents?.length);
        break;
      default:
        setTotalItems(allMeetings?.length);
    }
  }, [value, allMeetings, allStreamings, allScheledEvents]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const TabPanelContainer = document.getElementById('tab-panel-container');
    const TabPanelContainerHeight = TabPanelContainer?.offsetHeight || 0;

    // calItemCount = panel height - title height - pagination height) / item height + gap
    const calItemCount = Math.floor((TabPanelContainerHeight - 40 - 56) / 213);

    setItemsPerPage(calItemCount || 1);

    if (isMobile) {
      setItemsPerPage(3);
    }
  }, [value]);

  useEffect(() => {
    if (totalItems > 0) {
      setFirstItemIndex((currentPage - 1) * itemsPerPage);
      setLastItemIndex(currentPage * itemsPerPage);
    } else {
      setFirstItemIndex(-1);
      setLastItemIndex(0);
    }
  }, [currentPage, totalItems, value, itemsPerPage]);

  const slicedData = (data: any) => {
    const newArray = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    return newArray;
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, title, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className={classes.tabPanelWrapper}
      >
        {value === index && (
          <div className={classes.tabPanel}>
            <div className={classes.tabPanelHeadar}>
              <Typography
                variant="caption"
                component="h3"
                className={classes.tabPanelTitle}
              >
                {title}
              </Typography>
            </div>
            <div className={classes.meetingsWrapper}>{children}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabsHeader}
          aria-label="simple tabs"
          variant="fullWidth"
          centered
        >
          <Tab
            label={intl.formatMessage({
              id: 'meetings',
            })}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage({
              id: 'streamings',
            })}
            {...a11yProps(1)}
            className={clsx(
              value === 0 && classes.borderRight,
              value === 2 && classes.borderLeft
            )}
          />
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'events' : 'scheduled_events',
            })}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <div className={classes.TabPanelContainer} id="tab-panel-container">
        {value === 0 && (
          <TabPanel
            title={intl.formatMessage({
              id: 'meetings',
            })}
            index={0}
          >
            <div className={classes.tableSearch}>
              <TableSearch handleSearch={handleSearch} />
            </div>
            <div className={classes.cardsWrapper}>
              {slicedData(allMeetings).map((meeting: any, index: any) => {
                return (
                  <AllMeetingCard
                    key={index}
                    meeting={meeting}
                    openEditMeetingModal={openEditMeetingModal}
                    joinToMeetingButtonClick={joinToMeetingButtonClick}
                    removeMeeting={removeMeeting}
                  />
                );
              })}
            </div>
            <div className={classes.viewMoreButtonWrapper}>
              <Button
                color="primary"
                id="recent-meeting-table-more"
                variant="text"
                label={intl.formatMessage({
                  id: 'view_more',
                })}
                onClick={() => history.push('/meetings')}
              />
            </div>
          </TabPanel>
        )}
        {value === 1 && (
          <TabPanel
            title={intl.formatMessage({
              id: 'streamings',
            })}
            index={1}
          >
            <div className={classes.tableSearch}>
              <TableSearch handleSearch={handleSearchStreaming} />
            </div>
            <div className={classes.cardsWrapper}>
              {slicedData(allStreamings).map((streaming: any, index: any) => {
                return (
                  <AllMeetingCard
                    key={index}
                    meeting={streaming}
                    openEditMeetingModal={openUpdateLivestreamingModal}
                    joinToMeetingButtonClick={joinToStreamingButtonClick}
                    removeMeeting={removeStreaming}
                  />
                );
              })}
            </div>
            <div className={classes.viewMoreButtonWrapper}>
              <Button
                color="primary"
                id="recent-meeting-table-more"
                variant="text"
                label={intl.formatMessage({
                  id: 'view_more',
                })}
                onClick={() => history.push('/livestreaming')}
              />
            </div>
          </TabPanel>
        )}
        {value === 2 && (
          <TabPanel
            title={intl.formatMessage({
              id: 'scheduled_events',
            })}
            index={2}
          >
            <div className={classes.tableSearch}>
              <TableSearch handleSearch={handleSearchScheduledEvent} />
            </div>
            <div className={classes.cardsWrapper}>
              {slicedData(allScheledEvents).map((event: any, index: any) => {
                return (
                  <AllMeetingCard
                    key={index}
                    meeting={event}
                    openEditMeetingModal={openUpdateScheduledEventModal}
                    joinToMeetingButtonClick={joinScheduledEventOnClick}
                    removeMeeting={removeScheduledEvent}
                  />
                );
              })}
            </div>
            <div className={classes.viewMoreButtonWrapper}>
              <Button
                color="primary"
                id="recent-meeting-table-more"
                variant="text"
                label={intl.formatMessage({
                  id: 'view_more',
                })}
                onClick={() => history.push('/scheduledevents')}
              />
            </div>
          </TabPanel>
        )}
        {/* <div className={classes.paginationWrapper}>
          <div className={classes.paginationText}>
            {itemsPerPage < 2
              ? firstItemIndex + 1
              : `${firstItemIndex + 1} - ${lastItemIndex}`}{' '}
            of {totalItems}
          </div>
          <div className={classes.buttonWrapper}>
            <IconButton
              id="ffff"
              icon={<ArrowBackIosIcon />}
              variant="text"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={firstItemIndex === 0 || !totalItems}
              customStyles={classes.arrowButton}
            />
            <IconButton
              id="ffff"
              icon={<ArrowForwardIosIcon />}
              variant="text"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={totalItems <= lastItemIndex}
              customStyles={classes.arrowButton}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(TabViewMeetings));
