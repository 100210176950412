/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ForgotPasswordView from './forgotpassword.view';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ForgotPasswordAsync, selectAuth } from './auth.slice';
import { actions as appActions } from '../app/app.slice';

export interface IForgotPasswordContainer {}

const ForgotPasswordContainer: React.FC<IForgotPasswordContainer> = ({}: IForgotPasswordContainer) => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (auth.isAuthenticated) history.push('/');
  }, [auth.isAuthenticated]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10121'));
  }, []);

  const handleForgotPassword = (data: any) => {
    dispatch(ForgotPasswordAsync(data));
  };
  const handleCancel = (data: any) => {
    history.push('/signin');
  };
  return (
    <ForgotPasswordView
      handleForgotPassword={handleForgotPassword}
      handleCancel={handleCancel}
      forgotPasswordSuccess={auth.forgotPasswordSuccess}
    />
  );
};

export default memo(ForgotPasswordContainer);
