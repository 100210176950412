import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  Edit as EditIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Lock as LockIcon,
  PersonAddDisabled as PersonAddDisabledIcon,
  PersonAddOutlined as PersonAddOutlinedIcon,
} from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';
import { CircularProgress, IconButton } from '@material-ui/core';
import styles from './users.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import AddUsersModal from './components/addUsersModal';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import { ChangePasswordModal, DeleteUserModal } from './components';
import EditUserModal from './components/editUserModal';
import SuspendUserModal from './components/suspendUserModal';
import UserInfoModal from './components/userInfoModal';
import { IRequestParams } from '../../components/types';
import { getUserStatus } from '../../helper/translationFunctions';

interface IUsersView extends WithStyles<typeof styles> {
  selectUser: any;
  usersList: any;
  handleAddUser: (data: any) => void;
  handleDeleteUser: (id: string) => void;
  handleRestUserPassword: (data: any) => void;
  handleEditUser: (data: any) => void;
  handleSuspendUser: (data: any) => void;
  handleUnsuspendUser: (data: any) => void;
  handleGetUsers: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

const UsersView: React.FC<IUsersView> = ({
  classes,
  selectUser,
  usersList,
  handleAddUser,
  handleDeleteUser,
  handleRestUserPassword,
  handleEditUser,
  handleSuspendUser,
  handleUnsuspendUser,
  handleGetUsers,
}: IUsersView) => {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openSuspendUserModal, setOpenSuspendUserModal] = useState(false);
  const [openViewUserModal, setOpenViewUserModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const intl = useIntl();

  const getUserOptionList = (action: string) => {
    const optionList = [
      // {
      //   label: 'Delete this user',
      //   onClick: () => setOpenDeleteUserModal(true),
      //   icon: <DeleteOutlinedIcon fontSize="small" />,
      // },
      {
        label: intl.formatMessage({
          id: 'view',
        }),
        id: 'user-view',
        onClick: () => setOpenViewUserModal(true),
        icon: <VisibilityOutlinedIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'user-edit',
        onClick: () => setOpenEditUserModal(true),
        icon: <EditIcon fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'change_password',
        }),
        id: 'user-change_password',
        onClick: () => setOpenResetPasswordModal(true),
        icon: <LockIcon fontSize="small" />,
      },
    ];

    if (action === 'suspend') {
      optionList.push({
        label: intl.formatMessage({
          id: 'suspend',
        }),
        id: 'user-suspend',
        onClick: () => setOpenSuspendUserModal(true),
        icon: <PersonAddDisabledIcon fontSize="small" />,
      });
    } else if (action === 'unsuspend') {
      optionList.push({
        label: intl.formatMessage({
          id: 'unsuspend',
        }),
        id: 'user-unsuspend',
        onClick: () => setOpenSuspendUserModal(true),
        icon: <PersonAddOutlinedIcon fontSize="small" />,
      });
    }
    return optionList;
  };

  const [userOptionList, setUserOptionList] = useState(
    getUserOptionList('none')
  );

  const tableHeadings = [
    {
      name: 'profile.name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: intl.formatMessage({
        id: 'email',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedUser(data);
    if (data.status === 'suspend') {
      setUserOptionList(getUserOptionList('unsuspend'));
    } else if (data.status === 'active') {
      setUserOptionList(getUserOptionList('suspend'));
    } else {
      setUserOptionList(getUserOptionList('none'));
    }
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    // setAnchorEl(null);
    setOpenMoreOption(false);
    setUserOptionList([]);
  };
  const renderTableRow = useCallback(() => {
    return map(usersList.data, (row) => {
      return {
        ...row,
        'profile.name': row.profile.name,
        status: getUserStatus(intl, row.status),
        more: (
          <IconButton
            id="more-option-in-users"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [usersList]);

  const renderAddUserButton = () => {
    return (
      <div>
        <Button
          customStyles={classes.button}
          onClick={() => setOpenAddUserModal(true)}
          label={intl.formatMessage({
            id: 'add_admin',
          })}
          id="add-users-button"
          startIcon={<PersonAddIcon />}
          variant="contained"
          color="primary"
        />
      </div>
    );
  };
  if (!usersList) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first user">
        {renderAddUserButton()}
        <AddUsersModal
          selectUser={selectUser}
          open={openAddUserModal}
          handleClose={() => setOpenAddUserModal(false)}
          handleAddUser={handleAddUser}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="users-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          <FormattedMessage id="admins" />
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddUserButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="users-list-table"
            title={
              <div id="total-users">
                <FormattedMessage
                  id="total_admins"
                  values={{ n: usersList.total }}
                />
                {selectUser.loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination
            serverSide
            filter
            count={usersList.total}
            dataRefreshFunc={handleGetUsers}
          />
        </Card>
      </div>
      <AddUsersModal
        selectUser={selectUser}
        open={openAddUserModal}
        handleClose={() => setOpenAddUserModal(false)}
        handleAddUser={handleAddUser}
      />
      <DeleteUserModal
        selectUser={selectUser}
        open={openDeleteUserModal}
        handleClose={() => setOpenDeleteUserModal(false)}
        selectedUser={selectedUser}
        handleDeleteUser={handleDeleteUser}
      />
      <ChangePasswordModal
        selectUser={selectUser}
        open={openResetPasswordModal}
        handleClose={() => setOpenResetPasswordModal(false)}
        selectedUser={selectedUser}
        handleRestUserPassword={handleRestUserPassword}
      />
      <EditUserModal
        selectUser={selectUser}
        open={openEditUserModal}
        handleClose={() => setOpenEditUserModal(false)}
        selectedUser={selectedUser}
        handleEditUser={handleEditUser}
      />
      <UserInfoModal
        open={openViewUserModal}
        handleClose={() => setOpenViewUserModal(false)}
        selectedUser={selectedUser}
      />
      <SuspendUserModal
        selectUser={selectUser}
        open={openSuspendUserModal}
        handleClose={() => setOpenSuspendUserModal(false)}
        selectedUser={selectedUser}
        handleSuspendUser={handleSuspendUser}
        handleUnsuspendUser={handleUnsuspendUser}
      />
      <Dropdown
        id="user-more-option-button"
        open={Boolean(selectedUser) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={userOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(UsersView));
