import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchAllMeetingsApi, removeMeetingApi } from './meetings.api';
import { actions as appActions } from '../app/app.slice';
import { ApiError } from '../../components/types';

export interface IMeetingsState {
  list: Array<any>;
  overview: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IMeetingsState = {
  list: [],
  overview: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchAllMeetingsAsync = createAsyncThunk(
  'meetings/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllMeetingsApi(data);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeMeetingAsync = createAsyncThunk(
  'meetings/removeMeeting',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await removeMeetingApi(data);

      // fetch all the meetings after remove a meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_removed_meeting',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch all meetings
    builder.addCase(fetchAllMeetingsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMeetingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data?.meetings;
      state.overview = action?.payload?.data?.overview;
    });
    builder.addCase(fetchAllMeetingsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // remove a meeting
    builder.addCase(removeMeetingAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = meetingsSlice;

export const selectMeetings = (state: RootState) => state.meetings;

export default meetingsSlice.reducer;
