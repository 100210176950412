import React, { memo } from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      color: theme.palette.secondary.main,
      minHeight: 'unset',

      '& .MuiTab-root': {
        textTransform: 'capitalize',
        fontFamily: 'PT Sans',
        fontSize: theme.typography.h6.fontSize,
        // [theme.breakpoints.down('md')]: {
        //   fontSize: '14px',
        //   minWidth: 'unset',
        // },
        // [theme.breakpoints.down('xs')]: {
        //   fontSize: 12,
        //   minHeight: 36,
        // },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiTabs-fixed': {
        padding: '3px',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },

      '& .Mui-selected': {
        backgroundColor: theme.palette.common.white,
        borderRadius: 6,
        boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.12)',

        '& .MuiTab-wrapper': {
          background: theme.custom.primaryGradient,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: theme.typography.h6.fontSize,
          fontWeight: 700,
          border: 'none !important',
          // [theme.breakpoints.down('md')]: {
          //   fontSize: '14px',
          // },
          // [theme.breakpoints.down('xs')]: {
          //   fontSize: 12,
          // },
        },
      },
    },
  });
};

export interface TabsComponentProps extends WithStyles<typeof styles> {
  id?: string;

  className?: any;
  children: React.ReactNode;
  value: any;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  onChange: any;
  centered?: boolean;
}

export const TabsComponent: React.FC<TabsComponentProps> = ({
  classes,
  className,
  children,
  id,
  ...otherProps
}) => {
  return (
    <Tabs
      {...otherProps}
      className={clsx(classes.root, 'custom-tabs-class', className)}
    >
      {children}
    </Tabs>
  );
};

export default memo(withStyles(styles)(TabsComponent));
