import React, { memo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IDeleteFAQModal extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  faq: any;
  handleClose?: any;
  handleDeleteFAQ: (id: string) => void;
}

const DeleteFAQModal = ({
  classes,
  open,
  faq,
  handleClose,
  handleDeleteFAQ,
}: IDeleteFAQModal) => {
  return (
    <ActionModal
      modalTitle="Do you want to delete this FAQ ?"
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Delete"
      actinButtonOnClick={() => handleDeleteFAQ(faq?._id)}
    >
      <Grid container className={classes.userCard}>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>{faq?.title}</b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {faq?.answer}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteFAQModal));
