import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIntl } from 'react-intl';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import { TableSearch } from '../../../components/Search';
import { IRequestParams } from '../../../components/types';
import EventCard from './eventCard';
import { Tabs, Tab, TabPanel } from '../../../components/Tabs';
import { Pagination } from '../../../components/Pagination';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    appbar: {
      backgroundColor: theme.palette.grey[100],
      boxShadow: 'none',
      borderRadius: 8,
      zIndex: 100,
    },
    borderRight: {
      '& .MuiTab-wrapper': {
        borderRight: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    borderLeft: {
      '& .MuiTab-wrapper': {
        borderLeft: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    TabPanelContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    meetingsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '105vh',
      },
    },
    tableSearch: {
      position: 'absolute',
      top: 96,
      right: 0,
    },
  });
};

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  title: any;
}

interface ITabViewMeetingsProps extends WithStyles<typeof styles> {
  handleSearch: (data: IRequestParams) => void;
  upcomingMeetings: Array<any>;
  ongoingMeetings: Array<any>;
  recentMeetings: Array<any>;
  removeMeeting: (id: string) => void;
  joinToMeetingButtonClick: (data: any) => void;
  openUpdateScheduledEventModal: (data: any) => void;
  openViewScheduledEventModal: (data: any) => void;
  stopMeeting: (id: string) => void;
}

const TabViewMeetings = ({
  classes,
  handleSearch,
  upcomingMeetings,
  ongoingMeetings,
  recentMeetings,
  removeMeeting,
  joinToMeetingButtonClick,
  openUpdateScheduledEventModal,
  openViewScheduledEventModal,
  stopMeeting,
}: ITabViewMeetingsProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
    setValue(newValue);
    setCurrentPage(1);
    switch (newValue) {
      case 0:
        setTotalItems(recentMeetings?.length);
        break;
      case 1:
        setTotalItems(ongoingMeetings?.length);
        break;
      case 2:
        setTotalItems(upcomingMeetings?.length);
        break;
      default:
        setTotalItems(recentMeetings?.length);
    }
  };

  const [value, setValue] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [lastItemIndex, setLastItemIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  // const handlePageChange = (pageNumber: number) => {
  //   setCurrentPage(pageNumber);
  // };

  useEffect(() => {
    setTotalItems(recentMeetings?.length);
  }, [recentMeetings]);

  useEffect(() => {
    const TabPanelContainer = document.getElementById(
      'streaming-tab-panel-container'
    );
    const TabPanelContainerHeight = TabPanelContainer?.offsetHeight || 0;

    // calItemCount = panel height -(title height + pagination height) / item height + gap
    const calItemCount = Math.floor(
      (TabPanelContainerHeight - (40 + 56)) / (112 + 24)
    );

    setItemsPerPage(calItemCount || 1);
  }, [value, window.innerHeight]);

  useEffect(() => {
    if (totalItems > 0) {
      setFirstItemIndex((currentPage - 1) * itemsPerPage);
      setLastItemIndex(currentPage * itemsPerPage);
    } else {
      setFirstItemIndex(-1);
      setLastItemIndex(0);
    }
  }, [currentPage, totalItems, value, itemsPerPage]);

  const slicedData = (data: any) => {
    const newArray = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    return newArray;
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs"
          variant="fullWidth"
          centered
        >
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'recent' : 'recent_scheduled_events',
            })}
            index={0}
          />
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'ongoing' : 'ongoing_scheduled_events',
            })}
            index={1}
            className={clsx(
              value === 0 && classes.borderRight,
              value === 2 && classes.borderLeft
            )}
          />
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'upcoming' : 'upcoming_scheduled_events',
            })}
            index={2}
          />
        </Tabs>
      </AppBar>
      <div className={classes.tableSearch}>
        <TableSearch handleSearch={handleSearch} />
      </div>
      <div
        className={classes.TabPanelContainer}
        id="streaming-tab-panel-container"
      >
        <TabPanel
          title={intl.formatMessage({
            id: 'recent_events',
          })}
          value={value}
          index={0}
          itemsGap="24px"
        >
          {slicedData(recentMeetings).map((recentMeeting: any, index: any) => {
            return (
              <EventCard
                key={index}
                data={recentMeeting}
                type="recent"
                openViewScheduledEventModal={openViewScheduledEventModal}
              />
            );
          })}
        </TabPanel>

        <TabPanel
          title={intl.formatMessage({
            id: 'ongoing_events',
          })}
          value={value}
          index={1}
          itemsGap="24px"
        >
          {slicedData(ongoingMeetings).map(
            (ongoingMeeting: any, index: any) => {
              return (
                <EventCard
                  key={index}
                  data={ongoingMeeting}
                  type="ongoing"
                  joinToMeetingButtonClick={joinToMeetingButtonClick}
                  openViewScheduledEventModal={openViewScheduledEventModal}
                  stopMeeting={stopMeeting}
                />
              );
            }
          )}
        </TabPanel>

        <TabPanel
          title={intl.formatMessage({
            id: 'upcoming_events',
          })}
          value={value}
          index={2}
          itemsGap="24px"
        >
          {slicedData(upcomingMeetings).map(
            (upcomingMeeting: any, index: any) => {
              return (
                <EventCard
                  key={index}
                  data={upcomingMeeting}
                  type="upcoming"
                  removeMeeting={removeMeeting}
                  joinToMeetingButtonClick={joinToMeetingButtonClick}
                  openUpdateScheduledEventModal={openUpdateScheduledEventModal}
                />
              );
            }
          )}
        </TabPanel>

        <Pagination
          firstItemIndex={firstItemIndex}
          itemsPerPage={itemsPerPage}
          lastItemIndex={lastItemIndex}
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(TabViewMeetings));
