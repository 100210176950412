/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  unassignAnyDevicesAsync,
  fetchAllDevicesAsync,
  selectDevices,
  resetAnyDevicesAsync,
  editAnyDevicesAsync,
  fetchDevicesAsync,
} from './devices.slice';
import { selectAuth } from '../auth/auth.slice';
import DevicesView from './alldevices.view';
import { actions as appActions } from '../app/app.slice';
import { IGetDevicesArgs } from './devices.api';

export interface IDevicesContainer {}

const DevicesContainer: React.FC<IDevicesContainer> = ({}: IDevicesContainer) => {
  const devices = useAppSelector(selectDevices);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllDevicesAsync({ limit: 500 }));
  }, []);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10120'));
  }, []);

  const handleDeleteDevice = (data: any) => {
    dispatch(unassignAnyDevicesAsync(data));
  };

  const handleEditDevice = (data: any) => {
    dispatch(editAnyDevicesAsync(data));
  };

  const handleResetDevice = (id: string) => {
    dispatch(resetAnyDevicesAsync(id));
  };

  const handleGetDevices = (data: IGetDevicesArgs) => {
    dispatch(fetchDevicesAsync(data));
  };

  return (
    <DevicesView
      selectDevice={devices}
      devicesList={devices.listAll}
      permissions={auth.permissions}
      handleDeleteDevice={handleDeleteDevice}
      handleResetDevice={handleResetDevice}
      handleEditDevice={handleEditDevice}
      handleGetDevices={handleGetDevices}
    />
  );
};

export default memo(DevicesContainer);
