/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import ContactView from './contactus.view';
import { sendContactusAsync } from './contactus.slice';
import { IContactusData } from './contactus.api';
import { actions as appActions } from '../app/app.slice';

export interface IContactUsContainer {}

const ContactUsContainer: React.FC<IContactUsContainer> = ({}: IContactUsContainer) => {
  const dispatch = useAppDispatch();
  const _handleSendMessage = (data: IContactusData) => {
    dispatch(sendContactusAsync(data));
  };

  useEffect(() => {
    dispatch(appActions.setLocationCode('10114'));
  }, []);

  return (
    <>
      <ContactView handleSendMessage={_handleSendMessage} />
    </>
  );
};

export default memo(ContactUsContainer);
