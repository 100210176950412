import { AxiosResponse } from 'axios';
import api from '../../app/axios';
import { IRequestParams } from '../../components/types';

export interface IGetCustomersArgs extends IRequestParams {
  id?: string;
}

export const fetchCustomerApi = (id: string): Promise<AxiosResponse> => {
  return api.get(`users/customer/${id}`);
};

export const fetchCustomersApi = (
  data: IGetCustomersArgs
): Promise<AxiosResponse> => {
  return api.post('users/getcustomers', data);
};

export const fetchAllCustomersApi = (
  data: IGetCustomersArgs
): Promise<AxiosResponse> => {
  return api.post('users/getallcustomers', data);
};

export const fetchAllSubadminsApi = (id: string): Promise<AxiosResponse> => {
  if (id) {
    return api.get(`users/getallsubadmins/${id}`);
  }
  return api.get('users/getallsubadmins');
};

export const addCustomerApi = (data: any): Promise<AxiosResponse> => {
  return api.post(`users/createcustomer`, data);
};

export const deleteCustomerApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`users/customer/${id}`);
};

export const editCustomerApi = (data: string): Promise<AxiosResponse> => {
  return api.post(`users/customer/update`, data);
};

export const resetCustomerPasswordApi = (data: any): Promise<AxiosResponse> => {
  const { _id, password } = data;
  return api.post(`users/customer/${_id}/password`, { password });
};

export const promoteCustomerApi = (id: string): Promise<AxiosResponse> => {
  return api.post(`users/promotecustomer/${id}`);
};

export const demoteSubadminApi = (id: string): Promise<AxiosResponse> => {
  return api.post(`users/demotesubadmin/${id}`);
};

export const suspendCustomerApi = (id: string): Promise<AxiosResponse> => {
  return api.post(`users/customer/suspend/${id}`);
};

export const unsuspendCustomerApi = (id: string): Promise<AxiosResponse> => {
  return api.post(`users/customer/unsuspend/${id}`);
};
