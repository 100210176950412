import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { TextField } from '../../../components/TextField';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';
import { Switch } from '../../../components/Switch';
import { IDevice } from '../../devices/devices.slice';
import { IRequestParams } from '../../../components/types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    favouriteWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 7,
      marginTop: 24,
      [theme.breakpoints.down('xs')]: {
        marginTop: 12,
      },
    },
    textCapitalize: {
      '& .MuiOutlinedInput-input': {
        textTransform: 'capitalize',
      },
    },
    modal: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: theme.palette.secondary.main,
      },
    },
  });
};

export interface IDeviceModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  device?: IDevice;
  handleClose?: any;
  handleEditDevice: (data: any) => void;
  loading: boolean;
}

const DeviceModal = ({
  classes,
  open,
  handleClose,
  device,
  handleEditDevice,
  loading,
}: IDeviceModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [name, setName] = useState('');
  const [videolinkTv, setVideolinkTv] = useState('');
  const [status, setStatus] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setName(device?.deviceName || '');
    setVideolinkTv(device?.userDeviceName || '');
    setStatus(device?.rebootStatus || '');
    setIsFavorite(device?.favourite || false);
  }, [device]);

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const onClickActionButton = () => {
    const deviceData = {
      deviceId: device?.id,
      name: device?.userDeviceName,
      privacyTimeOut: device?.PrivacyTimeOut,
      privacyEnabled: device?.PrivacyEnabled,
      favourite: isFavorite,
    };

    handleEditDevice(deviceData);
  };
  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'view_speed_dial',
      })}
      modalDescription={
        !isMobile
          ? intl.formatMessage({
              id: 'view_speed_dial_description',
            })
          : ''
      }
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'save',
      })}
      actinButtonOnClick={onClickActionButton}
      actionButtonDisabled={loading}
      customStyles={classes.modal}
    >
      <TextField
        id="name"
        value={name}
        label={intl.formatMessage({
          id: 'name',
        })}
        disabled
      />
      <TextField
        id="tv-name"
        value={videolinkTv}
        customStyles={classes.textCapitalize}
        label={intl.formatMessage({
          id: 'videolink_tv',
        })}
        disabled
      />
      <TextField
        id="spead-dial-status"
        value={status}
        customStyles={classes.textCapitalize}
        label={intl.formatMessage({
          id: 'status',
        })}
        disabled
      />
      <div className={classes.favouriteWrapper}>
        <Switch
          checked={isFavorite}
          onChange={handleToggleFavorite}
          // disabled={selectStream.loading}
        />
        <Typography variant="body1">
          {intl.formatMessage({
            id: 'Mark as a Favourite',
          })}
        </Typography>
      </div>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeviceModal));
