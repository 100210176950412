import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { NoEncryption } from '@material-ui/icons';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topWrapper: {
      marginBottom: theme.spacing(2),
    },
    bottomWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexGrow: 1,
      maxHeight: 'calc(100% - 48px)',
    },
    leftPanel: {
      height: 'auto',
      minWidth: 245,
      padding: 0,
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      '& .Mui-selected': {
        '& .MuiSvgIcon-root': {
          opacity: 1,
        },
      },
      '& .MuiTabs-indicator': {
        height: '0 !important',
      },
    },
    tab: {
      minHeight: 50,
    },
    tabIcon: {
      marginRight: theme.spacing(0.5),
      width: 15,
      height: 15,
      backgroundColor: theme.palette.grey[300],
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    labelWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
      textTransform: 'none',
      textAlign: 'left',
      '& .MuiSvgIcon-root': {
        opacity: 0.5,
      },
    },
    arrow: {
      marginLeft: theme.spacing(1),
    },
    rightPanel: {
      flexGrow: 1,
      height: '100%',
      maxHeight: '100%',
      marginLeft: theme.spacing(2),
      borderRadius: 6,
      overflow: 'auto',
      '& [role*=tabpanel]': {
        height: '100%',
      },
    },
    heading: {
      fontWeight: 600,
    },
    noMobileView: {
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.secondary.dark,
    },
  });
};

export default styles;
