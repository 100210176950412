import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getMeetingByLocationApi } from './replaystreamingRoom.api';
import { actions as appActions } from '../app/app.slice';

export interface IReplaystreamingRoomState {
  roomName: string;
  viewingUrl: string;
  startTime: string;
  endTime: string;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IReplaystreamingRoomState = {
  roomName: '',
  viewingUrl: '',
  startTime: '',
  endTime: '',
  loading: false,
  success: false,
  error: null,
};

export const getMeetingByLocationAsync = createAsyncThunk(
  'getMeetingByLocation',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMeetingByLocationApi(data);
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const replaystreamingRoomSlice = createSlice({
  name: 'scheduleMeeting',
  initialState,
  reducers: {
    resetSuccess(state) {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // get Meeting Token
    builder.addCase(getMeetingByLocationAsync.pending, (state, action) => {
      state.roomName = '';
    });
    builder.addCase(getMeetingByLocationAsync.fulfilled, (state, action) => {
      state.roomName = action.payload?.data?.name;
      state.viewingUrl = action.payload?.data?.replaystreaming?.viewingUrl;
      state.startTime = action.payload?.data?.beginTime;
      state.endTime = action.payload?.data?.endTime;
    });
    builder.addCase(getMeetingByLocationAsync.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { actions } = replaystreamingRoomSlice;

export const selectReplaystreamingRoom = (state: RootState) =>
  state.replaystreamingRoom;

export default replaystreamingRoomSlice.reducer;
