import { init as initApm } from '@elastic/apm-rum';
import {
  elasticsearchApmServerUrl,
  elasticsearchApmServiceName,
  environment,
} from './config';

const apm = initApm({
  serviceName: elasticsearchApmServiceName,
  serverUrl: elasticsearchApmServerUrl,
  serviceVersion: '',
  environment,
});

export default apm;
