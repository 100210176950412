/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import CreateScheduledEventModal from './createScheduledEventModal.view';
import { ShareMeetingModal } from '../../../meetings/components';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import {
  selectScheduledEvent,
  actions,
  updateScheduledEventAsync,
  createScheduledEventAsync,
} from '../../scheduledEvent.slice';
import { getGroupsAsync, selectGroups } from '../../../group/groups.slice';
import { selectMembers } from '../../../members/members.slice';
import {
  fetchDevicesAsync,
  selectDevices,
} from '../../../devices/devices.slice';
import { IScheduledMeetingData } from '../../scheduledEvent.api';
import { selectAuth } from '../../../auth/auth.slice';

const CreateScheduledEventModalContainer: React.FC<unknown> = () => {
  const scheduledEvent = useAppSelector(selectScheduledEvent);
  const groups = useAppSelector(selectGroups);
  const devices = useAppSelector(selectDevices);
  const member = useAppSelector(selectMembers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);

  const _scheduleMeetinOnClose = useCallback(() => {
    if (scheduledEvent.isUpdateScheduledEvent)
      dispatch(actions.closeEditMeetingModal());
    else dispatch(actions.closeScheduleModal());
  }, [dispatch, scheduledEvent.isUpdateScheduledEvent]);

  useEffect(() => {
    if (
      scheduledEvent.success &&
      scheduledEvent.isScheduleModalOpen &&
      !isShareMeetingOpen
    ) {
      _scheduleMeetinOnClose();
      // setIsShareMeetingOpen(true);
    }
  }, [scheduledEvent.success]);

  useEffect(() => {
    if (auth && auth.user && auth.user.id) {
      if (auth.user.role === 'member' && auth.user.parentId) {
        dispatch(getGroupsAsync({ id: auth.user.parentId._id, limit: 500 }));
        dispatch(fetchDevicesAsync({ limit: 500 }));
      } else {
        dispatch(getGroupsAsync({ limit: 500 }));
        dispatch(fetchDevicesAsync({ limit: 500 }));
      }
    }
  }, [auth]);

  const _saveMeetingBtnClick = (data: IScheduledMeetingData) => {
    if (scheduledEvent.isUpdateScheduledEvent)
      dispatch(updateScheduledEventAsync(data));
    else dispatch(createScheduledEventAsync(data));
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
    dispatch(actions.resetScheduleMeeting());
  };

  return (
    <>
      {scheduledEvent.isScheduleModalOpen && (
        <CreateScheduledEventModal
          open={scheduledEvent.isScheduleModalOpen}
          groups={groups.list}
          devicesList={devices.list.data}
          membersList={member.list.data}
          handleClose={_scheduleMeetinOnClose}
          saveMeetingButtonClick={_saveMeetingBtnClick}
          isUpdateScheduledEvent={scheduledEvent.isUpdateScheduledEvent}
          isCreateScheduledEvent={scheduledEvent.isCreateScheduledEvent}
          isViewScheduledEvent={scheduledEvent.isViewScheduledEvent}
          meeting={scheduledEvent.data}
          loading={scheduledEvent.loading}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={scheduledEvent?.data}
        />
      )}
    </>
  );
};

export default memo(CreateScheduledEventModalContainer);
