import React, { memo } from 'react';
import clsx from 'clsx';
import { Button, ButtonProps, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Alignment, Size, Variant } from '../types';
import { Typography } from '../Typography';

type Color = 'primary' | 'secondary' | string;

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiButton-contained': {
        color: theme.palette.common.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
        },
      },
      '& .MuiButton-outlined': {
        padding: '7px 22px',
      },
      '& .MuiButton-startIcon': {
        // marginRight: 4,
      },
      '& .MuiSvgIcon-root': {
        // fontSize: theme.typography.button.fontSize,
      },
    },
    primary: {
      '& .MuiButton-contained': {
        backgroundImage: theme.custom.primaryGradient,
        '&:hover': {
          backgroundImage: theme.custom.primaryGradientHover,
        },
      },
      '& .MuiButton-outlined': {
        background: theme.custom.primaryGradient,
        color: theme.palette.primary.main,
        borderColor: 'transparent',
        borderWidth: 1,
        '&:hover': {
          background: theme.custom.primaryGradientHover,
          borderColor: 'transparent',
          border: '1px solid',
        },
        '&:focus': {
          background: theme.custom.primaryGradientHover,
          borderColor: 'transparent',
        },
      },
      '& .MuiButton-text': {
        background: theme.custom.primaryGradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        '&:hover': {
          background: theme.custom.primaryGradientHover,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
      '& .Mui-disabled': {
        backgroundImage: 'linear-gradient(270deg, #FFCDEA 0%, #FFD8D9 100%)',
      },
    },
    secondary: {
      '& .MuiButton-contained': {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
      },

      '& .MuiButton-outlined': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderWidth: 1,
      },
      '& .MuiButton-text': {
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.secondary.light,
        },
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[400],
      },
    },
    button: {
      textTransform: 'capitalize',
      padding: '8px 22px',
      boxShadow: 'none',
      borderRadius: 4,
      height: '100%',
    },
    small: {
      padding: '8px 22px',
      // fontSize: theme.typography.body2.fontSize,
    },
    margin: {
      margin: theme.spacing(1),
    },
    alignRight: {
      textAlign: 'right',
    },
    alignCenter: {
      textAlign: 'center',
    },
    round: {
      borderRadius: 1000,
    },
  });
};

export interface ButtonComponentProps
  extends WithStyles<ButtonProps & typeof styles> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  id?: string;
  label?: string;
  fullWidth?: boolean;
  color?: Color;
  variant?: Variant;
  roundBorder?: boolean;
  disabled?: boolean;
  customStyles?: string;
  align?: Alignment;
  size?: Size;
  startIcon?: React.ReactNode;
}

export const BasicButton: React.FC<ButtonComponentProps> = ({
  classes,
  onClick,
  id,
  label = 'Test Button',
  fullWidth = false,
  roundBorder = false,
  disabled = false,
  align = 'left',
  variant,
  customStyles,
  size,
  color = 'secondary',
  startIcon,
  ...otherProps
}: ButtonComponentProps) => {
  return (
    <div
      className={clsx(
        classes.root,
        'basic-button-wrapper',
        color === 'primary' ? classes.primary : classes.secondary,
        align === 'center' && classes.alignCenter,
        align === 'right' && classes.alignRight,
        customStyles
      )}
    >
      <Button
        className={clsx(
          classes.button,
          roundBorder && classes.round,
          size === 'small' && classes.small,
          'basic-button-class'
        )}
        onClick={onClick}
        id={id}
        startIcon={startIcon}
        disabled={disabled}
        fullWidth={fullWidth}
        variant={variant}
        {...otherProps}
      >
        <Typography variant="button">{label}</Typography>
      </Button>
    </div>
  );
};

export default memo(withStyles(styles)(BasicButton));
