import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topWrapper: {
      width: '100%',
      marginBottom: '2vh',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    bottomWrapper: {
      position: 'relative',
      flexGrow: 1,
      overflow: 'auto',
    },
    card: {
      height: '100%',
      paddingTop: 0,
      paddingBottom: 5,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 5,
      },
    },
    title: {
      borderBottom: '1px solid #00000038',
      width: '100%',
      padding: '16px 0',
    },
    table: {
      // position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
      '& .MuiTableBody-root .MuiTableRow-root': {
        cursor: 'pointer',
      },
    },
    lightText: {
      color: '#7C76B0',
    },
  });
};

export default styles;
