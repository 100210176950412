import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError, IbaseDoc, IResponseParams } from '../../components/types';
import { RootState } from '../../app/store';
import {
  IScheduledMeetingData,
  connectYoutubeApi,
  disconnectYoutubeApi,
  readReplaystreamingsApi,
  getYoutubeStatusApi,
  deleteReplaystreamingsApi,
  createReplaystreamingApi,
  updateReplaystreamingApi,
  getLocalLivestreamingsApi,
} from './replaystreaming.api';
import { actions as appActions } from '../app/app.slice';
import {
  IScheduleMeetings,
  emptyScheduleMeeting,
} from '../scheduleMeeting/scheduleMeeting.slice';

export interface IStreamingUrl extends IbaseDoc {
  id: string;
  name: string;
  livestreaming: {
    broadcastId: string;
    viewingUrl: string;
  };
}

export interface IReplaystreamingState {
  data: IScheduleMeetings;
  list: Array<any>;
  overview: any;
  loading: boolean;
  success: boolean;
  youtubeStatus: boolean;
  error: ApiError;
  isScheduleModalOpen: boolean;
  isUpdateReplaystreaming: boolean;
  isCreateReplayStreaming: boolean;
  isViewReplayStreaming: boolean;
  streamingUrls: IStreamingUrl[];
}

const initialState: IReplaystreamingState = {
  list: [],
  overview: null,
  loading: false,
  success: false,
  youtubeStatus: false,
  error: null,
  data: emptyScheduleMeeting,
  isScheduleModalOpen: false,
  isUpdateReplaystreaming: false,
  isCreateReplayStreaming: false,
  isViewReplayStreaming: false,
  streamingUrls: [],
};

export const createReplaystreamingAsync = createAsyncThunk(
  'replaystreaming/create',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await createReplaystreamingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(readReplaystreamingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_replaystreaming',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const readReplaystreamingsAsync = createAsyncThunk(
  'replaystreaming/fetch',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await readReplaystreamingsApi(data);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateReplaystreamingAsync = createAsyncThunk(
  'replaystreaming/update',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateReplaystreamingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(readReplaystreamingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_replaystreaming',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteReplaystreamingAsync = createAsyncThunk(
  'replaystreaming/delete',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteReplaystreamingsApi(data);

      // fetch all the meetings after remove a meeting
      dispatch(readReplaystreamingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_replaystreaming',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const connectYoutubeAsync = createAsyncThunk(
  'replaystreaming/connectYoutube',
  async (data: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await connectYoutubeApi();
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const disconnectYoutubeAsync = createAsyncThunk(
  'replaystreaming/disconnectYoutube',
  async (data: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await disconnectYoutubeApi();
      dispatch(getYoutubeStatusAsync());
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getYoutubeStatusAsync = createAsyncThunk(
  'replaystreaming/getYoutubeStatus',
  async (data: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await getYoutubeStatusApi();
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getLocalLivestreamingAsync = createAsyncThunk(
  'replaystreaming/getLocalLivestreaming',
  async ({ search }: { search: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getLocalLivestreamingsApi(search);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const replaystreamingSlice = createSlice({
  name: 'replaystreaming',
  initialState,
  reducers: {
    openScheduleModal(state) {
      state.isScheduleModalOpen = true;
    },
    closeScheduleModal(state) {
      state.isScheduleModalOpen = false;
      state.isCreateReplayStreaming = false;
      state.isViewReplayStreaming = false;
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    openCreateReplaystreamingModal(state) {
      state.isScheduleModalOpen = true;
      state.isCreateReplayStreaming = true;
    },
    openUpdateReplaystreamingModal(state, action) {
      state.isUpdateReplaystreaming = true;
      state.isScheduleModalOpen = true;
      state.data = action.payload;
    },
    openViewReplaystreamingModal(state, action) {
      state.isUpdateReplaystreaming = false;
      state.isScheduleModalOpen = true;
      state.isViewReplayStreaming = true;
      state.data = action.payload;
    },
    closeEditMeetingModal(state) {
      state.isScheduleModalOpen = false;
      state.isUpdateReplaystreaming = false;
      state.isViewReplayStreaming = false;
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    resetScheduleMeeting(state) {
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // fetch replaystreamings
    builder.addCase(readReplaystreamingsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(readReplaystreamingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data?.meetings;
      state.overview = action?.payload?.data?.overview;
    });
    builder.addCase(readReplaystreamingsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // connect youtube
    builder.addCase(connectYoutubeAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(connectYoutubeAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.data) {
        window.location.replace(action?.payload?.data);
      }
    });
    builder.addCase(connectYoutubeAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // get youtube status
    builder.addCase(getYoutubeStatusAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getYoutubeStatusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.youtubeStatus = action?.payload?.success;
    });
    builder.addCase(getYoutubeStatusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // schedule a new replaystreaming
    builder.addCase(createReplaystreamingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(createReplaystreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(createReplaystreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // delete a replaystreaming
    builder.addCase(deleteReplaystreamingAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteReplaystreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteReplaystreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // edit a replaystreaming
    builder.addCase(updateReplaystreamingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(updateReplaystreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(updateReplaystreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // get local livestreaming data
    builder.addCase(getLocalLivestreamingAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocalLivestreamingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.streamingUrls = action?.payload?.data || [];
    });
    builder.addCase(getLocalLivestreamingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = replaystreamingSlice;

export const selectReplaystreaming = (
  state: RootState
): IReplaystreamingState => state.replaystreaming;

export default replaystreamingSlice.reducer;
