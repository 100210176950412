/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
export const validatePassword = (input: string): boolean => {
  return !input || input.length < 8;
};

export const validateEmail = (input: string): boolean => {
  const reg = /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
  const result = reg.test(input);
  const b1 = input === '';
  const error = !result && !b1;

  return error;
};

export const isAlphanumeric = (input: string): boolean => {
  const regex = /[^a-z0-9]/gi;
  if (regex.test(input)) return false;
  return true;
};

export const isLowercaseAlphanumeric = (input: string): boolean => {
  const regex = /[^a-z0-9]/g;
  if (regex.test(input)) return false;
  return true;
};

export const validateContactNumber = (input: string): boolean => {
  const regex = /^[\+]?[0-9]{7,15}$/g;
  if (regex.test(input)) return false;
  return true;
};

export const isNumeric = (input: string): boolean => {
  const regex = /[^0-9]/gi;
  if (regex.test(input)) return false;
  return true;
};

export const isUrl = (input: string): boolean => {
  const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  if (regex.test(input)) return false;
  return true;
};
