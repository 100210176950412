import React, { memo, useState } from 'react';

import { withStyles, WithStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, Tab } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';

import { isPermissionAvailable } from '../../app/permission';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import {
  ProfileSettings,
  TermsSettings,
  PrivacyPolicySettings,
  ContactUsSettings,
  GeneralSettings,
  HelpSettings,
} from './components';
import styles from './settings.styles';

import { PrivacyIcon } from '../../assets/icons';

interface ISettingsView extends WithStyles<typeof styles> {
  auth: any;
  settings: any;
  handleUpdateSettings: (data: any) => void;
  handleUpdateUserProfile: (data: any) => void;
  handleUpdateUserPassword: (data: any) => void;
  handleAddFAQ: (data: any) => void;
  handleUpdateFAQ: (data: any) => void;
  handleDeleteFAQ: (id: string) => void;
  handleUploadProfilePicture: (data: any) => any;
  handleUploadSystemLogo: (data: any) => any;
}

const SettingsView: React.FC<ISettingsView> = ({
  classes,
  auth,
  settings,
  handleUpdateSettings,
  handleUpdateUserProfile,
  handleUpdateUserPassword,
  handleAddFAQ,
  handleUpdateFAQ,
  handleDeleteFAQ,
  handleUploadProfilePicture,
  handleUploadSystemLogo,
}: ISettingsView) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const intl = useIntl();

  const _handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setSelectedTab(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  if (window?.innerWidth < 575) {
    return (
      <div className={classes.noMobileView}>
        <ReportProblemOutlinedIcon />
        <Typography variant="body1">
          Sorry, this page is not supported by mobiles.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper}>
        <Typography
          className={classes.heading}
          variant="h5"
          component="h3"
          color="textPrimary"
        >
          <FormattedMessage id="settings" />
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.leftPanel}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={_handleTabChange}
          >
            {isPermissionAvailable(
              auth?.permissions,
              'editProfileSettings'
            ) && (
              <Tab
                className={classes.tab}
                key={`tab-${0}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'user_profile',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={<SettingsOutlinedIcon className={classes.tabIcon} />}
                {...a11yProps(0)}
              />
            )}

            {isPermissionAvailable(
              auth?.permissions,
              'editGeneralSettings'
            ) && (
              <Tab
                className={classes.tab}
                key={`tab-${1}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'general_settings',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={<AccountCircleOutlinedIcon className={classes.tabIcon} />}
                {...a11yProps(1)}
              />
            )}

            {isPermissionAvailable(
              auth?.permissions,
              'editPrivacyPolicySettings'
            ) && (
              <Tab
                className={classes.tab}
                key={`tab-${2}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'update_privacy_policy',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={
                  <img
                    className={classes.tabIcon}
                    src={PrivacyIcon}
                    alt="privacy-icon"
                  />
                }
                {...a11yProps(2)}
              />
            )}

            {isPermissionAvailable(auth?.permissions, 'editTermsSettings') && (
              <Tab
                className={classes.tab}
                key={`tab-${3}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'update_terms',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={<AssignmentOutlinedIcon className={classes.tabIcon} />}
                {...a11yProps(3)}
              />
            )}

            {isPermissionAvailable(auth?.permissions, 'editHelpSettings') && (
              <Tab
                className={classes.tab}
                key={`tab-${4}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'help',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={<HelpOutlineOutlinedIcon className={classes.tabIcon} />}
                {...a11yProps(4)}
              />
            )}

            {isPermissionAvailable(
              auth?.permissions,
              'editContactInfoSettings'
            ) && (
              <Tab
                className={classes.tab}
                key={`tab-${5}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'contact_info',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={<ContactsOutlinedIcon className={classes.tabIcon} />}
                {...a11yProps(5)}
              />
            )}

            {/* {isPermissionAvailable(auth?.permissions, 'editFAQSettings') && (
              <Tab
                className={classes.tab}
                key={`tab-${6}`}
                label={
                  <div className={classes.labelWrapper}>
                    {intl.formatMessage({
                      id: 'FAQ',
                    })}
                    <ArrowForwardIosIcon className={classes.arrow} />
                  </div>
                }
                // icon={<img className={classes.tabIcon} src={icon} alt={label} />}
                icon={FaqIcon}
                {...a11yProps(6)}
              />
            )} */}
          </Tabs>
        </Card>
        <div className={classes.rightPanel}>
          {isPermissionAvailable(auth?.permissions, 'editProfileSettings') && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 0}
              id={`vertical-tabpanel-${0}`}
              aria-labelledby={`vertical-tab-${0}`}
              key={`vertical-tabpanel-${0}`}
            >
              {selectedTab === 0 && (
                <ProfileSettings
                  user={auth?.user}
                  settings={settings}
                  handleUpdateUserProfile={handleUpdateUserProfile}
                  handleUpdateUserPassword={handleUpdateUserPassword}
                  handleUploadFile={handleUploadProfilePicture}
                />
              )}
            </div>
          )}
          {isPermissionAvailable(auth?.permissions, 'editGeneralSettings') && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 1}
              id={`vertical-tabpanel-${1}`}
              aria-labelledby={`vertical-tab-${1}`}
              key={intl.formatMessage({
                id: 'general_settings',
              })}
            >
              {selectedTab === 1 && (
                <GeneralSettings
                  settings={settings}
                  handleUploadFile={handleUploadSystemLogo}
                  handleUpdateSettings={handleUpdateSettings}
                />
              )}
            </div>
          )}
          {isPermissionAvailable(
            auth?.permissions,
            'editPrivacyPolicySettings'
          ) && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 2}
              id={`vertical-tabpanel-${2}`}
              aria-labelledby={`vertical-tab-${2}`}
              key={intl.formatMessage({
                id: 'update_privacy_policy',
              })}
            >
              {selectedTab === 2 && (
                <PrivacyPolicySettings
                  settings={settings}
                  handleUpdateSettings={handleUpdateSettings}
                />
              )}
            </div>
          )}
          {isPermissionAvailable(auth?.permissions, 'editTermsSettings') && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 3}
              id={`vertical-tabpanel-${3}`}
              aria-labelledby={`vertical-tab-${3}`}
              key={intl.formatMessage({
                id: 'update_terms',
              })}
            >
              {selectedTab === 3 && (
                <TermsSettings
                  settings={settings}
                  handleUpdateSettings={handleUpdateSettings}
                />
              )}
            </div>
          )}
          {isPermissionAvailable(auth?.permissions, 'editHelpSettings') && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 4}
              id={`vertical-tabpanel-${4}`}
              aria-labelledby={`vertical-tab-${4}`}
              key={intl.formatMessage({
                id: 'help',
              })}
            >
              {selectedTab === 4 && (
                <HelpSettings
                  settings={settings}
                  handleUpdateSettings={handleUpdateSettings}
                />
              )}
            </div>
          )}
          {isPermissionAvailable(
            auth?.permissions,
            'editContactInfoSettings'
          ) && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 5}
              id={`vertical-tabpanel-${5}`}
              aria-labelledby={`vertical-tab-${5}`}
              key={intl.formatMessage({
                id: 'contact_info',
              })}
            >
              {selectedTab === 5 && (
                <ContactUsSettings
                  settings={settings}
                  handleUpdateSettings={handleUpdateSettings}
                />
              )}
            </div>
          )}
          {/* {isPermissionAvailable(auth?.permissions, 'editFAQSettings') && (
            <div
              role="tabpanel"
              hidden={selectedTab !== 6}
              id={`vertical-tabpanel-${6}`}
              aria-labelledby={`vertical-tab-${6}`}
              key={intl.formatMessage({
                id: 'FAQ',
              })}
            >
              {selectedTab === 6 && (
                <FAQSettings
                  settings={settings}
                  handleAddFAQ={handleAddFAQ}
                  handleUpdateFAQ={handleUpdateFAQ}
                  handleDeleteFAQ={handleDeleteFAQ}
                />
              )}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(SettingsView));
