import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { isLowercaseAlphanumeric, isNumeric } from '../../../helper/validation';
import { TextField } from '../../../components/TextField';
import { Checkbox } from '../../../components/Checkbox';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    checkBoxText: {
      color: '#868D96',
    },
    checkBoxWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiPaper-root': {
        width: 'auto',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
  });
};

export interface IAddDeviceModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleAddDevice: (data: any) => void;
  selectDevice: any;
}

const AddDeviceModal = ({
  classes,
  open,
  handleClose,
  handleAddDevice,
  selectDevice,
}: IAddDeviceModalProps) => {
  const [deviceId, setDeviceId] = useState({ error: false, value: '' });
  const [deviceName, setDeviceName] = useState({ error: false, value: '' });
  const [privacyTimeout, setPrivacyTimeout] = useState({
    error: false,
    value: '',
  });
  const [privacyEnabled, setPrivacyEnabled] = useState({
    error: false,
    value: false,
  });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectDevice.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!deviceName.value || deviceName.error) {
      isValidate = false;
      setDeviceName({ ...deviceName, error: true });
    }
    if (!deviceId.value || deviceId.error) {
      isValidate = false;
      setDeviceId({ ...deviceId, error: true });
    }
    if (!privacyTimeout.value || privacyTimeout.error) {
      isValidate = false;
      setPrivacyTimeout({ ...privacyTimeout, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const deviceData = {
        deviceId: deviceId.value,
        userDeviceName: deviceName.value,
        privacyTimeout: privacyTimeout.value,
        privacyEnabled: privacyEnabled.value,
      };
      handleAddDevice(deviceData);
    }
  };

  const deviceIdOnChange = (e: { target: { value: string } }) => {
    const error = !isLowercaseAlphanumeric(e.target.value);
    setDeviceId({ value: e.target.value, error });
  };
  const deviceNameOnChange = (e: { target: { value: string } }) => {
    setDeviceName({ value: e.target.value, error: false });
  };
  const privacyTimeoutOnChange = (e: { target: { value: string } }) => {
    const error = !isNumeric(e.target.value);
    setPrivacyTimeout({ value: e.target.value, error });
  };
  const privacyEnabledOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPrivacyEnabled({ value: checked, error: false });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'add_device',
      })}
      modalDescription={intl.formatMessage({
        id: 'add_device_to_manage_meetings',
      })}
      id="add-device-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'add_device',
      })}
    >
      <TextField
        id="add-device-device-name"
        error={deviceName.error}
        label={intl.formatMessage({
          id: 'user_device_name',
        })}
        required
        onChange={deviceNameOnChange}
        helperText={
          deviceName.error &&
          intl.formatMessage({
            id: 'please_insert_valid_device_name',
          })
        }
      />
      <TextField
        id="add-device-deviceId"
        error={deviceId.error}
        label={intl.formatMessage({
          id: 'device_id',
        })}
        required
        onChange={deviceIdOnChange}
        helperText={
          deviceId.error &&
          intl.formatMessage({
            id: 'please_insert_valid_deviceid',
          })
        }
      />
      <TextField
        id="add-device-privacy-timeout"
        error={privacyTimeout.error}
        label={intl.formatMessage({
          id: 'privacy_timeout',
        })}
        required
        onChange={privacyTimeoutOnChange}
        helperText={
          privacyTimeout.error &&
          intl.formatMessage({
            id: 'please_insert_valid_privacy_timeout',
          })
        }
      />
      <div className={classes.checkBoxWrapper}>
        <Checkbox
          id="add-device-privacy-enabled"
          checked={privacyEnabled.value}
          onChange={privacyEnabledOnChange}
        />
        <Typography variant="body1" className={classes.checkBoxText}>
          {intl.formatMessage({
            id: 'privacy_enabled',
          })}
        </Typography>
      </div>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddDeviceModal));
