import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { isNumeric } from '../../../helper/validation';
import { Checkbox } from '../../../components/Checkbox';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    checkBoxText: {
      color: '#868D96',
    },
    checkBoxWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiPaper-root': {
        width: 'auto',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
  });
};

export interface IAddUsersModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleEditDevice: (data: any) => void;
  selectDevice: any;
  selectedDevice: any;
}

const EditDeviceModal = ({
  classes,
  open,
  handleClose,
  handleEditDevice,
  selectDevice,
  selectedDevice,
}: IAddUsersModalProps) => {
  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [privacyTimeout, setPrivacyTimeout] = useState({
    error: false,
    value: '',
  });
  const [privacyEnabled, setPrivacyEnabled] = useState({
    error: false,
    value: false,
  });
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectDevice.loading]);

  useEffect(() => {
    if (selectedDevice) {
      setName({
        error: false,
        value: selectedDevice.userDeviceName || selectedDevice.deviceName || '',
      });
      setPrivacyTimeout({ error: false, value: selectedDevice.PrivacyTimeOut });
      setPrivacyEnabled({ error: false, value: selectedDevice.PrivacyEnabled });
    }
  }, [selectedDevice]);

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!privacyTimeout.value || privacyTimeout.error) {
      isValidate = false;
      setPrivacyTimeout({ ...privacyTimeout, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const deviceData = {
        deviceId: selectedDevice?.id,
        name: name.value,
        privacyTimeOut: privacyTimeout.value,
        privacyEnabled: privacyEnabled.value,
      };
      handleEditDevice(deviceData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({ value: e.target.value, error: false });
  };

  const privacyTimeoutOnChange = (e: { target: { value: string } }) => {
    const error = !isNumeric(e.target.value);
    setPrivacyTimeout({ value: e.target.value, error });
  };

  const privacyEnabledOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPrivacyEnabled({ value: checked, error: false });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'edit_this_device',
      })}
      modalDescription={intl.formatMessage({
        id: 'edit_device_description',
      })}
      id="edit-device-modal"
      open={open}
      isSingleButton
      handleClose={() => {
        setName({
          error: false,
          value:
            selectedDevice?.userDeviceName || selectedDevice?.deviceName || '',
        });
        handleClose();
      }}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'save',
      })}
    >
      <TextField
        id="edit-device-device-name"
        error={name.error}
        value={name.value}
        label={intl.formatMessage({
          id: 'name',
        })}
        required
        onChange={nameOnChange}
        helperText={
          name.error &&
          intl.formatMessage({
            id: 'please_insert_valid_device_name',
          })
        }
      />
      <TextField
        id="edit-device-privacy-timeout"
        error={privacyTimeout.error}
        value={privacyTimeout.value}
        label={intl.formatMessage({
          id: 'privacy_timeout',
        })}
        required
        onChange={privacyTimeoutOnChange}
        helperText={
          privacyTimeout.error &&
          intl.formatMessage({
            id: 'please_insert_valid_privacy_timeout',
          })
        }
      />
      <div className={classes.checkBoxWrapper}>
        <Checkbox
          id="edit-device-privacy-enabled"
          checked={privacyEnabled.value}
          onChange={privacyEnabledOnChange}
        />
        <Typography variant="body1" className={classes.checkBoxText}>
          {intl.formatMessage({
            id: 'privacy_enabled',
          })}
        </Typography>
      </div>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(EditDeviceModal));
