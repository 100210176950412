import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100vh',
      padding: theme.spacing(0, 2),
    },
    conferenceContainer: {
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
    },
    buttonWrapper: {
      display: 'flex',
    },
    rejoin: {
      marginRight: theme.spacing(1),
    },
    leftGrid: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 3vw',
    },
    rightGrid: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 3vw',
    },
    videoPreviewWrapper: {
      height: '60%',
      display: 'flex',
      justifyContent: 'center',
      '& video': {
        maxWidth: '100%',
      },
    },
    videoMutedPreview: {
      backgroundColor: theme.palette.common.black,
      aspectRatio: '16/9',
      maxWidth: '100%',
    },
    mediaButtonsWrapper: {
      marginTop: '2vh',
      display: 'flex',
      justifyContent: 'center',
    },
    mediaButton: {
      backgroundColor: '#E01717',
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      margin: theme.spacing(0, 1),
      '&:hover': {
        backgroundColor: '#ff3636',
      },
    },
    useInfoCard: {
      height: 'auto',
      marginBottom: '2vw',
      padding: theme.spacing(3),
    },
    cardHeading: {
      marginBottom: theme.spacing(2),
    },
    textOverflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    bannerCard: {
      height: 'auto',
      marginBottom: '2vw',
      padding: theme.spacing(3),
      textAlign: 'center',
    },
    profileDetailsWrapper: {
      margin: theme.spacing(3, 0),
    },
  });
};

export default styles;
