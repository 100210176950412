import React, { memo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { IGroup, IGroupDevice, IGroupMember } from '../groups.slice';
import { IDevicesState } from '../../devices/devices.slice';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IGroupInfoModalProps extends WithStyles<typeof styles> {
  selectedGroup: IGroup;
  selectDevice: IDevicesState;
  showParent: boolean;
  open: boolean;
  handleClose: () => void;
}

const GroupInfoModal = ({
  classes,
  selectedGroup,
  selectDevice,
  showParent,
  open,
  handleClose,
}: IGroupInfoModalProps) => {
  const getDeviceName = (deviceId: string) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const device of selectDevice.list.data) {
      if (device._id === deviceId) {
        return device.userDeviceName || device.deviceName || '';
      }
    }
    return '';
  };

  const intl = useIntl();
  return (
    <ActionModal
      modalTitle={selectedGroup?.name}
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'done',
      })}
      actinButtonOnClick={handleClose}
      isSingleButton
    >
      <Grid container className={classes.userCard}>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="description" /> :&nbsp;
            {selectedGroup?.description}
          </Typography>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="members" /> :&nbsp;
            {selectedGroup?.members.map((member: IGroupMember) => {
              return `${member?.userId?.profile?.name} | ${member?.userId?.email}, `;
            })}
          </Typography>
          <Typography variant="body1" color="secondary">
            <FormattedMessage id="devices" /> :&nbsp;
            {selectedGroup?.devices.map((device: IGroupDevice) => {
              return `${getDeviceName(device?.deviceId?.id)}, `;
            })}
          </Typography>
          {showParent ? (
            <Typography variant="body1" color="secondary">
              <FormattedMessage id="managed_by" /> :&nbsp;
              {selectedGroup?.parentId?.profile?.name}
            </Typography>
          ) : (
            ''
          )}
          {/* <Typography variant="body1" color="secondary">
            <FormattedMessage id="participants" /> : &nbsp;
            {meeting?.participantCount}
          </Typography> */}
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(GroupInfoModal));
