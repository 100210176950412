/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { IGroup, IGroupsState } from '../groups.slice';

const styles = (theme: Theme) => {
  return createStyles({
    groupCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IDeleteGroupModalProps extends WithStyles<typeof styles> {
  selectGroup: IGroupsState;
  open: boolean;
  selectedGroup: IGroup;
  handleClose: () => void;
  handleDeleteGroup: (id: string) => void;
}
const DeleteGroupModal = ({
  classes,
  open,
  selectedGroup,
  handleClose,
  handleDeleteGroup,
  selectGroup,
}: IDeleteGroupModalProps) => {
  const intl = useIntl();

  useEffect(() => {
    handleClose();
  }, [selectGroup.loading]);

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'do_you_want_to_delete_this_group',
      })}
      open={open}
      handleClose={handleClose}
      actinButtonLabel={intl.formatMessage({
        id: 'delete',
      })}
      actinButtonOnClick={() => {
        handleDeleteGroup(selectedGroup._id);
      }}
    >
      <Grid container className={classes.groupCard}>
        <Grid item xs={1}>
          <GroupIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>
              {selectedGroup?.name ||
                intl.formatMessage({
                  id: 'not_available',
                })}
            </b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedGroup?.description ||
              intl.formatMessage({
                id: 'not_available',
              })}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteGroupModal));
