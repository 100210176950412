import { Role } from '../features/auth/auth.slice';

export interface IPermissions {
  editGeneralSettings?: boolean;
  editPrivacyPolicySettings?: boolean;
  editTermsSettings?: boolean;
  editFAQSettings?: boolean;
  editContactInfoSettings?: boolean;
  editScreenAppSettings?: boolean;
  editHelpSettings?: boolean;
  resetUserPassword?: boolean;
  screenAppIntegration?: boolean;
  viewReports?: boolean;
  viewAdmins?: boolean;
  viewCustomers?: boolean;
  viewMembers?: boolean;
  editMembers?: boolean;
  promoteCustomers?: boolean;
  suspendCustomers?: boolean;
  viewGroups?: boolean;
  viewDevices?: boolean;
  addDevices?: boolean;
  scheduleMeeting?: boolean;
  instantMeeting?: boolean;
  viewAllCustomers?: boolean;
  viewAllMembers?: boolean;
  viewAllDevices?: boolean;
  addMembers?: boolean;
  addLivestreamings?: boolean;
  viewLivestreamings?: boolean;
  editLivestreamings?: boolean;
  deleteLivestreamings?: boolean;
  viewScheduledEvents?: boolean;
  addScheduledEvents?: boolean;
  addReplaystreamings?: boolean;
  viewReplayStreamings?: boolean;
  editReplaystreamings?: boolean;
  deleteReplaystreamings?: boolean;
}

export const rolePermissions = {
  'super-admin': {
    editProfileSettings: true,
    editGeneralSettings: true,
    editPrivacyPolicySettings: true,
    editTermsSettings: true,
    editFAQSettings: true,
    editContactInfoSettings: true,
    editScreenAppSettings: true,
    editHelpSettings: true,
    resetUserPassword: true,
    screenAppIntegration: true,
    viewReports: true,
    viewAdmins: true,
    viewCustomers: false,
    viewMembers: false,
    editMembers: false,
    promoteCustomers: true,
    suspendCustomers: true,
    viewGroups: false,
    viewDevices: false,
    addDevices: false,
    scheduleMeeting: false,
    instantMeeting: false,
    viewAllCustomers: true,
    viewAllMembers: true,
    viewAllDevices: true,
    addMembers: true,
    viewMeetings: false,
    addLivestreamings: false,
    viewLivestreamings: false,
    editLivestreamings: false,
    deleteLivestreamings: false,
    viewScheduledEvents: false,
    addScheduledEvents: false,
    addReplaystreamings: false,
    viewReplayStreamings: false,
    editReplaystreamings: false,
    deleteReplaystreamings: false,
  },
  admin: {
    editProfileSettings: true,
    editGeneralSettings: true,
    editPrivacyPolicySettings: true,
    editTermsSettings: true,
    editFAQSettings: true,
    editContactInfoSettings: true,
    editScreenAppSettings: true,
    editHelpSettings: true,
    resetUserPassword: true,
    screenAppIntegration: true,
    viewReports: true,
    viewAdmins: true,
    viewCustomers: false,
    viewMembers: false,
    editMembers: false,
    promoteCustomers: true,
    suspendCustomers: true,
    viewGroups: false,
    viewDevices: false,
    addDevices: false,
    scheduleMeeting: false,
    instantMeeting: false,
    viewAllCustomers: true,
    viewAllMembers: true,
    viewAllDevices: true,
    addMembers: true,
    viewMeetings: false,
    addLivestreamings: false,
    viewLivestreamings: false,
    editLivestreamings: false,
    deleteLivestreamings: false,
    viewScheduledEvents: false,
    addScheduledEvents: false,
    addReplaystreamings: false,
    viewReplayStreamings: false,
    editReplaystreamings: false,
    deleteReplaystreamings: false,
  },
  subadmin: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    editHelpSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: true,
    viewAdmins: false,
    viewCustomers: true,
    viewMembers: true,
    editMembers: true,
    promoteCustomers: false,
    suspendCustomers: true,
    viewGroups: true,
    viewDevices: true,
    addDevices: true,
    scheduleMeeting: true,
    instantMeeting: true,
    viewAllCustomers: false,
    viewAllMembers: false,
    viewAllDevices: false,
    addMembers: true,
    viewMeetings: true,
    addLivestreamings: true,
    viewLivestreamings: true,
    editLivestreamings: true,
    deleteLivestreamings: true,
    viewScheduledEvents: true,
    addScheduledEvents: true,
    addReplaystreamings: true,
    viewReplayStreamings: true,
    editReplaystreamings: true,
    deleteReplaystreamings: true,
  },
  customer: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    editHelpSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: true,
    viewAdmins: false,
    viewCustomers: false,
    viewMembers: true,
    editMembers: true,
    promoteCustomers: false,
    suspendCustomers: false,
    viewGroups: true,
    viewDevices: true,
    addDevices: true,
    scheduleMeeting: true,
    instantMeeting: true,
    viewAllCustomers: false,
    viewAllMembers: false,
    viewAllDevices: false,
    addMembers: true,
    viewMeetings: true,
    addLivestreamings: true,
    viewLivestreamings: true,
    editLivestreamings: true,
    deleteLivestreamings: true,
    viewScheduledEvents: true,
    addScheduledEvents: true,
    addReplaystreamings: true,
    viewReplayStreamings: true,
    editReplaystreamings: true,
    deleteReplaystreamings: true,
  },
  member: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    editHelpSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: false,
    viewAdmins: false,
    viewCustomers: false,
    viewMembers: true,
    editMembers: false,
    promoteCustomers: false,
    suspendCustomers: false,
    viewGroups: false,
    viewDevices: true,
    addDevices: false,
    scheduleMeeting: true,
    instantMeeting: true,
    viewAllCustomers: false,
    viewAllMembers: false,
    viewAllDevices: false,
    addMembers: false,
    viewMeetings: true,
    addLivestreamings: false,
    viewLivestreamings: true,
    editLivestreamings: false,
    deleteLivestreamings: false,
    viewScheduledEvents: true,
    addScheduledEvents: true,
    addReplaystreamings: false,
    viewReplayStreamings: true,
    editReplaystreamings: false,
    deleteReplaystreamings: false,
  },
};

export const filterRolePermissions = (role: Role): IPermissions => {
  return rolePermissions[role as keyof typeof rolePermissions];
};

export const isPermissionAvailable = (
  permissions: IPermissions,
  feature: string
): boolean => {
  if (!permissions || !feature) return false;
  if (permissions[feature as keyof typeof permissions]) return true;
  return false;
};
